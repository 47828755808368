import React from 'react';

import { SAIcon, SAIcons, SAIconSize } from '@saux/design-system-react';

import { formatPhone } from '../../util';

import {
  AgencyBilledModalWrapper,
  ModalContainer,
  ModalHeader,
  ModalTitle,
  ModalHeaderClose,
  ModalBody,
  ModalBodyText,
  ModalAgentLabel,
  ModalAgentName,
  ModalDetailsRow,
  ModalDetailsTitle,
  ModalDetailsLink,
  ModalCloseButton,
  LoaderErrorContainer,
} from './AgencyBilledModal.styles';
import AgencyBilledQuantity from '../../sharedTypes/AgencyBilledQuantity';

export interface AgencyInfo {
  name: string | null | undefined;
  phoneNumber: string | null | undefined;
  email: string | null | undefined;
}

interface Props {
  showModal: boolean;
  closeModal: Function;
  agencyInfo: AgencyInfo | undefined;
  loading: boolean;
  agencyBilledQuantity: AgencyBilledQuantity;
}

export default ({
  showModal,
  closeModal,
  agencyInfo,
  loading,
  agencyBilledQuantity,
}: Props) => {
  if (!showModal) {
    return <></>;
  }

  if (loading) {
    return (
      <AgencyBilledModalWrapper
        onClickHandler={(e: React.MouseEvent) => e.stopPropagation()}
      >
        <LoaderErrorContainer>Loading...</LoaderErrorContainer>
      </AgencyBilledModalWrapper>
    );
  }

  return (
    <AgencyBilledModalWrapper
      onClickHandler={(e: React.MouseEvent) => e.stopPropagation()}
    >
      <ModalContainer>
        <ModalHeader>
          <ModalTitle>Account Notification</ModalTitle>
          <ModalHeaderClose onClick={() => closeModal()}>
            <SAIcon
              icon={SAIcons.x}
              size={SAIconSize.medium}
              colorVariant="dark"
            />
          </ModalHeaderClose>
        </ModalHeader>
        <ModalBody>
          <ModalBodyText>
            {agencyBilledQuantity === AgencyBilledQuantity.All
              ? `The policies in your account are agency billed, please reach out
            your agent for any billing or payment questions or to make a
            payment.`
              : `A policy on this account uses agency billing, please reach
            out to your agent for any billing or payment questions or to
            make a payment.`}
          </ModalBodyText>
          <ModalAgentLabel>Your Agent</ModalAgentLabel>
          <ModalAgentName>{agencyInfo?.name}</ModalAgentName>
          <ModalDetailsRow>
            <ModalDetailsTitle>Phone Number:</ModalDetailsTitle>
            <ModalDetailsLink
              href={`tel:${(agencyInfo?.phoneNumber || '').replace(
                /[^0-9+]/g,
                '',
              )}`}
            >
              {formatPhone(agencyInfo?.phoneNumber)}
            </ModalDetailsLink>
          </ModalDetailsRow>
          <ModalDetailsRow>
            <ModalDetailsTitle>Email Address:</ModalDetailsTitle>
            <ModalDetailsLink href={agencyInfo?.email || ''}>
              {agencyInfo?.email}
            </ModalDetailsLink>
          </ModalDetailsRow>
        </ModalBody>
        <ModalCloseButton onClick={() => closeModal()}>CLOSE</ModalCloseButton>
      </ModalContainer>
    </AgencyBilledModalWrapper>
  );
};

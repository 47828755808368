import React, { useEffect } from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0px;
`;

interface PolicyContainerProps {
  policyNumberArray: string[] | [];
  selectedPolicy: string;
  selectedDate: string;
  reviewDateArray: string[] | [];
  handlePolicyChange: Function;
  handleDateChange: Function;
}

const PolicyDropdown = ({
  policyNumberArray,
  selectedPolicy,
  selectedDate,
  reviewDateArray,
  handlePolicyChange,
  handleDateChange,
}: PolicyContainerProps) => {
  useEffect(() => {
    handlePolicyChange({
      target: {
        value: policyNumberArray.length > 0 ? policyNumberArray[0] : '',
      },
    });
  }, [policyNumberArray]);
  useEffect(() => {
    handleDateChange({
      target: {
        value: reviewDateArray.length > 0 ? reviewDateArray[0] : '',
      },
    });
  }, [reviewDateArray]);

  return (
    <Container>
      <Box sx={{ width: '15%'}}>
        <FormControl fullWidth>
          <InputLabel id="select-label-policy">
            Select a Policy
          </InputLabel>
          <Select
            labelId="select-label-policy"
            id="select"
            value={selectedPolicy}
            label="Select a Policy"
            onChange={(event) => handlePolicyChange(event)}
          >
            {policyNumberArray &&
            policyNumberArray.length > 0 &&
            policyNumberArray.map((policy) => (
              <MenuItem key={policy} value={policy}>{policy}</MenuItem>
            ))
            }
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ width: '25%', marginLeft: '20px'}}>
        <FormControl fullWidth>
          <InputLabel id="select-label-date">
            Select a Report to Review
          </InputLabel>
          <Select
            labelId="select-label-date"
            id="select"
            value={selectedDate}
            label="Select a Report to Review"
            onChange={(event) => handleDateChange(event)}
          >
            {reviewDateArray &&
            reviewDateArray.length > 0 &&
            reviewDateArray.map((review) => (
              <MenuItem key={review} value={review}>{review}</MenuItem>
            ))
            }
          </Select>
        </FormControl>
      </Box>
    </Container>
  );
};

export default PolicyDropdown;

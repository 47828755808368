import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

export const PayrollMainWrapper = styled.div`
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  background: white;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 3vh;
`;
export const Text = styled.div`
  font-size: 1.2rem;
`;
export const TextWrapper = styled.div`
  margin-bottom: 3vh;
`;
export const Button = styled.span`
  margin-left: 0px;
  margin-right: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

import React, { useEffect, useState, useCallback } from 'react'
import { Jurisdiction, Location, Period, PriorReportDetails, CoveredEmployee } from './graphql/GET_PRIOR_AUDIT_REPORT_DETAILS';
import { formatCurrency, formatDatePayg } from '../../util/formatters';
import { ReactComponent as ChevronUp } from '../../assets/images/ChevronUp.svg';
import { ReactComponent as ChevronDownIcon } from '../../assets/images/ChevronDownIcon.svg';
import { ReactComponent as Calendar } from '../../assets/images/Calendar.svg';
import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
  ClassHeader,
  ClassCell,
  Header,
  TimePeriod,
  Result,
  ResultLabel,
  LocationDisplay,
  ReportedPayroll,
  Green,
  Icon,
  SummaryContainer,
  SummaryText,
  Wrapper,
  Span,
} from './JurisdictionDetailsStyles';

interface AuditDetailsProps {
  selectedPriorReport: PriorReportDetails | any | {};
}

const AuditDetails = ({
  selectedPriorReport
}: AuditDetailsProps) => {
  const [jurisdiction, setJurisdiction] = useState<Jurisdiction[] | []>([]);
  const [openStates, setOpenStates] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    if (selectedPriorReport && selectedPriorReport?.jurisdictions && selectedPriorReport.jurisdictions.length > 0) {
      setJurisdiction(selectedPriorReport.jurisdictions);
      const selectedJur: Jurisdiction[] = selectedPriorReport.jurisdictions;
      selectedJur.forEach((reqJur) => {
        setOpenStates(prevStates => ({ ...prevStates, [reqJur.publicID]: false }));
      });
    }
  }, [selectedPriorReport]);

  const toggleDropdown = useCallback((publicID: string) => {
    setOpenStates(prevStates => ({
      ...prevStates,
      [publicID]: !prevStates[publicID]
    }));
  }, []);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLSpanElement>, publicID: string) => {
      if (event.key === 'Enter' || event.key === ' ') {
        toggleDropdown(publicID);
      }
    },
    [toggleDropdown],
  );

  const totalAuditPayroll = (loc: Location) => {
    let totalAuditPaid = 0;
    totalAuditPaid = loc.coveredEmployees.reduce((total, employee) => {
      return total + parseInt(employee.auditedPayroll, 10);
    }, 0);
    return totalAuditPaid;
  };

  return (
    <Wrapper>
      {jurisdiction && jurisdiction.length > 0 ? (
        jurisdiction.map((jur: Jurisdiction) => (
          <div key={jur.publicID}>
            <SummaryContainer>
              <SummaryText>{jur.jurisdictionName}</SummaryText>
              <ReportedPayroll>
                Reported Payroll: &nbsp;&nbsp;&nbsp;
                {formatCurrency(jur.totalReportedPayRoll)}
                <Span
                  role="button"
                  tabIndex={0}
                  onClick={() => toggleDropdown(jur.publicID)}
                  onKeyDown={(e) => handleKeyDown(e, jur.publicID)}
                >
                  {openStates[jur.publicID] ? <ChevronUp /> : <ChevronDownIcon />}
                </Span>
              </ReportedPayroll>
            </SummaryContainer>
            {openStates[jur.publicID] && (
              <>
                {jur.periods.map((per: Period) => (
                  <>
                    <TimePeriod>
                      <Icon>
                        <Calendar />
                      </Icon>
                      Period: {formatDatePayg(selectedPriorReport?.premiumAuditBaseDetails?.periodStartDate)} - {formatDatePayg(selectedPriorReport?.premiumAuditBaseDetails?.periodEndDate)}
                    </TimePeriod>
                    {per.locations.map((loc: Location) => (
                    <div key={loc.publicID}>
                      <LocationDisplay>
                        <Green />
                        <b>Location {loc.locationNum}&nbsp;:&nbsp;&nbsp;</b> {loc.locationDisplayName}
                      </LocationDisplay>
                      <Table>
                        <Header>
                          <ClassHeader>Class Code</ClassHeader>
                          <TableHeader>Estimated Payroll</TableHeader>
                          <TableHeader>Reported Payroll</TableHeader>
                        </Header>
                        {loc && loc.coveredEmployees &&
                          loc.coveredEmployees.map((ce: CoveredEmployee) => (
                            <TableRow key={ce.classCode}>
                              <ClassCell>
                                {ce.classCode}-{ce.description}
                              </ClassCell>
                              <TableCell>
                                {formatCurrency(ce.estimatedPayroll)}
                              </TableCell>
                              <TableCell>
                                {formatCurrency(ce.auditedPayroll)}
                              </TableCell>
                            </TableRow>
                          ))}
                        <TableRow>
                          <ResultLabel>Total</ResultLabel>
                          <Result>{formatCurrency(loc.totalEstimatedPayRoll)}</Result>
                          <Result>
                            {' '}
                            {formatCurrency(totalAuditPayroll(loc))}
                          </Result>
                        </TableRow>
                      </Table>
                    </div>
                  ))}
                  </>
                ))}
              </>
            )}
          </div>
        ))
      ) : null}
    </Wrapper>
  )
}

export default AuditDetails
import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from './util/global.theme';

export const StyledContentWrapper = styled.div`
  max-width: ${customBase?.global?.breakpoints?.xlarge + 1}px;
  margin: ${customBase?.global?.spacers?.med_large}px auto 0;
  @media (min-width: 0px) and (max-width: ${customBase.global.breakpoints
      .small}px) {
    align-items: center;
    padding: 0 ${customBase?.global?.spacers?.xxsmall - 2}px
      ${customBase?.global?.spacers?.large}px;
  }
  @media (min-width: ${customBase.global.breakpoints
      .small}px) and (max-width: ${customBase.global.breakpoints.xlarge}px) {
    padding: 0 ${customBase?.global?.spacers?.large}px
      ${customBase?.global?.spacers?.large}px;
  }
  @media (min-width: ${customBase.global.breakpoints.xlarge}px) {
    width: 90%;
  }
`;

export const StyledInnerContentWrapper = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      padding-top: ${theme?.size.spacer.large};
      min-height: 80vh;
    `;
  }};
`;

export const TopContextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ResponsiveFeedbackHolder = styled.div`
  padding-top: 25px;
  .leave-feedback-button {
    background-color: #cd5234;
  }
`;

export const ResponsiveFeedbackContent = styled.div`
  padding: 30px 0px 20px 0px;
  display: flex;
  justify-content: center;
`;

export const ResponsiveFeedbackButton = styled.div`
  padding-bottom: 20px;
`;

function formatCurrency(value: number | Number | null | undefined | string) {
  if (value === null || value === undefined) {
    return 'N/A';
  }

  return Number(value).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export { formatCurrency as default };

import styled from 'styled-components';
import { SAModal } from '@saux/design-system-react';
import customBase from '../../util/global.theme';

const breakpoint = customBase.global.breakpoints.medium;

export const AgencyBilledModalWrapper = styled(SAModal)`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoaderErrorContainer = styled.p`
  margin: 0;
  padding: 30px;
  text-align: center;
  background-color: #ffffff;
`;

export const ModalContainer = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoint + 1}px) {
    padding: 30px;
    border-radius: 5px;
    width: 650px;
  }

  @media (max-width: ${breakpoint}px) {
    width: 100%;
    height: 100%;
  }
`;

export const ModalHeader = styled.div`
  @media (max-width: ${breakpoint}px) {
    display: flex;
    padding: 16px;
    align-items: center;
    justify-content: space-between;
    height: 66px;
    border-bottom: 1px solid #d9dfeb;
  }
`;

export const ModalTitle = styled.p`
  font-weight: 600;
  font-size: 19x;
  line-height: 23px;
  color: #3b3b3b;
  margin: 0;

  @media (max-width: ${breakpoint}px) {
    margin-left: 4px;
    font-size: 16px;
    line-height: 19px;
  }
`;

export const ModalHeaderClose = styled.div`
  display: none;

  @media (max-width: ${breakpoint}px) {
    cursor: pointer;
    display: block;
    width: 30px;
    height: 30px;
  }
`;

export const ModalBody = styled.div`
  padding: 16px 0;

  @media (max-width: ${breakpoint}px) {
    padding: 20px;
    flex-grow: 1;
  }
`;

export const ModalBodyText = styled.p`
  margin: 0 0 32px;
  font-size: 16px;
  line-height: 21px;
  color: #3b3b3b;
`;

export const ModalAgentLabel = styled.p`
  margin: 0 0 12px;
  display: inline-block;
  color: #9325b2;
  border: 1px solid #9325b2;
  border-radius: 24px;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  padding: 4px 10px;
`;

export const ModalAgentName = styled.p`
  margin: 0 0 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #3b3b3b;
`;

export const ModalDetailsRow = styled.div`
  display: flex;
  align-items: center;
  height: 26px;
`;

export const ModalDetailsTitle = styled.p`
  flex-grow: 0;
  font-weight: 600;
  font-size: 13px;
  color: #3b3b3b;
  margin: 0;
`;

export const ModalDetailsLink = styled.a`
  display: block;
  flex-grow: 0;
  font-size: 16px;
  color: #0173c6;
  padding: 0 8px;
  text-decoration: none;
`;

const closeBtnMargin = 20;

export const ModalCloseButton = styled.button`
  cursor: pointer;
  border: none;
  color: #ffffff;
  font-weight: 600;
  padding: 15px 25px;
  background: #4d840b;
  border-radius: 5px;
  align-self: end;

  @media (max-width: ${breakpoint}px) {
    margin: ${closeBtnMargin}px;
    text-align: center;
    width: calc(100% - ${closeBtnMargin * 2}px);
  }
`;

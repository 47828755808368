import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

// z-index of 2147483601 is one unit above the freshchat icon
export const Modal = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      z-index: 2147483601;
      overflow-y: hidden;
      padding-top: 25px;
      width: 100vw;
      height: 100vh;
      background-color: ${theme?.color.primary.foreground};
      border-radius: ${theme?.size.radius.small};
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease-out;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      touch-action: none;
      
      @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
        width: 670px;
        height: 482px;
      }
      `;
  }}
`;

export const Backdrop = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(4, 30, 65, 0.6);
  z-index: 2147483601;
  overflow-y: hidden;
  touch-action: none;
`;

export const DesktopModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  padding-top: 167px;

  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    width: 670px;
    height: 482px;
    padding-top: 70px;
  }

  @media screen and (max-height: ${customBase.global.breakpoints.small}px) {
    padding-top: 50px;
  }
`;

export const UpperContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 326px;
`;

export const BottomContentWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 30px;
  padding-bottom: 30px;
`;

export const PreviousButtonWrapper = styled.div`
  margin-right: 5px;
`;

export const TitleWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 8px;
`;

export const SubTitleWrapper = styled.div`
  p {
    margin-bottom: 0px;
  }
`;

export const InputWrapper = styled.div`
  margin-top: 18px;
  margin-bottom: 66px;
  width: 326px;
  height: 55px;

  @media screen and (max-width: ${customBase.global.breakpoints.xsmall}px) {
    width: 300px;
  }
`;

export const PhoneWrapper = styled.div`
  margin-top: 18px;
  margin-bottom: 66px;
  height: 55px;
`;

export const ToolTipHolder = styled.div`
  position: absolute;
`;

import { navigate, useLocation } from '@reach/router';
import AWSAppSyncClient from 'aws-appsync';
import { SATabBar } from '@saux/design-system-react';
import React, { useEffect, useState } from 'react';
import { AgencyBilledModal } from '..';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import { useFeatureFlag } from '../../util/hooks';
import ClaimsDisabledModal from '../claimsDisabledModal/ClaimsDisabledModal';
import AgencyBilledQuantity from '../../sharedTypes/AgencyBilledQuantity';

export interface TabRoute {
  label: string;
  pathMatch: string;
}

interface NavbarProps {
  tabRoutes: TabRoute[];
  isAgencyBilled: boolean;
  accountNumber: string;
  awsAppSyncClient: AWSAppSyncClient<any>;
  agencyBilledQuantity: AgencyBilledQuantity;
}

const Navbar = ({
  tabRoutes,
  isAgencyBilled,
  accountNumber,
  awsAppSyncClient,
  agencyBilledQuantity,
}: NavbarProps) => {
  const itemLabels = tabRoutes.map((tabRoute) => tabRoute.label);
  const location = useLocation();

  const { flagDetails: AgencyBilledModalFlag } = useFeatureFlag(
    'AgencyBilledModal',
  );
  const { flagDetails: ClaimsDisabledFlag } = useFeatureFlag(
    'ClaimsDisabledFlag',
  );

  function setDefaultActiveIndex(): number {
    const found = tabRoutes.findIndex(
      (tabRoute) => window.location.href.indexOf(tabRoute.pathMatch) > -1,
    );
    return found >= 0 ? found : 0;
  }

  const [activeIndex, setActiveIndex] = useState(setDefaultActiveIndex());
  const [showAgencyBilledModal, setShowAgencyBilledModal] = useState(false);
  const [showClaimsDisabledModal, setShowClaimsDisabledModal] = useState(false);

  const navigateToIndex = (index: number) => {
    navigate(tabRoutes[index].pathMatch);
    setActiveIndex(index);

    simpleGTMDataLayer({
      event: `Nav-${tabRoutes[index].label}-click`,
      event_action: 'Nav Click',
      event_category: tabRoutes[index].label,
      event_label: 'Nav Link',
    });

    simpleGTMDataLayer({
      event: `Snowplow-Nav-${tabRoutes[index].label}-click`,
      event_action: 'Nav Click',
      event_category: tabRoutes[index].label,
      event_label: 'Nav Link',
    });
  };

  useEffect(() => {
    window.addEventListener('popstate', () => {
      setActiveIndex(setDefaultActiveIndex());
    });
    setActiveIndex(
      tabRoutes
        .map((tabRoute) => tabRoute.pathMatch)
        .indexOf(location.pathname),
    );
  }, [location]);

  useEffect(() => {
    if (
      AgencyBilledModalFlag?.enabled &&
      isAgencyBilled &&
      tabRoutes[activeIndex].pathMatch === '/billing'
    ) {
      navigateToIndex(0);
      setShowAgencyBilledModal(true);
    }
  }, [AgencyBilledModalFlag, accountNumber, isAgencyBilled]);

  function onTabBarChange(newActiveIndex: number) {
    if (
      AgencyBilledModalFlag?.enabled &&
      isAgencyBilled &&
      tabRoutes[newActiveIndex].pathMatch === '/billing'
    ) {
      setShowAgencyBilledModal(true);
    } else if (
      ClaimsDisabledFlag?.enabled &&
      tabRoutes[newActiveIndex].pathMatch === '/claims'
    ) {
      setShowClaimsDisabledModal(true);
    } else {
      navigateToIndex(newActiveIndex);
    }
  }

  return (
    <>
      <div className="navBar">
        <SATabBar
          activeIndex={activeIndex}
          itemLabels={itemLabels}
          onChange={onTabBarChange}
        />
      </div>
      <AgencyBilledModal
        showModal={showAgencyBilledModal}
        closeModal={() => setShowAgencyBilledModal(false)}
        accountNumber={accountNumber}
        awsAppSyncClient={awsAppSyncClient}
        agencyBilledQuantity={agencyBilledQuantity}
      />
      {showClaimsDisabledModal && (
        <ClaimsDisabledModal closeModal={setShowClaimsDisabledModal} />
      )}
    </>
  );
};

export default Navbar;

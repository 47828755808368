import styled from 'styled-components';

export const Wrapper = styled.div`
  font-family: 'PT Sans', sans-serif;
  margin-bottom: 20px;
  background-color: #fff;
`;
export const TimePeriod = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #0173c6;
  padding: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
`;
export const Table = styled.table`
  padding-bottom: 30px;
  width: 100%;
`;

export const TableRow = styled.tr`
  background-color: #f8f8f8;
  height: 32px;
  border-bottom: 1px solid var(--grays-200-gray-blue, #d2dbe7);
  &:hover {
    background-color: #e0e0e0;
  }
`;
export const ClassHeader = styled.td`
  color: var(--text-primary-text-black, #3b3b3b);
  font-size: 13.33px;
  font-style: normal;
  font-weight: 700;g
  line-height: normal;
  width: 228px;
  padding: 20px;
`;
export const TableHeader = styled.td`
  color: var(--text-primary-text-black, #3b3b3b);
  text-align: right;
  font-size: 13.33px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 114px;
  padding: 20px;
`;
export const Header = styled.tr`
  width: 832px;
  height: 32px;
  flex-shrink: 0;
  background: var(--grays-50-gray-blue, #f4f6fa);
  border-spacing: 0;
`;
export const TableCell = styled.td`
  padding: 20px;
  color: var(--text-primary-text-black, #3b3b3b);
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
`;
export const ClassCell = styled.td`
  padding: 20px;
  color: var(--text-primary-text-black, #3b3b3b);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
`;
export const Result = styled.td`
  padding: 20px;
  color: var(--text-primary-text-black, #3b3b3b);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: right;
`;

export const ResultLabel = styled.td`
  padding: 20px;
  color: var(--text-primary-text-black, #3b3b3b);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
export const Location = styled.div`
  color: var(--text-primary-text-black, #3b3b3b);
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-left: 10px;
  padding-bottom: 40px;
`;

export const Green = styled.span`
  width: 4px;
  height: 20px;
  flex-shrink: 0;
  background: var(--primary-action-green, #4d840b);
  margin-right: 10px; /* Adjust the margin as needed */
`;
export const ReportedPayroll = styled.span`
  color: var(--text-primary-text-black, #3b3b3b);
  text-align: right;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-right: 0px;
`;

export const Icon = styled.span`
  padding: 10px;
`;
export const SummaryContainer = styled.div`
  display: flex;
  height: 54px;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 17px;
  align-items: center;
  flex-shrink: 0;
  background: var(--grays-100-gray-blue, #e3e9f1);
  justify-content: space-between;
`;

export const SummaryText = styled.div`
  color: var(--text-primary-text-black, #3b3b3b);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
export const Span = styled.span`
  margin-left: 10px;
`;

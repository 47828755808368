import styled, { css } from 'styled-components';
import customBase from '../../../util/global.theme';

const breakpoint = customBase.global.breakpoints.medium;

export const desktopCSS = (cssRules: string) => css`
  @media (min-width: ${breakpoint + 1}px) {
    ${cssRules}
  }
`;

export const responsiveCSS = (cssRules: string) => css`
  @media (max-width: ${breakpoint}px) {
    ${cssRules}
  }
`;

export const FeedbackModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  background: #ffffff;

  ${desktopCSS(`
    width: 450px;
    right: 0;
    box-shadow: 0px 2px 5px rgba(1, 83, 142, 0.2);
    border-radius: 3px;

    top: 50%;
    transform: translate(0, -50%);
  `)}

  ${responsiveCSS(`
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
  `)}

  z-index: 9999;
`;

export const TitleRow = styled.div`
  display: flex;

  ${desktopCSS(`
    padding: 12px 12px 0 20px;
  `)}

  ${responsiveCSS(`
    padding: 22px 22px 0 30px;
  `)}
`;

export const TitleText = styled.p`
  font-weight: 700;
  font-size: 19px;
  line-height: 23px;
  color: #3b3b3b;
  margin: 4px 0 0 0;
  flex-grow: 1;
`;

export const CloseButton = styled.div`
  display: block;
  padding: 1px;
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

export const DisclaimerWrap = styled.p`
  font-size: 13px;
  line-height: 17px;

  ${desktopCSS(`
    padding: 10px 20px 13px;
  `)}

  ${responsiveCSS(`
    padding: 10px 30px 23px;
  `)}
`;

export const DisclaimerNote = styled.span`
  font-weight: 700;
  color: #cd5234;
`;

export const DisclaimerContactUs = styled.a`
  text-decoration: none;
  font-weight: 700;
  color: #0174c6;
`;

export const FeedbackAlertWrap = styled.div`
  margin: 7px 20px 13px;

  ${responsiveCSS(`
    margin: 7px 30px 23px;
  `)}
`;

export const ButtonsRow = styled.div`
  display: flex;
  justify-content: end;
  padding: 20px;

  ${responsiveCSS(`
    padding: 30px;
  `)}
`;

export const CancelButton = styled.p`
  cursor: pointer;
  display: block;
  border-radius: 5px;
  background: #ffffff;
  font-weight: 700;
  font-size: 19px;
  line-height: 23px;
  color: #3b3b3b;
  line-height: 40px;
  padding: 0 25px;
  margin-right: 10px;

  &.cancel:hover {
    color: #ffffff;
    background-color: #0173c6;
  }

  ${responsiveCSS(`
    padding: 0 45px;
  `)}
`;

export const ShareButton = styled(CancelButton)`
  color: #ffffff;
  background-color: #4d840b;
  margin-right: 0;
`;

import React from 'react';
import { SideModal, Backdrop } from './SlideOutStyles';

interface SlideOutProps {
  children: any;
}

const SlideOut = ({ children }: SlideOutProps) => {
  return (
    <>
      <Backdrop />
      <SideModal id="slide-out-menu">{children}</SideModal>
    </>
  );
};

export default SlideOut;

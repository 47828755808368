import styled from 'styled-components';

export const MenuInitialsIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  min-height: 60px;

  *:hover {
    cursor: pointer;
  }

  background: #3e5572;
  border: 2px solid #e1f5ff;
  border-radius: 50%;
  box-sizing: border-box;
`;

export const InitialsHamburgerMenu = styled.div`
  position: relative;
  bottom: 20px;
`;

export default MenuInitialsIcon;

import { LocationProvider } from '@reach/router';
import { GlobalStyle, SAUX360Theme } from '@saux/design-system-react';
import Amplify, { Auth } from 'aws-amplify';
import AWSAppSyncClient from 'aws-appsync';
import { AuthOptions } from 'aws-appsync-auth-link';
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import App from './App';
import getConfig, { Env } from './aws_exports';
import * as serviceWorker from './serviceWorker';

const awsExports = getConfig(process.env.REACT_APP_ENV as Env);

Amplify.configure(awsExports);

const jwtTokenProcess = async () => {
  const jwtToken = await Promise.resolve(
    Auth.currentSession()
      .then((session) => session.getIdToken().getJwtToken())
      .catch((err) => {
        console.error('error', err);
      }),
  );

  return jwtToken;
};

jwtTokenProcess().then((result: string | void) =>
  sessionStorage.setItem('jwtToken', result === undefined ? '' : result),
);

const auth = {
  type: awsExports.aws_appsync_authenticationType,
  jwtToken: jwtTokenProcess(),
} as AuthOptions;

const awsAppSyncClient: AWSAppSyncClient<any> = new AWSAppSyncClient(
  {
    url: awsExports.aws_appsync_graphqlEndpoint,
    region: awsExports.aws_appsync_region,
    disableOffline: true,
    auth,
  },
  {
    defaultOptions: {
      query: {
        fetchPolicy: 'network-only',
      },
    },
  },
);

ReactDOM.render(
  <ThemeProvider theme={SAUX360Theme}>
    <LocationProvider>
      <GlobalStyle />
      <App awsAppSyncClient={awsAppSyncClient} />
    </LocationProvider>
  </ThemeProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();

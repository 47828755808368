import React, { useState, useEffect } from 'react';
import {
  SAText,
  SAButton,
  SAIcon,
  SAIcons,
  SAUX360Theme,
} from '@saux/design-system-react';
import styled, { css } from 'styled-components';
import {
  PayrollMainWrapper,
  ButtonWrapper,
  Text,
  TextWrapper,
  Button,
} from './ReviewPayrollStyles';
import { PayrollMessage, PayrollWrapper } from './ReportPayrollStyles';
import { Policy } from './graphql/SAVE_PREMIUM_AUDIT_DETAILS';
import {
  SummaryContainer,
  PolicyMainBox,
  PolicyLeftBox,
  PolicyLabel,
  PolicyValue,
  PolicyLabelText,
  SummaryText,
  PolicyValueText,
  PolicyRightBox,
  PolicyRightButton,
} from '../priorReport/PriorReportsStyles';
import { ResponseData, Location } from './graphql/GET_PREMIUM_DETAILS';
import PayrollAlert from '../payrollAlerts/PayrollAlert';
import { formatCurrency, formatDatePayg } from '../../util/formatters';
import JurisdictionDetails from './JurisdictionDetails';
import Spinning from './Spinner';
import ReviewAuditDetails from './ReviewAuditDetails';

interface ReviewPayrollProps {
  premiumDetails: ResponseData;
  editbuttonHandler: () => void;
  bindEditHandler: () => void;
  setReviewPayroll: React.Dispatch<React.SetStateAction<boolean>>;
  accountNumber: string;
  modalType: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSaveResponse: Policy | undefined;
  isLoading: boolean;
  setLoading: Function;
  hideSubmit: boolean;
  effectiveStartDate: String;
  effectiveEndDate: String;
  isReview: boolean;
}

const ReviewPayrollComponent = ({
  premiumDetails,
  editbuttonHandler,
  bindEditHandler,
  setReviewPayroll,
  accountNumber,
  modalType,
  isOpen,
  setIsOpen,
  onSaveResponse,
  isLoading,
  setLoading,
  hideSubmit,
  effectiveStartDate,
  effectiveEndDate,
  isReview,
}: ReviewPayrollProps) => {
  const [data, setData] = useState<
    | ResponseData['getPremiumAuditTxnDetails']['result']
    | Policy['result']
    | undefined
    | any
  >();

  const BannerIconWrapper = styled.div`
    ${({ size }: { size: string }) => css`
      width: ${size};
      flex-basis: ${size};
      margin-right: 20px;
      display: flex;
      align-items: center;
    `}
  `;
  const BannerTextContainer = styled.div`
    display: flex;
  `;

  const [policyNumber, setPolicyNumber] = useState<String | null>();
  const [effDate, seteffDate] = useState<String | null | undefined>();
  const allLocationsArrays: Location[][] = [];
  const allLocationNames: string[] = [];
  const [locationDetails, setLocationDetails] = useState<Location[][]>();
  const [locationName, setLocationName] = useState<string[]>([]);
  const [tReportedpayroll, setTReportedPayroll] = useState<number>(0);
  const [tPremiumDue, setTPremiumDue] = useState<string>('');

  useEffect(() => {
    if (onSaveResponse) {
      setData(onSaveResponse.result);
      if (isReview){
        window.location.reload();
      }
    }
    else {
      setData(premiumDetails?.getPremiumAuditTxnDetails?.result);
    }
  }, [premiumDetails, onSaveResponse]);

  useEffect(() => {
    setPolicyNumber(data?.policyNumber);
    const effStartDate = formatDatePayg(data?.policyEffectiveDate);
    const effEndDate = formatDatePayg(data?.policyExpirationDate);
    seteffDate(`${effStartDate} - ${effEndDate}`);
    if (
      data &&
      data?.currentReport &&
      data?.currentReport?.premiumAuditBaseDetails &&
      data?.currentReport?.premiumAuditBaseDetails?.totalPremiumDue
    ) {
      setTPremiumDue(
        data?.currentReport?.premiumAuditBaseDetails?.totalPremiumDue,
      );
    }
    if (
      data &&
      data?.currentReport &&
      data?.currentReport?.premiumAuditBaseDetails &&
      data?.currentReport?.premiumAuditBaseDetails?.totalReportedPayRoll
    ) {
      setTReportedPayroll(
        data?.currentReport?.premiumAuditBaseDetails?.totalReportedPayRoll,
      );
    }
  }, [data]);

  useEffect(() => {
    if (data && 'currentReport' in data) {
      if ('jurisdictions' in data.currentReport) {
        data.currentReport.jurisdictions.forEach((jurisdiction: any) => {
          if ('jurisdictionName' in jurisdiction) {
            allLocationNames.push(jurisdiction.jurisdictionName);
          }
          if ('periods' in jurisdiction) {
            jurisdiction.periods.forEach((period: any) => {
              if ('locations' in period) {
                const locationsArray: Location[] = period.locations;
                allLocationsArrays.push(locationsArray);
              }
            });
          }
        });
      }
    }
    setLocationName(allLocationNames);
    setLocationDetails(allLocationsArrays);
  }, [data]);
  useEffect(() => {
    let payroll = 0;
    allLocationsArrays.forEach((location) => {
      location.forEach((value) => {
        value.coveredEmployees.forEach((employee) => {
          const val =
            employee && employee.auditedPayroll !== undefined
              ? employee.auditedPayroll
              : 0;
          payroll =
            parseInt(payroll.toString(), 10) + parseInt(val.toString(), 10);
          setTReportedPayroll(payroll);
        });
      });
    });
  }, [allLocationsArrays]);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const editButtonClick = () => {
    editbuttonHandler();
    setReviewPayroll(false);
  };

  return (
    <>
      {isLoading ? (
        <Spinning />
      ) : (
        <>
          {data && (
            <div style={{ backgroundColor: '#fff', padding: '20px' }}>
              <TextWrapper>
                <Text>
                  Please review payrolls and total premium due.{' '}
                  <b>
                    Your submission is not complete until you submit the report.
                  </b>
                </Text>
                <ButtonWrapper>
                  {!hideSubmit && (
                    <>
                      <Button>
                        <SAButton
                          label="REVIEW PAYROLL"
                          variant="primary-medium"
                          onClick={bindEditHandler}
                        >
                          Submit Payroll
                        </SAButton>
                      </Button>
                      <Button>
                        <SAButton
                          label="Edit"
                          variant="medium-outline"
                          onClick={editButtonClick}
                        >
                          Edit
                        </SAButton>
                      </Button>
                    </>
                  )}
                </ButtonWrapper>
              </TextWrapper>
              {isOpen && (
                <PayrollAlert
                  accountNumber={accountNumber}
                  effectiveDate={`${effectiveStartDate} - ${effectiveEndDate}`}
                  isOpen={isOpen}
                  toggleModalHandler={toggleModal}
                  modalType={modalType}
                />
              )}
              <SummaryContainer>
                <SummaryText>Summary</SummaryText>
              </SummaryContainer>
              <PolicyMainBox>
                <PolicyLeftBox>
                  <PolicyLabel>
                    <PolicyLabelText>Policy Number</PolicyLabelText>
                    <PolicyLabelText>Effective Date</PolicyLabelText>

                    <PolicyLabelText>Total Reported Payroll</PolicyLabelText>

                    <PolicyLabelText>Total Premium Due</PolicyLabelText>
                  </PolicyLabel>
                  <PolicyValue>
                    <PolicyValueText>{policyNumber}</PolicyValueText>
                    <PolicyValueText>{effDate}</PolicyValueText>

                    <PolicyValueText>
                      {formatCurrency(tReportedpayroll)}
                    </PolicyValueText>

                    <PolicyValueText>
                      {formatCurrency(tPremiumDue)}
                    </PolicyValueText>
                  </PolicyValue>
                </PolicyLeftBox>
                <PolicyRightBox>
                  <PolicyRightButton onClick={window.print}>Print</PolicyRightButton>
                </PolicyRightBox>
              </PolicyMainBox>

              {locationDetails
                ? locationDetails.map((location, index) => (
                  <JurisdictionDetails
                    locationDetails={location}
                    jurisdictionName={locationName[index]}
                  />
                ))
                : null}
            </div>
          )}
          {!data && (
            <PayrollMainWrapper>
              <PayrollWrapper>
                <PayrollMessage>
                  <div>
                    <BannerTextContainer>
                      <BannerIconWrapper size="25px">
                        <SAIcon
                          icon={SAIcons.alert}
                          size="25px"
                          colorVariant={SAUX360Theme.colors.error}
                        />
                      </BannerIconWrapper>
                      <b>
                        Unfortunately no payroll data is available at this
                        moment. Please try again after sometime.
                      </b>
                    </BannerTextContainer>
                  </div>
                </PayrollMessage>
              </PayrollWrapper>
            </PayrollMainWrapper>
          )}
        </>
      )}
    </>
  );
};

export default ReviewPayrollComponent;

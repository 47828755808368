import React, { useState, useEffect } from 'react';
import { FetchResult } from '@apollo/react-hooks';
import AWSAppSyncClient from 'aws-appsync';
import { loader } from 'graphql.macro';
import LogRocket from 'logrocket';
import FeedbackModalBody, { FeedbackSuccessToast } from './feedbackModalBody';
import FeedbackFloatingButton from './feedbackFloatingButton';

const provideFeedbackMutation = loader(
  '../../graphql/mutations/Put_Feedback.graphql',
);

interface Props {
  accountNumber: string | undefined;
  awsAppSyncClient: AWSAppSyncClient<any>;
  userName: string | undefined;
  userEmail: string | undefined;
  feedbackModalLink: Function;
}

export default ({
  accountNumber,
  awsAppSyncClient,
  userName,
  userEmail,
  feedbackModalLink,
}: Props) => {
  const [error, setError] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [feedbackSuccess, setFeedbackSuccess] = useState<boolean>(false);

  useEffect(() => {
    feedbackModalLink(() => setShowModal(true));
  }, []);

  const handleClose = () => {
    if (document.body.style.overflow === 'hidden') {
      document.body.style.overflow = 'auto';
    }

    setShowModal(false);
  };

  const accountnumber: null | string = accountNumber ?? 'N/A';
  const username: null | string = userName ?? 'N/A';
  const useremail: null | string = userEmail ?? 'N/A';

  let logrocketurl: string = 'N/A';

  LogRocket.getSessionURL((sessionURL: string) => {
    if (sessionURL) {
      logrocketurl = sessionURL;
      sessionStorage.setItem('logrocketURL', logrocketurl);
    }
  });

  const submitFeedback = (feedback: string, topic: string) => {
    const variables = {
      account_number: accountnumber,
      userName: username,
      userEmail: useremail,
      appPath: window.location.pathname,
      logrocketurl,
      feedback,
      topic,
    };

    const mutationResult: Promise<FetchResult<any>> = awsAppSyncClient.mutate({
      mutation: provideFeedbackMutation,
      fetchPolicy: 'no-cache',
      variables,
    }) as Promise<FetchResult<any>>;
    mutationResult
      .then((fetchResult: FetchResult<any>) => {
        if (fetchResult) {
          setFeedbackSuccess(true);
          handleClose();
        }
      })
      .catch((err: Error) => {
        setError(true);
        console.error(err);
      });
  };

  return (
    <>
      {showModal ? (
        <FeedbackModalBody
          submit={submitFeedback}
          closeModal={handleClose}
          error={error}
        />
      ) : (
        <FeedbackFloatingButton showModal={() => setShowModal(true)} />
      )}
      <FeedbackSuccessToast
        isOpen={feedbackSuccess}
        close={() => setFeedbackSuccess(false)}
      />
    </>
  );
};

/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/naming-convention */
import AWSAppSyncClient from 'aws-appsync';
import { loader } from 'graphql.macro';
import { ApolloQueryResult } from 'apollo-client';
import getConfig, { Env } from '../aws_exports';
import {
  GET_POLICIES_BY_ACCOUNT,
  GET_POLICIES_BY_ACCOUNTVariables,
} from '../generated/GET_POLICIES_BY_ACCOUNT';

const getPoliciesByAccountQuery = loader(
  '../graphql/queries/Get_Policies_by_Account.graphql',
);

declare global {
  interface Window {
    fcWidget: any;
  }
  class chatWidget {
    static loadChatWidget: Function;

    static putProperties: Function;

    static hide: Function;

    static show: Function;
  }
}

export interface CognitoUser {
  username: string;
  attributes: CognitoAttributes;
}

interface CognitoAttributes {
  email: string;
  name: string;
  given_name: string;
}

const config = getConfig(process.env.REACT_APP_ENV as Env);

let script: HTMLScriptElement;
class FreshChat {
  static initialize = (
    appsyncClient: AWSAppSyncClient<any>,
    user: CognitoUser,
    accountNumber: string,
    sourceSystemCode: string,
  ) => {
    const userId: string = user?.username?.replace('EmpowerId_', '') ?? '';
    const loadScript = new Promise((resolve, reject) => {
      script = document.createElement('script');
      script.src = config.chat_widget;
      script.addEventListener('load', () => {
        resolve(true);
      });
      script.addEventListener('error', (e) => {
        reject(e);
      });
      document.body.appendChild(script);
    });
    loadScript
      .then(() => {
        chatWidget.loadChatWidget({
          userId,
          token: config.freshchat_token,
          siteId: 'Customer360',
          tags: ['customer360'],
          email: user?.attributes?.email,
          firstName: user?.attributes?.name,
          lastName: user?.attributes?.given_name,
          accountNumber,
          isInsured: true,
          sourceSystemCode,
        });
        if (accountNumber !== '') getAndSetPolicies(accountNumber);
      })
      .catch((err) => {
        console.error(err);
      });

    const setPolicies = (policies: string) => {
      chatWidget.putProperties({
        account: accountNumber,
        policies,
      });
    };

    const getPolicies = (account: string) => {
      const variables: GET_POLICIES_BY_ACCOUNTVariables = {
        account_number: account,
      } as GET_POLICIES_BY_ACCOUNTVariables;
      return appsyncClient.query({
        query: getPoliciesByAccountQuery,
        variables,
      }) as Promise<ApolloQueryResult<GET_POLICIES_BY_ACCOUNT>>;
    };

    const getAndSetPolicies = (accountn: string) => {
      getPolicies(accountn).then(
        (policiesData: ApolloQueryResult<GET_POLICIES_BY_ACCOUNT>) => {
          if (policiesData?.data?.account?.items) {
            const account = policiesData?.data?.account?.items[0];
            const policiesItems = account?.policies?.items;
            const policies: string[] = [];
            policiesItems?.forEach((item) => {
              if (item?.policy_number) {
                sessionStorage.setItem('policyNumber', item?.policy_number);
                chatWidget.putProperties({
                  policyNumber: item?.policy_number,
                });
                policies.push(item?.policy_number);
              }
            });

            setPolicies(policies.join(','));
          }
        },
      );
    };
  };

  static hide = () => {
    if (script) {
      chatWidget.hide();
    }
  };

  static show = () => {
    if (script) {
      chatWidget.show();
    }
  };
}
// eslint-disable-next-line
export { FreshChat };

import React, { useState, useEffect } from 'react';
import AWSAppSyncClient from 'aws-appsync';
import { loader } from 'graphql.macro';
import { ApolloQueryResult } from 'apollo-client';
import {
  PayrollMainWrapper,
  HeaderTabWrappper,
  HeaderTab,
  PayrollReportTab,
  PriorReportTab,
  PariorContainer,
  TextStylingA,
  ActiveTab,
  PayrollTextContainer,
  TextStyling,
  InstructionWrapper,
  InstructionDiv,
  InstructionPDF,
  InstructionVideo,
} from './RPWrapperStyles';
import ReportPayroll from '../ReportPayroll';
import PriorReport from '../priorReport';
import {
  GET_USERNAV_INFO,
  GET_USERNAV_INFOVariables,
} from '../../generated/GET_USERNAV_INFO';
import { ReactComponent as Document } from '../../assets/images/Document.svg';

const getUsernameByAccount = loader(
  '../../graphql/queries/Get_UserNav_Info.graphql',
);

interface ReportPayrollWrapperProps {
  awsAppSyncClient: AWSAppSyncClient<any>;
  accountNumber: string;
  job_number: string | undefined;
}

const ReportPayrollWrapper = ({
  awsAppSyncClient,
  accountNumber,
  job_number,
}: ReportPayrollWrapperProps) => {
  const [currentTab, setCurrentTab] = useState<string>('payroll');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const payg = urlParams.get('payg');
    if (payg === 'true') {
      setCurrentTab('prior');
    }
  }, []);
  const pdfUrl = 'https://d2vsak2pdryzho.cloudfront.net/PAYG_INSTRUCTION.pdf';
  const vedioUrl = 'https://youtu.be/bBuIJLyj4fU';
  const openPdf = () => {
    window.open(pdfUrl, '_blank');
  };
  const openVideo = () => {
    window.open(vedioUrl, '_blank');
  };

  return (
    <PayrollMainWrapper>
      <InstructionWrapper onClick={openPdf}>
        <InstructionDiv>
          <Document />
          &nbsp; INSTRUCTIONAL &nbsp;&nbsp;&nbsp;&nbsp;
          <InstructionPDF onClick={openPdf}>pdf &nbsp; </InstructionPDF>
          {/* <InstructionVideo onClick={openVideo}>
            &nbsp;&nbsp; Video
          </InstructionVideo> */}
        </InstructionDiv>
      </InstructionWrapper>
      <HeaderTabWrappper>
        <HeaderTab>
          {currentTab === 'payroll' ? (
            <PayrollReportTab>
              <ActiveTab onClick={() => setCurrentTab('payroll')}>
                <PayrollTextContainer>
                  <TextStylingA> Report Payroll </TextStylingA>
                </PayrollTextContainer>
              </ActiveTab>
            </PayrollReportTab>
          ) : (
            <PayrollReportTab onClick={() => setCurrentTab('payroll')}>
              <PayrollTextContainer>
                <TextStyling> Report Payroll </TextStyling>
              </PayrollTextContainer>
            </PayrollReportTab>
          )}
          {currentTab === 'prior' ? (
            <PayrollReportTab>
              <ActiveTab onClick={() => setCurrentTab('prior')}>
                <PayrollTextContainer>
                  <TextStylingA> Previous Payroll </TextStylingA>
                </PayrollTextContainer>
              </ActiveTab>
            </PayrollReportTab>
          ) : (
            <PriorReportTab onClick={() => setCurrentTab('prior')}>
              <PayrollTextContainer>
                <TextStyling> Previous Payroll </TextStyling>
              </PayrollTextContainer>
            </PriorReportTab>
          )}
        </HeaderTab>
      </HeaderTabWrappper>
      {currentTab === 'payroll' ? (
        <ReportPayroll
          awsAppSyncClient={awsAppSyncClient}
          accountNumber={accountNumber}
        />
      ) : (
        <PriorReport
          awsAppSyncClient={awsAppSyncClient}
          accountNumber={accountNumber}
        />
      )}
    </PayrollMainWrapper>
  );
};

export default ReportPayrollWrapper;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SAUXTheme, SAButton } from '@saux/design-system-react';
import getConfig, { Env } from '../../aws_exports';
import { ReactComponent as CpraImg } from '../../assets/images/CPRA_32px.svg';

const config = getConfig(process.env.REACT_APP_ENV as Env);

interface SAFooterProps {
  removeBranding?: boolean;
  theme?: SAUXTheme;
  CARightsRequired?: boolean;
  [additionalProps: string]: any;
}

const Footer = styled.footer`
  ${(props: SAFooterProps) => {
    const { theme } = props;
    return `
      align-items: center;
      color: ${theme?.color.background.foreground};
      display: flex;
      flex-direction: row;
      font-family: ${theme?.font.primary.family};
      font-size: ${theme?.font.primary.size.smallTEMP};
      height: ${theme?.size.spacer.xl};
      justify-content: center;
      text-align: center;

      a {
        font-weight: ${theme?.font.primary.weight.bold};
        margin-left: ${theme?.size.spacer.xs};
      }

      a:link,
      a:visited {
        color: ${theme?.color.background.foreground};
        text-decoration: none;
      }

      a:hover {
        color: ${theme?.color.primary.background};
        text-decoration: underline;
      }

      @media only screen and (max-width: 700px) {
        flex-direction: column;
      }
    `;
  }};
`;
const PopUpLink = styled.span`
  font-weight: ${({ theme }) => theme?.font.primary.weight.bold};
  margin-left: ${({ theme }) => theme?.size.spacer.xs};
  color: ${({ theme }) => theme?.color.background.foreground};
  text-decoration: none;
  cursor: pointer;

  &:link,
  &:visited {
    color: ${({ theme }) => theme?.color.background.foreground};
  }

  &:hover {
    color: ${({ theme }) => theme?.color.primary.background};
    text-decoration: underline;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3vw;
`;
const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LeftWrapper = styled.div`
  margin-left: 10px;
`;

const RightWrapper = styled.div`
  align-items: center;
`;

const SACustomFooter = ({
  removeBranding,
  CARightsRequired,
  ...rest
}: SAFooterProps) => {
  const handleTestClick = () => {
    /* @ts-ignore */
    window.utag?.gdpr?.showDoNotSellPrompt?.();
  };
  const handleTestKeyPress = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (event.key === 'Enter') {
      handleTestClick();
    }
  };
  const branding =
    removeBranding === true
      ? 'Company'
      : 'State Automobile Mutual Insurance Company';
  const today = new Date();
  const year = today.getFullYear().toString();
  const text = `© ${year} Copyright ${branding}. All rights reserved.`;
  const links = !removeBranding ? (
    <MainWrapper>
      <MainWrapper>
        <a href="https://www.libertymutualgroup.com/about-lm/corporate-information/privacy-policy">
          Privacy Policy &nbsp;|
        </a>
        <a href="https://www.stateauto.com/terms">Terms of Use&nbsp;|</a>
        {CARightsRequired && (
          <MainWrapper>
            <LeftWrapper>
              <CpraImg />
            </LeftWrapper>
            <RightWrapper>
              <a href={config.carights_url}>
                Your California Privacy Choices&nbsp;|&nbsp;
              </a>
            </RightWrapper>
          </MainWrapper>
        )}

        <PopUpLink
          role="button"
          tabIndex={0}
          onClick={handleTestClick}
          onKeyDown={handleTestKeyPress}
        >
          Do Not Sell or Share My Personal Information (CA Residents Only)
        </PopUpLink>
      </MainWrapper>
    </MainWrapper>
  ) : (
    ''
  );

  return (
    <Footer {...rest}>
      <Wrapper>
        {text}
        <span>{links}</span>
      </Wrapper>
    </Footer>
  );
};

export default SACustomFooter;

import React, { useState } from 'react';
import {
  SAColumns,
  SAIcons,
  SAInput,
  SAStack,
  SAText,
} from '@saux/design-system-react';
// eslint-disable-next-line import/no-named-as-default
import {
  PasswordContentRow,
  HeaderWrapper,
  PasswordHeaderWrapper,
  PasswordRowValueWrapper,
  EditPasswordContentRow,
  RowValueWrapper,
  EditingRowValueWrapper,
  InputWrapper,
  ContentRowSpacer,
  TextHolder,
  GreenWrapper,
  CheckWrapper,
  ResponsiveRow,
} from './ProfilePageStyles';
import { ReactComponent as GreenCheck } from '../../assets/images/GreenCheck.svg';
import { ReactComponent as ClearAlert } from '../../assets/images/ClearAlert.svg';
import customBase from '../../util/global.theme';
import useIsResponsive from '../../util/useIsResponsive';

interface ProfilePagePasswordRowProps {
  edit: boolean;
  oldPassword: string | undefined;
  confirmPassword: string | undefined;
  setOldPassword: Function;
  setNewPassword: Function;
  setConfirmPassword: Function;
  setPasswordError: Function;
  passwordIsLongEnough: boolean;
  passwordHasCapital: boolean;
  passwordHasNumber: boolean;
  passwordHasLowercase: boolean;
  passwordHasSpecialChar: boolean;
  passwordError: boolean;
  passwordsDoNotMatch: boolean;
  setPasswordsDoNotMatch: Function;
}

const ProfilePagePasswordRow = ({
  edit,
  oldPassword,
  confirmPassword,
  setOldPassword,
  setNewPassword,
  setConfirmPassword,
  passwordIsLongEnough,
  passwordHasCapital,
  passwordHasNumber,
  passwordHasLowercase,
  passwordHasSpecialChar,
  passwordError,
  passwordsDoNotMatch,
  setPasswordError,
  setPasswordsDoNotMatch,
}: ProfilePagePasswordRowProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const isResponsive = useIsResponsive();

  return (
    <>
      {edit ? (
        <EditPasswordContentRow>
          {isResponsive && (
            <>
              <SAStack spacing="small">
                <ContentRowSpacer>
                  <PasswordHeaderWrapper>
                    <SAText
                      text="Old Password"
                      type="paragraph"
                      colorVariant="info-foreground"
                      weight="bold"
                    />
                  </PasswordHeaderWrapper>
                  <RowValueWrapper>
                    <EditingRowValueWrapper>
                      <InputWrapper>
                        <SAInput
                          data-private
                          id="profile-page-old-password-input"
                          test-attr="profile-page-old-password-input"
                          label="Old Password"
                          fullWidth
                          type={showPassword ? 'text' : 'password'}
                          onClick={() => {
                            if (passwordError) setPasswordError(false);
                          }}
                          onKeyDown={(evt) => {
                            if (evt.code === 'Space') evt.preventDefault();
                          }}
                          onChange={(evt) => {
                            setOldPassword(evt.target.value.replace(/\s/g, ''));
                          }}
                          defaultValue={oldPassword ?? ''}
                          icon={SAIcons.eye}
                          onClickIcon={() => setShowPassword(!showPassword)}
                        />
                        {passwordError && (
                          <SAText
                            text="Old password is incorrect"
                            type="small"
                            colorVariant="alert"
                          />
                        )}
                      </InputWrapper>
                    </EditingRowValueWrapper>
                  </RowValueWrapper>
                </ContentRowSpacer>
                <ContentRowSpacer>
                  <PasswordHeaderWrapper>
                    <SAText
                      text="New Password"
                      type="paragraph"
                      colorVariant="info-foreground"
                      weight="bold"
                    />
                  </PasswordHeaderWrapper>
                  <RowValueWrapper>
                    <EditingRowValueWrapper>
                      <InputWrapper>
                        <SAInput
                          data-private
                          id="profile-page-new-password-input"
                          test-attr="profile-page-new-password-input"
                          label="New Password"
                          type={showPassword ? 'text' : 'password'}
                          fullWidth
                          onKeyDown={(evt) => {
                            if (evt.code === 'Space') evt.preventDefault();
                          }}
                          onChange={(evt) => {
                            setNewPassword(evt.target.value.replace(/\s/g, ''));
                          }}
                          icon={SAIcons.eye}
                          onClickIcon={() => setShowPassword(!showPassword)}
                        />
                        {!passwordIsLongEnough && confirmPassword && (
                          <SAText
                            text="Password must be at least 12 characters"
                            type="small"
                            colorVariant="alert"
                          />
                        )}
                      </InputWrapper>
                    </EditingRowValueWrapper>
                  </RowValueWrapper>
                </ContentRowSpacer>
                <ContentRowSpacer>
                  <PasswordHeaderWrapper>
                    <SAText
                      text="Confirm Password"
                      type="paragraph"
                      colorVariant="info-foreground"
                      weight="bold"
                    />
                  </PasswordHeaderWrapper>
                  <RowValueWrapper>
                    <EditingRowValueWrapper>
                      <InputWrapper>
                        <SAInput
                          data-private
                          id="profile-page-confirm-password-input"
                          test-attr="profile-page-confirm-password-input"
                          label="Confirm Password"
                          type={showPassword ? 'text' : 'password'}
                          fullWidth
                          onKeyDown={(evt) => {
                            if (evt.code === 'Space') evt.preventDefault();
                          }}
                          onChange={(evt) => {
                            setConfirmPassword(
                              evt.target.value.replace(/\s/g, ''),
                            );
                          }}
                          onClick={() => {
                            if (passwordsDoNotMatch)
                              setPasswordsDoNotMatch(false);
                          }}
                          icon={SAIcons.eye}
                          onClickIcon={() => setShowPassword(!showPassword)}
                        />
                        {passwordsDoNotMatch && (
                          <SAText
                            text="Passwords must match"
                            type="small"
                            colorVariant="alert"
                          />
                        )}
                      </InputWrapper>
                    </EditingRowValueWrapper>
                  </RowValueWrapper>
                </ContentRowSpacer>
              </SAStack>
              <SAStack spacing="small">
                <ResponsiveRow>
                  <InputWrapper>
                    <TextHolder>
                      <SAText
                        text="Password must be at least 12 characters, not include personal/account information or spaces, and include all 4 of the following characteristics:"
                        type="small"
                        alignment="left"
                      />
                      {!passwordHasCapital && (
                        <GreenWrapper>
                          <SAText
                            text="• Upper case letter"
                            type="small"
                            alignment="left"
                          />
                          <CheckWrapper>
                            <ClearAlert />
                          </CheckWrapper>
                        </GreenWrapper>
                      )}
                      {passwordHasCapital && (
                        <GreenWrapper>
                          <SAText
                            text="• Upper case letter"
                            type="small"
                            alignment="left"
                            colorVariant="secondary-background"
                          />
                          <CheckWrapper>
                            <GreenCheck />
                          </CheckWrapper>
                        </GreenWrapper>
                      )}
                      {!passwordHasLowercase && (
                        <GreenWrapper>
                          <SAText
                            text="• Lower case letter"
                            type="small"
                            alignment="left"
                          />
                          <CheckWrapper>
                            <ClearAlert />
                          </CheckWrapper>
                        </GreenWrapper>
                      )}
                      {passwordHasLowercase && (
                        <GreenWrapper>
                          <SAText
                            text="• Lower case letter"
                            type="small"
                            alignment="left"
                            colorVariant="secondary-background"
                          />
                          <CheckWrapper>
                            <GreenCheck />
                          </CheckWrapper>
                        </GreenWrapper>
                      )}
                      {!passwordHasNumber && (
                        <GreenWrapper>
                          <SAText
                            text="• Number"
                            type="small"
                            alignment="left"
                          />
                          <CheckWrapper>
                            <ClearAlert />
                          </CheckWrapper>
                        </GreenWrapper>
                      )}
                      {passwordHasNumber && (
                        <GreenWrapper>
                          <SAText
                            text="• Number"
                            type="small"
                            alignment="left"
                            colorVariant="secondary-background"
                          />
                          <CheckWrapper>
                            <GreenCheck />
                          </CheckWrapper>
                        </GreenWrapper>
                      )}
                      {!passwordHasSpecialChar && (
                        <GreenWrapper>
                          <SAText
                            text="• Special character"
                            type="small"
                            alignment="left"
                          />
                          <CheckWrapper>
                            <ClearAlert />
                          </CheckWrapper>
                        </GreenWrapper>
                      )}
                      {passwordHasSpecialChar && (
                        <GreenWrapper>
                          <SAText
                            text="• Special character"
                            type="small"
                            alignment="left"
                            colorVariant="secondary-background"
                          />
                          <CheckWrapper>
                            <GreenCheck />
                          </CheckWrapper>
                        </GreenWrapper>
                      )}
                    </TextHolder>
                  </InputWrapper>
                </ResponsiveRow>
              </SAStack>
            </>
          )}
          {!isResponsive && (
            <>
              <SAColumns columns={[8, 4]}>
                <SAStack spacing="small">
                  <ContentRowSpacer>
                    <PasswordHeaderWrapper>
                      <SAText
                        text="Old Password"
                        type="paragraph"
                        colorVariant="info-foreground"
                        weight="bold"
                      />
                    </PasswordHeaderWrapper>
                    <RowValueWrapper>
                      <EditingRowValueWrapper>
                        <InputWrapper>
                          <SAInput
                            data-private
                            id="profile-page-old-password-input"
                            test-attr="profile-page-old-password-input"
                            label="Old Password"
                            fullWidth
                            type={showPassword ? 'text' : 'password'}
                            onClick={() => {
                              if (passwordError) setPasswordError(false);
                            }}
                            onKeyDown={(evt) => {
                              if (evt.code === 'Space') evt.preventDefault();
                            }}
                            onChange={(evt) => {
                              setOldPassword(
                                evt.target.value.replace(/\s/g, ''),
                              );
                            }}
                            defaultValue={oldPassword ?? ''}
                            icon={SAIcons.eye}
                            onClickIcon={() => setShowPassword(!showPassword)}
                          />
                          {passwordError && (
                            <SAText
                              text="Old password is incorrect"
                              type="small"
                              colorVariant="alert"
                            />
                          )}
                        </InputWrapper>
                      </EditingRowValueWrapper>
                    </RowValueWrapper>
                  </ContentRowSpacer>
                  <ContentRowSpacer>
                    <PasswordHeaderWrapper>
                      <SAText
                        text="New Password"
                        type="paragraph"
                        colorVariant="info-foreground"
                        weight="bold"
                      />
                    </PasswordHeaderWrapper>
                    <RowValueWrapper>
                      <EditingRowValueWrapper>
                        <InputWrapper>
                          <SAInput
                            data-private
                            id="profile-page-new-password-input"
                            test-attr="profile-page-new-password-input"
                            label="New Password"
                            type={showPassword ? 'text' : 'password'}
                            fullWidth
                            onKeyDown={(evt) => {
                              if (evt.code === 'Space') evt.preventDefault();
                            }}
                            onChange={(evt) => {
                              setNewPassword(
                                evt.target.value.replace(/\s/g, ''),
                              );
                            }}
                            icon={SAIcons.eye}
                            onClickIcon={() => setShowPassword(!showPassword)}
                          />
                          {!passwordIsLongEnough && confirmPassword && (
                            <SAText
                              text="Password must be at least 12 characters"
                              type="small"
                              colorVariant="alert"
                            />
                          )}
                        </InputWrapper>
                      </EditingRowValueWrapper>
                    </RowValueWrapper>
                  </ContentRowSpacer>
                  <ContentRowSpacer>
                    <PasswordHeaderWrapper>
                      <SAText
                        text="Confirm Password"
                        type="paragraph"
                        colorVariant="info-foreground"
                        weight="bold"
                      />
                    </PasswordHeaderWrapper>
                    <RowValueWrapper>
                      <EditingRowValueWrapper>
                        <InputWrapper>
                          <SAInput
                            data-private
                            id="profile-page-confirm-password-input"
                            test-attr="profile-page-confirm-password-input"
                            label="Confirm Password"
                            type={showPassword ? 'text' : 'password'}
                            fullWidth
                            onKeyDown={(evt) => {
                              if (evt.code === 'Space') evt.preventDefault();
                            }}
                            onChange={(evt) => {
                              setConfirmPassword(
                                evt.target.value.replace(/\s/g, ''),
                              );
                            }}
                            onClick={() => {
                              if (passwordsDoNotMatch)
                                setPasswordsDoNotMatch(false);
                            }}
                            icon={SAIcons.eye}
                            onClickIcon={() => setShowPassword(!showPassword)}
                          />
                          {passwordsDoNotMatch && (
                            <SAText
                              text="Passwords must match"
                              type="small"
                              colorVariant="alert"
                            />
                          )}
                        </InputWrapper>
                      </EditingRowValueWrapper>
                    </RowValueWrapper>
                  </ContentRowSpacer>
                </SAStack>
                <SAStack spacing="small">
                  <ContentRowSpacer>
                    <EditingRowValueWrapper>
                      <InputWrapper>
                        <TextHolder>
                          <SAText
                            text="Password must be at least 12 characters, not include personal/account information or spaces, and include all 4 of the following characteristics:"
                            type="small"
                            alignment={
                              window.innerWidth >=
                              customBase.global.breakpoints.medium
                                ? 'left'
                                : 'center'
                            }
                          />
                          {!passwordHasCapital && (
                            <GreenWrapper>
                              <SAText
                                text="• Upper case letter"
                                type="small"
                                alignment="left"
                              />
                              <CheckWrapper>
                                <ClearAlert />
                              </CheckWrapper>
                            </GreenWrapper>
                          )}
                          {passwordHasCapital && (
                            <GreenWrapper>
                              <SAText
                                text="• Upper case letter"
                                type="small"
                                alignment="left"
                                colorVariant="secondary-background"
                              />
                              <CheckWrapper>
                                <GreenCheck />
                              </CheckWrapper>
                            </GreenWrapper>
                          )}
                          {!passwordHasLowercase && (
                            <GreenWrapper>
                              <SAText
                                text="• Lower case letter"
                                type="small"
                                alignment="left"
                              />
                              <CheckWrapper>
                                <ClearAlert />
                              </CheckWrapper>
                            </GreenWrapper>
                          )}
                          {passwordHasLowercase && (
                            <GreenWrapper>
                              <SAText
                                text="• Lower case letter"
                                type="small"
                                alignment="left"
                                colorVariant="secondary-background"
                              />
                              <CheckWrapper>
                                <GreenCheck />
                              </CheckWrapper>
                            </GreenWrapper>
                          )}
                          {!passwordHasNumber && (
                            <GreenWrapper>
                              <SAText
                                text="• Number"
                                type="small"
                                alignment="left"
                              />
                              <CheckWrapper>
                                <ClearAlert />
                              </CheckWrapper>
                            </GreenWrapper>
                          )}
                          {passwordHasNumber && (
                            <GreenWrapper>
                              <SAText
                                text="• Number"
                                type="small"
                                alignment="left"
                                colorVariant="secondary-background"
                              />
                              <CheckWrapper>
                                <GreenCheck />
                              </CheckWrapper>
                            </GreenWrapper>
                          )}
                          {!passwordHasSpecialChar && (
                            <GreenWrapper>
                              <SAText
                                text="• Special character"
                                type="small"
                                alignment="left"
                              />
                              <CheckWrapper>
                                <ClearAlert />
                              </CheckWrapper>
                            </GreenWrapper>
                          )}
                          {passwordHasSpecialChar && (
                            <GreenWrapper>
                              <SAText
                                text="• Special character"
                                type="small"
                                alignment="left"
                                colorVariant="secondary-background"
                              />
                              <CheckWrapper>
                                <GreenCheck />
                              </CheckWrapper>
                            </GreenWrapper>
                          )}
                        </TextHolder>
                      </InputWrapper>
                    </EditingRowValueWrapper>
                  </ContentRowSpacer>
                </SAStack>
              </SAColumns>
            </>
          )}
        </EditPasswordContentRow>
      ) : (
        <PasswordContentRow>
          <HeaderWrapper>
            <SAText
              text="Password"
              type="paragraph"
              colorVariant="info-foreground"
              weight="bold"
            />
          </HeaderWrapper>
          <PasswordRowValueWrapper>
            <SAText text="************" type="heading-3" weight="bold" />
          </PasswordRowValueWrapper>
        </PasswordContentRow>
      )}
    </>
  );
};

export default ProfilePagePasswordRow;

import styled from 'styled-components';
import customBase from '../../../util/global.theme';

const breakpoint = customBase.global.breakpoints.medium;
const leftSectionPercDes = 40;
const leftSectionPercMob = 50;

export const ModalBody = styled.div`
  border-radius: 5px;
  background-color: #ffffff;
  display: flex;
  width: 100%;

  @media (max-width: ${breakpoint}px) {
    height: 100%;
    flex-direction: column;
  }
`;

export const ModalBodyLeft = styled.div`
  @media (min-width: ${breakpoint + 1}px) {
    position: relative;
    width: ${leftSectionPercDes}%;
    overflow-y: visible;
    overflow-x: clip;
  }

  @media (max-width: ${breakpoint}px) {
    width: 100%;
    height: ${leftSectionPercMob}%;
  }
`;

export const ModalBodyRight = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoint + 1}px) {
    width: ${100 - leftSectionPercDes}%;
    padding: 30px;
  }

  @media (max-width: ${breakpoint}px) {
    width: 100%;
    height: ${100 - leftSectionPercMob}%;
    padding: 24px;
    align-content: space-between;
    justify-content: space-between;
  }
`;

export const ModalDesktopImage = styled.img`
  display: block;
  width: auto;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: -4%;

  @media (max-width: ${breakpoint}px) {
    display: none;
  }
`;

export const ModalMobileImage = styled.img`
  display: block;
  width: auto;
  height: 100%;

  @media (min-width: ${breakpoint + 1}px) {
    display: none;
  }
`;

export const ModalTextWrap = styled.div<{ displayAppModal: boolean }>`
  display: flex;
  flex-direction: column;
  align-content: center;

  @media (min-width: ${breakpoint + 1}px) {
    padding: ${({ displayAppModal }) =>
      displayAppModal ? '95px 0 75px' : '150px 0 100px'};
  }
`;

export const ModalTitle = styled.p`
  margin-bottom: 4px;
  color: #619424;
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
`;

export const ModalSubTitle = styled.p`
  font-weight: 700;
  font-size: 19px;
  line-height: 23px;
  color: #3b3b3b;
  margin-bottom: 16px;
`;

export const ModalDescription = styled.p`
  font-size: 16px;
  line-height: 19px;
  color: #666666;
`;

export const ModalButtonWrap = styled.div<{ displayAppModal: boolean }>`
  display: flex;
  ${({ displayAppModal }) => (displayAppModal ? 'padding-bottom: 75px;' : '')}

  @media (min-width: ${breakpoint + 1}px) {
    justify-content: ${({ displayAppModal }) =>
      displayAppModal ? 'flex-start' : 'flex-end'};
    align-items: center;
  }

  @media (max-width: ${breakpoint}px) {
    justify-content: space-between;
  }
`;

export const LaterButton = styled.button`
  cursor: pointer;
  padding: 8px;
  font-weight: 700;
  font-size: 19px;
  line-height: 23px;
  color: #3b3b3b;
  align-self: center;
  border: none;
  background: none;

  @media (min-width: ${breakpoint + 1}px) {
    margin-right: 40px;
  }
`;

export const SwitchNowButton = styled.button`
  cursor: pointer;
  padding: 15px 30px;
  background: #65a100;
  border-radius: 4px;
  font-weight: 700;
  font-size: 19px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
  border: none;
`;

export const ModalActionsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 0 25px;
`;

export const ModalAction = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 19px;
  line-height: 30px;
  color: #0174c6;
  margin: 0 30px;
`;

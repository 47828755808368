const formatInitials: Function = (displayName: string): string => {
  if (displayName.length > 0) {
    const names = displayName.split(' ');
    const firstInitial = displayName[0];

    if (names.length < 2) {
      return firstInitial;
    }

    const lastInitial = names[names.length - 1][0];
    const combinedInitials = firstInitial + lastInitial;

    return combinedInitials.toUpperCase();
  }

  return '';
};

export default formatInitials;

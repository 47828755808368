/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  Controller,
  FieldErrors,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import {
  SAButton,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAPagination,
  SAUX360Theme,
} from '@saux/design-system-react';
import Inputs, * as InputFields from '@material-ui/core/TextField/index';
import {
  Box,
  Collapse,
  Grid,
  InputAdornment,
  Typography,
  useFormControl,
} from '@material-ui/core';
import styled, { css } from 'styled-components';
import customBase from '../../util/global.theme';
import { SAInput } from '../../util/designSystem/SAInput';
import {
  Border,
  CovEmpWrapper,
  EmpFieldsBlock,
  LocationNumWrapper,
  LocationText,
  MakePaymentButtonWrapper,
  PayrollMainWrapper,
  PayrollMessage,
  PayrollWrapper,
  ResponsiveButtonWrapper,
  SubmitPayrollError,
  SummaryBlock,
} from './ReportPayrollStyles';
import {
  Jurisdiction,
  PolicyData,
  ResponseData,
  Location,
  CoveredEmployee,
} from './graphql/GET_PREMIUM_DETAILS';
import {
  PolicyLabel,
  PolicyLabelText,
  PolicyLeftBox,
  PolicyMainBox,
  PolicyRightBox,
  PolicyRightButton,
  PolicyValue,
  PolicyValueText,
  SummaryText,
} from '../priorReport/PriorReportsStyles';
import { Period } from '../priorReport/graphql/GET_PRIOR_AUDIT_REPORT_DETAILS';
import {
  PaginationWrapper,
  PayrollBlock,
  TextStyling,
  TextStylingD,
} from '../reportPayrollWrapper/RPWrapperStyles';
import { PaginationNavigation } from '../../util/paginationHelper';
import { SavePremiumAuditRequest } from './graphql/SAVE_PREMIUM_AUDIT_DETAILS';
import ReviewPayrollComponent from './ReviewPayroll';
import { ReactComponent as Spinner } from '../../assets/images/Spinner.svg';
import { ReactComponent as ChevronUp } from '../../assets/images/ChevronUp.svg';
import { ReactComponent as ChevronDownIcon } from '../../assets/images/ChevronDownIcon.svg';
import { formatCurrency, formatDatePayg } from '../../util';
import ReviewAuditDetails from './ReviewAuditDetails';

interface ReportPayrollProps {
  premiumDetails: ResponseData;
  effectiveStartDate: String;
  effectiveEndDate: String;
  setEffectiveStartDate: Function;
  setEffectiveEndDate: Function;
  // premiumDetails: PolicyData;
}

interface SaveAuditProps {
  premiumDetails: ResponseData;
  // inputRes: SavePremiumAuditRequest;
  errorState: boolean;
  onChildData: any;
  editbuttonHandler: () => void;
  bindEditHandler: () => void;
  accountNumber: string;
  status: string;
  modalType: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  hideSubmit: boolean;
  failureOnSave: boolean;
}

interface JurisdictionProps {
  premiumDetails: ResponseData;
  // premiumDetails: PolicyData;
  register: any;
  control: any;
  setValue: any;
  getValues: any;
}

interface FormValues {
  [key: string]: string;
}

interface PeriodProps {
  periods: Period;
}

interface LocationProps {
  locations: Location[];
  jurisdiction: any;
  index: number;
  collapse: boolean;
  inputs: {};
  register: any;
  control: any;
  setValue: any;
  getValues: any;
}

interface CoveredEmpInputsProps {
  EmpDetails: CoveredEmployee[];
  LocationNo: any;
  jurisdictionId: string;
  locationId: string;
  inputs: {};
  register: any;
  control: any;
  setValue: any;
  getValues: any;
}
interface MakePaymentButtonProps {
  setReviewPayroll: (value: any) => void;
  reviewPayroll: any;
  isLoading: boolean;
  isQuoteSaved: boolean;
}

interface SubmitEvent extends Event {
  submitter: HTMLElement;
}

// interface NestedObj {
//   [key: string]: any;
// }

// function removeTypename(obj: NestedObj): void {
//   const objNew = obj;
//   if (objNew instanceof Object) {
//     Object.keys(objNew).forEach((key) => {
//       if (key === '__typename') {
//         // delete obj[key];
//         delete (objNew as NestedObj)[key];
//       } else {
//         removeTypename(obj[key]);
//       }
//     });
//   }
// }

type Payload = Record<string, any>;
const toBeRemoved = [
  '__typename',
  'estimatedPayroll',
  'totalReportedPayRoll',
  'totalEstimatedPayRoll',
  'totalPremiumDue',
];
function removeTypename(obj: Payload): Payload {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(removeTypename) as Payload[];
  }

  return Object.entries(obj).reduce((acc, [key, value]) => {
    const red = acc;
    if (!toBeRemoved.includes(key)) {
      // if (key !== '__typename') {
      red[key] = removeTypename(value);
    }
    return red;
  }, {} as Payload);
}

const MakePaymentButton: React.FC<MakePaymentButtonProps> = ({
  setReviewPayroll,
  reviewPayroll,
  isLoading,
  isQuoteSaved,
}) => {
  // const submitForm = () => {
  //   const form = document.getElementById('my-form') as HTMLFormElement | null;
  //   form?.dispatchEvent(new Event('submit', { cancelable: true }));
  // };
  return (
    <ResponsiveButtonWrapper>
      <Border>
        <SAButton
          id="save"
          fullWidth
          fullWidthUnder={customBase.global.breakpoints.medium}
          test-attr="next-payment-button"
          label={isLoading ? 'SAVING' : 'SAVE'}
          variant="secondary-outline"
          type="submit"
          className="save"
          disabled={isLoading}
        // onClick={submitForm}
        />
      </Border>
      <Border>
        <SAButton
          id="review"
          fullWidth
          fullWidthUnder={customBase.global.breakpoints.medium}
          test-attr="next-payment-button"
          label="REVIEW PAYROLL"
          variant="primary"
          // onClick={() => setReviewPayroll(true)}
          type="submit"
          className="review"
        // disabled={!isQuoteSaved}
        />
      </Border>
    </ResponsiveButtonWrapper>
  );
};

const SummaryWrapper = ({
  premiumDetails,
  effectiveStartDate,
  effectiveEndDate,
  setEffectiveStartDate,
  setEffectiveEndDate,
}: ReportPayrollProps) => {
  setEffectiveStartDate(
    formatDatePayg(
      premiumDetails.getPremiumAuditTxnDetails.result.policyEffectiveDate,
    ),
  );
  setEffectiveEndDate(
    formatDatePayg(
      premiumDetails.getPremiumAuditTxnDetails.result.policyExpirationDate,
    ),
  );
  if (premiumDetails) {
    return (
      <>
        <SummaryBlock>
          <SummaryText>Summary</SummaryText>
        </SummaryBlock>
        <PolicyMainBox>
          <PolicyLeftBox>
            <PolicyLabel>
              <PolicyLabelText>Policy Number</PolicyLabelText>
              <PolicyLabelText>Effective Dates</PolicyLabelText>
            </PolicyLabel>
            <PolicyValue>
              <PolicyValueText>
                {premiumDetails?.getPremiumAuditTxnDetails.result.policyNumber}
              </PolicyValueText>
              <PolicyValueText>
                {effectiveStartDate} - {effectiveEndDate}
              </PolicyValueText>
            </PolicyValue>
          </PolicyLeftBox>
        </PolicyMainBox>
      </>
    );
  }
  return <></>;
};

const isValid = (event: any) => {
  if (event.target.value !== null) {
    return true;
  }
  return false;
};

const CoveredEmpInputs = ({
  EmpDetails,
  LocationNo,
  jurisdictionId,
  locationId,
  inputs,
  register,
  control,
  setValue,
  getValues,
}: CoveredEmpInputsProps) => {
  const [formattedValues, setFormattedValues] = useState('');
  const [displayedVal, SetDisplayedVal] = useState('');
  const [blurredField, setBlurredField] = useState({});
  // const handleInputChange = (fieldVal: string, fieldName: string) => {
  // const newValu = event.target.value;
  // if(/^[0-9]*$/.test(fieldVal)) {
  //   setValue(newValu);
  // }
  // setFormattedValues(newValu);
  // SetDisplayedVal(newValu);
  // };
  const handleBlur = (fieldVal: string, fieldName: string) => {
    const currentVal = getValues(fieldVal);
    const entVal = Number(parseFloat(currentVal[fieldName].replace(/[^0-9.-]+/g, "")));
    // Number(currentVal[fieldName]);
    if (Number.isNaN(entVal)) {
      setValue(fieldName, null);
      setBlurredField({ ...blurredField, [fieldName]: true })
    } else {
      const formattedVal = formatCurrency(Number(Math.floor(entVal)).toFixed(2));
      setValue(fieldName, formattedVal);
      setBlurredField({ ...blurredField, [fieldName]: false })
    }
  };

  const handleKeyDown = (event: any) => {
    const regex = /^[0-9\b]+$/;
    if (!regex.test(event.key) && event.key !== "Backspace" && event.key !== "ArrowLeft" && event.key !== "ArrowRight" && event.key !== "Tab") {
      event.preventDefault();
    }
  }

  const ExpandedLabel = styled(Typography)(({ theme }) => ({
    width: '250px',
    transition: 'width 0.1s ease',
    // fontSize: '0.75rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginLeft: '10px',
    '&:hover': {
      maxWidth: '100%',
      whiteSpace: 'normal',
    },
  }));

  return (
    <>
      <EmpFieldsBlock>
        <Grid container item spacing={4}>
          {/* <form onSubmit={}> */}
          {EmpDetails.map((emp, index) => {
            return (
              <CovEmpWrapper>
                <Controller
                  control={control}
                  name={`${emp.classCode}-${emp.description}`}
                  render={({
                    field,
                    fieldState: { error },
                    formState: { errors },
                  }) => (
                    <div>
                      <ExpandedLabel style={{ marginLeft: "5px", fontSize: "15px" }}>
                        {`${emp.classCode}-${emp.description}`}
                      </ExpandedLabel>
                      <Inputs
                        id={`${jurisdictionId}-${locationId}-${emp.publicID}`}
                        // label={`${emp.classCode}-${emp.description}`}
                        placeholder="--"
                        variant="outlined"
                        size="medium"
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        {...field}
                        {...register(
                          `${jurisdictionId}-${locationId}-${emp.publicID}`,
                          {
                            required: 'required',
                          },
                        )}
                        error={Boolean(
                          errors[
                          `${jurisdictionId}-${locationId}-${emp.publicID}`
                          ],
                        )}
                        defaultValue={
                          emp.auditedPayroll && emp.auditedPayroll !== null ? formatCurrency(emp.auditedPayroll) : '0.00'
                        }
                        onBlur={() =>
                          handleBlur(
                            field.value,
                            `${jurisdictionId}-${locationId}-${emp.publicID}`,
                          )
                        }
                        onKeyDown={handleKeyDown}
                      // onChange={()=> handleInputChange(field.value, `${jurisdictionId}-${locationId}-${emp.publicID}`)}
                      />
                      {
                        (blurredField[`${jurisdictionId}-${locationId}-${emp.publicID}` as keyof {}] || errors[
                          `${jurisdictionId}-${locationId}-${emp.publicID}`
                        ]) && (<div style={{ color: 'red', marginLeft: '10px', fontSize: '1rem', }}> This field is required.</div>)
                      }
                    </div>
                  )
                  }
                />
              </CovEmpWrapper>
            );
          })}
          {/* </form> */}
        </Grid>
      </EmpFieldsBlock >
    </>
  );
};

const LocationWrapper = ({
  locations,
  jurisdiction,
  index,
  collapse,
  inputs,
  register,
  control,
  setValue,
  getValues,
}: LocationProps) => {
  const show = collapse ? 'none' : 'block';
  const element = document.getElementById(`location-wrapper-${index}`);
  if (element) {
    element.setAttribute('style', `display: ${show}`);
  }

  return (
    <>
      <div id={`location-wrapper-${index}`}>
        {locations.map((loc) => {
          return (
            <>
              <LocationNumWrapper>
                <LocationText>
                  <b>Location {loc.locationNum}</b>: {loc.locationDisplayName}
                </LocationText>
              </LocationNumWrapper>
              <PayrollBlock>
                <TextStylingD>Payroll</TextStylingD>
              </PayrollBlock>
              <CoveredEmpInputs
                EmpDetails={loc.coveredEmployees}
                LocationNo={loc.locationNum}
                jurisdictionId={jurisdiction}
                locationId={loc.publicID}
                inputs={inputs}
                register={register}
                control={control}
                setValue={setValue}
                getValues={getValues}
              />
            </>
          );
        })}
      </div>
    </>
  );
};

const mock = {
  locationDisplayName: '16741 N GREASEWOO ST, SUN CITY, AZ 85374',
  locationNum: 2,
  publicID: 'pccl:2683542',
  coveredEmployees: [
    {
      classCode: '8742',
      description: 'Salespersonsss or Collectors--Outside',
      publicID: 'pccl:867418',
      __typename: 'CoveredEmployee',
    },
  ],
  __typename: 'Location',
};

// export const chevronDownIcon = () => (
//   <SAIcon
//     icon={SAIcons.chevronDown}
//     size={SAIconSize.medium}
//     data-on-click-role="openBreakdown"
//     className="expandAccordion"
//   />
// );

// export const chevronUpIcon = () => (
//   <SAIcon
//     icon={SAIcons.chevronUp}
//     size={SAIconSize.medium}
//     data-on-click-role="openBreakdown"
//     className="expandAccordion"
//   />
// );

const JurisdictionWrapper = ({
  premiumDetails,
  register,
  control,
  setValue,
  getValues,
}: JurisdictionProps) => {
  const currentReport =
    premiumDetails.getPremiumAuditTxnDetails.result.currentReport.jurisdictions;
  const reportLength = currentReport.length;
  const initialState = Array.from({ length: reportLength }, () => false);
  const [collapse, setCollapse] = useState(initialState);
  const [inputs, setInputs] = useState({});

  const handleToggle = (index: number) => {
    setCollapse((prev) => {
      const newCollapsed = [...prev];
      newCollapsed[index] = !newCollapsed[index];
      return newCollapsed;
    });
  };

  const contentStyle = { display: collapse ? 'none' : 'block' };

  // MOCK

  // currentReport[0].periods[0].locations.push(mock);
  // currentReport[0].periods[0].locations[0].coveredEmployees = [
  //   ...currentReport[0].periods[0].locations[0].coveredEmployees,
  //   ...mockCov,
  // ];
  const [selectedPageIndex, setSelectedPageIndex] = useState<number>(0);
  const [
    assetsTablePaginationNav,
    setAssetsTablePaginationNav,
  ] = useState<PaginationNavigation>(PaginationNavigation.FIRST);
  const totalAssets = currentReport.length;
  const totalPages = Math.ceil(totalAssets / 2);
  function assetsTablePageChange(newCurrentPageIndex: number) {
    if (newCurrentPageIndex === 0) {
      setAssetsTablePaginationNav(PaginationNavigation.FIRST);
    } else if (totalPages - 1 === newCurrentPageIndex) {
      setAssetsTablePaginationNav(PaginationNavigation.LAST);
    } else if (selectedPageIndex < newCurrentPageIndex) {
      setAssetsTablePaginationNav(PaginationNavigation.NEXT);
    } else if (selectedPageIndex > newCurrentPageIndex) {
      setAssetsTablePaginationNav(PaginationNavigation.PREVIOUS);
    }
    setSelectedPageIndex(newCurrentPageIndex);
  }
  const Span = styled.span`
    margin-left: 10px;
  `;
  const jurisdictions = currentReport.length;
  return (
    <>
      {currentReport.map((element, index) => {
        return (
          <>
            <SummaryBlock>
              <SummaryText>{element.jurisdictionName}</SummaryText>
              {/* <SAIcon
                id={index}
                onClick={() => handleToggle(index)}
                icon={
                  collapse[index] ? SAIcons.chevronDown : SAIcons.chevronUp
                }
                size={SAIconSize.medium}
                data-on-click-role="openBreakdown"
                className="expandAccordion"
              /> */}
              <Span
                role="button"
                tabIndex={0}
                onClick={() => handleToggle(index)}
              >
                {collapse[index] ? <ChevronUp /> : <ChevronDownIcon />}
              </Span>
            </SummaryBlock>
            {element.periods.map((loc) => {
              return (
                <LocationWrapper
                  locations={loc.locations}
                  jurisdiction={element.publicID}
                  index={index}
                  collapse={collapse[index]}
                  inputs={setInputs}
                  register={register}
                  control={control}
                  setValue={setValue}
                  getValues={getValues}
                />
              );
            })}
          </>
        );
      })}
      <PaginationWrapper>
        <SAPagination
          currentPageIndex={selectedPageIndex}
          totalItems={totalAssets}
          itemsPerPage={2}
          onChange={assetsTablePageChange}
        />
      </PaginationWrapper>
    </>
  );
};

const ReportPayroll = ({
  premiumDetails,
  errorState,
  onChildData,
  editbuttonHandler,
  bindEditHandler,
  accountNumber,
  modalType,
  isOpen,
  setIsOpen,
  status,
  hideSubmit,
  failureOnSave,
}: SaveAuditProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isQuoteSaved, setIsQuoteSaved] = useState<boolean>(false);
  const [onSaveData, setOnSaveData] = useState();
  const [isFormValid, setIsFormValid] = useState(true);
  const [errorFields, setErrorFields] = useState([]);
  const [reviewPayroll, setReviewPayroll] = useState(false);
  const [effectiveStartDate, setEffectiveStartDate] = useState<string>('');
  const [effectiveEndDate, setEffectiveEndDate] = useState<string>('');
  const [isReview, setIsReview] = useState<boolean>(false);

  const recordedResponse = premiumDetails;
  const form = useForm();

  const {
    register,
    handleSubmit,
    control,
    setError,
    setValue,
    getValues,
    formState: { errors },
  } = form;

  useEffect(() => {
    if (status && status === 'Quoted') {
      setReviewPayroll(true);
    }
  }, [status]);

  const BannerIconWrapper = styled.div`
    ${({ size }: { size: string }) => css`
      width: ${size};
      flex-basis: ${size};
      margin-right: 20px;
      display: flex;
      align-items: center;
    `}
  `;
  const handleSave = (data: any, event?: React.BaseSyntheticEvent) => {
    setIsLoading(false);
    const eventSubmitter = (event?.nativeEvent as SubmitEvent).submitter?.id;
    Object.keys(data).forEach((key, index) => {
      const temp = recordedResponse.getPremiumAuditTxnDetails.result.currentReport.jurisdictions
        .find((ele) => {
          return ele.publicID === key.split('-')[0];
        })
        ?.periods[0].locations.find((ele) => {
          return ele.publicID === key.split('-')[1];
        })
        ?.coveredEmployees.find((ele) => {
          return ele.publicID === key.split('-')[2];
        });
      if (temp) {
        temp.auditedPayroll = parseFloat(data[key].replace(/[^0-9.-]+/g, ""));
        // Number(data[key]);
      }
      return null;
    });

    const res = recordedResponse.getPremiumAuditTxnDetails.result;

    if (eventSubmitter === 'review') {
      setIsLoading(true);
      setReviewPayroll(true);
      setIsReview(true);
    }

    const cleanedPayload = removeTypename(res);
    const payload = {
      input: {
        param: [
          { ...cleanedPayload, isQuoteNeeded: eventSubmitter === 'review' },
        ],
        isSavePremium: true,
      },
    };

    onChildData(payload, isLoading, setIsLoading, setOnSaveData);
    const erroremp = Object.values(data).some((ans) => {
      return (Number(ans) <= 0 && ans !== undefined)
      // return !ans && !undefined;
    });
    if (erroremp) {
      setIsFormValid(false);
    }
    else {
      setIsFormValid(true);
    }
  };

  const onError = (error: FieldErrors) => {
    setIsFormValid(!error);
  };
  const iconSize = '24px';
  const iconBaseColor = SAUX360Theme.colors.red;
  return (
    <>
      {reviewPayroll && !failureOnSave ? (
        <ReviewPayrollComponent
          premiumDetails={premiumDetails}
          editbuttonHandler={editbuttonHandler}
          bindEditHandler={bindEditHandler}
          setReviewPayroll={setReviewPayroll}
          accountNumber={accountNumber}
          modalType={modalType}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onSaveResponse={onSaveData}
          isLoading={isLoading}
          setLoading={setIsLoading}
          hideSubmit={hideSubmit}
          effectiveStartDate={effectiveStartDate}
          effectiveEndDate={effectiveEndDate}
          isReview={isReview}
        />
      ) : (
        <PayrollMainWrapper>
          {!isFormValid &&
            <SubmitPayrollError>
              <BannerIconWrapper size="25px">
                <SAIcon
                  icon={SAIcons.error}
                  size="25px"
                  colorVariant={SAUX360Theme.colors.error}
                />
              </BannerIconWrapper>
              Required field is missing.
            </SubmitPayrollError>
          }
          {failureOnSave &&
            <SubmitPayrollError>
              <BannerIconWrapper size="25px">
                <SAIcon
                  icon={SAIcons.error}
                  size="25px"
                  colorVariant={SAUX360Theme.colors.error}
                />
              </BannerIconWrapper>
              Unable to save/review at this moment. Please try again later.
            </SubmitPayrollError>
          }
          <form onSubmit={handleSubmit(handleSave, onError)} id="my-form">
            <PayrollWrapper>
              <PayrollMessage>
                Enter your actual payroll(s) by class code for each location for
                this reporting period.
                <b>
                  Failure to report your payroll(s) by the due date will result
                  in a monthly premium invoice based on estimated payroll(s).
                </b>
              </PayrollMessage>
              <MakePaymentButtonWrapper className="makePaymentButtonWrapper">
                <MakePaymentButton
                  setReviewPayroll={setReviewPayroll}
                  reviewPayroll={reviewPayroll}
                  isLoading={isLoading}
                  isQuoteSaved={!!onSaveData}
                />
              </MakePaymentButtonWrapper>
              <SummaryWrapper
                premiumDetails={premiumDetails}
                effectiveStartDate={effectiveStartDate}
                setEffectiveStartDate={setEffectiveStartDate}
                effectiveEndDate={effectiveEndDate}
                setEffectiveEndDate={setEffectiveEndDate}
              />
              <JurisdictionWrapper
                premiumDetails={premiumDetails}
                register={register}
                control={control}
                setValue={setValue}
                getValues={getValues}
              />
            </PayrollWrapper>
          </form>
        </PayrollMainWrapper>
      )}
    </>
  );
};
export default ReportPayroll;

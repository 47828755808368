export default (
  obj: { month: string; day: string; year: string } | null,
): string | null => {
  let dateStr: string | null = null;
  if (obj) {
    const month = parseInt(obj.month.toString(), 10) + 1;
    const day = obj.day.toString();
    const monthNumber = parseInt(month.toString(), 10);
    const formattedMonth =
      monthNumber < 10 ? `0${monthNumber.toString()}` : monthNumber.toString();
    const dayNumber = parseInt(day.toString(), 10);
    const formattedday =
      dayNumber < 10 ? `0${dayNumber}` : dayNumber.toString();
    dateStr = `${formattedMonth}/${formattedday}/${obj.year}`;
  }
  return dateStr;
};

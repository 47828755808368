export default (
  date?: string | number | Date | null,
  dateOptions?: Intl.DateTimeFormatOptions,
) => {
  const formattedDate = date instanceof Date ? date : new Date(date as string);

  if (formattedDate.toString() === 'Invalid Date') {
    // We don't want to actually display "Invalid Date" in the UI.
    return date;
  }

  const d = formattedDate;

  return d.toLocaleDateString('en', dateOptions);
};

import React from 'react';
import { SAText } from '@saux/design-system-react';
import {
  ContentRow,
  EditingRowValueWrapper,
  HeaderWrapper,
  RowValueWrapper,
  TypeSelectWrapper,
} from './ProfilePageStyles';
import { PhoneNumber, ProfilePageState } from './ProfileState';
import { SAInput } from '../../util/designSystem/SAInput';
import SASelect from '../../util/designSystem/SASelect';
import { useFeatureFlag } from '../../util/hooks';

interface ProfilePageRowProps {
  id: string;
  state: ProfilePageState;
  inputHint?: string;
  header: string;
  editLabel?: string;
  value: string | PhoneNumber;
  valueFormatter?: Function;
  hasOptions?: boolean;
  onInputChange?: Function;
  onSelectChange?: Function;
  editing?: boolean;
}

interface RowProps {
  header: string;
  children?: JSX.Element;
}

interface InputFieldProps {
  id: string;
  state: ProfilePageState;
  inputHint?: string | undefined;
  header: string;
  label: string;
  value: any;
  onInputChange: Function;
  onSelectChange?: Function;
  hasOptions?: boolean;
}

export const validateEmail = (email: string): boolean =>
  /^(.)*@(\w)*.(.)*$/.test(email);

export const validatePhone = (phoneNumber: string): boolean =>
  /^[0-9]{10}$/.test(phoneNumber);

export const validatePrimaryPhoneType = (type: string): boolean => {
  let valid = true;

  if (type !== 'MOBILE') {
    valid = false;
  }
  return valid;
};

const Row = ({ header, children }: RowProps) => (
  <ContentRow>
    <HeaderWrapper>
      <SAText
        text={header}
        weight="bold"
        type="paragraph"
        colorVariant="info-foreground"
      />
    </HeaderWrapper>
    <RowValueWrapper>{children}</RowValueWrapper>
  </ContentRow>
);

const InputField = ({
  id,
  state,
  inputHint,
  value,
  label,
  onInputChange,
  hasOptions,
  onSelectChange,
  header,
}: InputFieldProps) => {
  const options = [
    { label: 'Mobile', value: 'MOBILE' },
    { label: 'Home', value: 'HOME' },
    { label: 'Work', value: 'WORK' },
  ];

  let displayValue: string;
  let defaultSelectValue: string = '';

  if (typeof value !== 'string') {
    displayValue = value.number;
    defaultSelectValue = value.type;
  } else {
    displayValue = value;
  }

  return (
    <EditingRowValueWrapper>
      {inputHint ? (
        <SAInput
          id={id}
          test-attr={`profile-page-${header}-edit`}
          hint={inputHint}
          label={label}
          type="text"
          fullWidth
          value={displayValue}
          onChange={(e: any) => onInputChange(e.target.value)}
        />
      ) : (
        <SAInput
          id={id}
          test-attr={`profile-page-${header}-edit`}
          label={label}
          type="text"
          fullWidth
          value={displayValue}
          onChange={(e: any) => onInputChange(e.target.value)}
        />
      )}
      {hasOptions && onSelectChange && (
        <TypeSelectWrapper>
          {!state.updatedPrimaryPhone.validType ? (
            <SASelect
              id={`profile-page-${header}-select`}
              test-attr={`profile-page-${header}-select`}
              hint="Primary phone must be mobile"
              fullWidth
              options={options}
              defaultValue={defaultSelectValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const validType = validatePrimaryPhoneType(
                  e.target.value.toUpperCase(),
                );
                onSelectChange({
                  type: e.target.value.toUpperCase(),
                  validType: validType,
                });
              }}
            />
          ) : (
            <SASelect
              id={`profile-page-${header}-select`}
              test-attr={`profile-page-${header}-select`}
              fullWidth={false}
              options={options}
              defaultValue={defaultSelectValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const validType = validatePrimaryPhoneType(
                  e.target.value.toUpperCase(),
                );
                onSelectChange({
                  type: e.target.value.toUpperCase(),
                  validType: validType,
                });
              }}
            />
          )}
        </TypeSelectWrapper>
      )}
    </EditingRowValueWrapper>
  );
};

const ProfilePageRow = ({
  id,
  state,
  editing,
  inputHint,
  header,
  editLabel,
  value,
  valueFormatter,
  hasOptions,
  onInputChange,
  onSelectChange,
}: ProfilePageRowProps) => {
  const { flagDetails } = useFeatureFlag('Profile Page Dropdown');
  if (editing && onInputChange) {
    return (
      <ContentRow>
        <Row header={header}>
          <InputField
            id={id}
            state={state}
            inputHint={inputHint}
            value={value}
            header={header}
            label={editLabel || ''}
            onSelectChange={onSelectChange}
            onInputChange={onInputChange}
            hasOptions={flagDetails?.enabled && hasOptions}
          />
        </Row>
      </ContentRow>
    );
  }

  if (typeof value === 'string') {
    return (
      <Row header={header}>
        <SAText
          text={valueFormatter ? valueFormatter(value) : value}
          type="paragraph"
        />
      </Row>
    );
  }

  return (
    <Row header={header}>
      <SAText
        text={valueFormatter ? valueFormatter(value.number) : value.number}
        type="paragraph"
      />
    </Row>
  );
};

export default ProfilePageRow;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import { SAIcons } from '@saux/design-system-react';
import { SAFormElementProps } from '@saux/design-system-react/input/variants';
import { SAInputContainer } from './SAInput';

type SASelectItem = {
  label: string;
  value: string;
};

interface SASelectProps extends SAFormElementProps {
  defaultValue?: string;
  options: SASelectItem[];
  fullWidth: boolean;
  /** Additional props will be passed select element */
  [x: string]: any;
}

const StyledInputContainer = styled(SAInputContainer)`
  select {
    appearance: none;
  }
`;

export default function SASelect({
  options,
  defaultValue,
  id,
  label,
  hint,
  className,
  fullWidth,
  ...additionalProps
}: SASelectProps) {
  return (
    <StyledInputContainer
      id={id}
      label={label}
      hint={hint}
      className={`saSelectWrapper ${className || ''}`}
      icon={SAIcons.chevronDownBold}
      fullWidth={fullWidth}
    >
      <select defaultValue={defaultValue} {...additionalProps}>
        {options.map((item) => {
          return (
            <option key={`${item.label}${item.value}`} label={item.label}>
              {item.value}
            </option>
          );
        })}
      </select>
    </StyledInputContainer>
  );
}

SASelect.defaultProps = {
  defaultValue: undefined,
};

import { SAText } from '@saux/design-system-react';
import React from 'react';
import formatInitials from '../../util/formatters/formatInitials';
import { InitialsHamburgerMenu, MenuInitialsIcon } from './MenuIconStyles';

interface MenuIconProps {
  displayName: string;
}

const MenuIcon = ({ displayName }: MenuIconProps) => {
  const initials = formatInitials(displayName);

  return (
    <div>
      <MenuInitialsIcon>
        <SAText
          text={initials}
          colorVariant="active-foreground"
          weight="bold"
          type="heading-2"
        />
      </MenuInitialsIcon>
      <InitialsHamburgerMenu>
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d)">
            <circle cx="13" cy="12" r="11" fill="#65A100" />
            <circle cx="13" cy="12" r="11" stroke="#65A100" />
          </g>
          <path d="M9 9.5H17" stroke="white" strokeLinecap="square" />
          <path d="M9 12.5H17" stroke="white" strokeLinecap="square" />
          <path d="M9 15.5H17" stroke="white" strokeLinecap="square" />
          <defs>
            <filter
              id="filter0_d"
              x="0"
              y="0"
              width="26"
              height="26"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feOffset dy="1" />
              <feGaussianBlur stdDeviation="1" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </InitialsHamburgerMenu>
    </div>
  );
};

export default MenuIcon;

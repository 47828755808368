import React, { useState } from 'react';
import {
  SAIcon,
  SAIcons,
  SAUX360Theme,
  SAButton,
} from '@saux/design-system-react';
import styled, { css } from 'styled-components';
import getConfig, { Env } from '../../aws_exports';
import { formatDate, formatLocalDate } from '../../util/formatters';
import { useFeatureFlag } from '../../util/hooks';

const awsExports = getConfig(process.env.REACT_APP_ENV as Env);

interface PAYGDisplayBannerProps {
  auditDueDate: string;
  auditStartDate: string;
  auditEndDate: string;
}
const BannerHeader = styled.div`
  font-size: 15px;
  font-weight: bold;
`;
const ReportingPeriodLabel = styled.span`
  font-weight: bold;
  font-size: 14px;
`;
const DueDateLabel = styled.div`
  font-size: 12px;
`;
const BannerMainContainer = styled.div`
  display: flex;
  background: #edf2e6;
  color: #264205;
  border: 1px solid #c9dab5;
  border-left: 4px solid hsl(87, 85%, 28%);
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 hsla(205, 99%, 28%, 0.2);
  align-items: center;
  padding: 15px;
`;
const BannerTextContainer = styled.div`
  display: block;
`;
const BannerIconWrapper = styled.div`
  ${({ size }: { size: string }) => css`
    width: ${size};
    flex-basis: ${size};
    margin-right: 20px;
    display: flex;
    align-items: center;
  `}
`;
const BannerButtonWrapper = styled.div`
  margin-left: auto;
  display: flex;
  .sa-button {
    background-color: hsl(90, 35%, 80%);
    color: #264205;
  }
`;
const iconSize = '24px';
const iconBaseColor = SAUX360Theme.colors.green700;

export const PAYGDisplayBanner = ({
  auditDueDate,
  auditStartDate,
  auditEndDate,
}: PAYGDisplayBannerProps) => {
  const { flagDetails: ReportPayrollInAMP } = useFeatureFlag(
    'ReportPayrollInAMP',
  );
  const reportClickHandler = () => {
    if (ReportPayrollInAMP && ReportPayrollInAMP?.enabled) {
      window.open(awsExports.amp_url, '_blank');
    } else window.open('/report-payroll', '_self');
  };
  return (
    <>
      <BannerMainContainer>
        <BannerIconWrapper size={iconSize}>
          <SAIcon
            icon={SAIcons.success}
            size={iconSize}
            colorVariant={iconBaseColor}
          />
        </BannerIconWrapper>
        <BannerTextContainer>
          <BannerHeader>
            Workers&apos; Compensation payroll reporting period is now open.
          </BannerHeader>
          <ReportingPeriodLabel>
            Current Reporting Period:&nbsp;{auditStartDate}
            &nbsp;-&nbsp;{auditEndDate}
          </ReportingPeriodLabel>
          <DueDateLabel>
            Payroll reporting is due by&nbsp;
            {formatLocalDate(auditDueDate, {
              year: 'numeric',
              day: '2-digit',
              month: 'long',
            })}
          </DueDateLabel>
        </BannerTextContainer>
        <BannerButtonWrapper>
          <SAButton
            variant="link-medium"
            label="payroll-report"
            onClick={reportClickHandler}
          >
            REPORT PAYROLL
          </SAButton>
        </BannerButtonWrapper>
      </BannerMainContainer>
    </>
  );
};

PAYGDisplayBanner.defaultPros = {
  auditDueDate: undefined,
  auditStartDate: undefined,
  auditEndDate: undefined,
};

export default PAYGDisplayBanner;

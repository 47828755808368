import React, { Children, cloneElement, useEffect, useState } from 'react';

import {
  MultiItemModalWrapper,
  MultiItemContainer,
  ItemIndicatorWrap,
  ItemIndicator,
  SlideReel,
  SlideContainer,
} from './MultiItemModalStyles';

interface Props {
  customStyle?: string;
  indicatorClick?: boolean;
  children:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>[]
    | React.ReactPortal
    | React.ReactPortal[];
}

const MultiItemModal = ({
  customStyle = '',
  indicatorClick,
  children,
}: Props) => {
  if (Children.count(children) === 0) {
    return <></>;
  }

  if (Children.count(children) === 1) {
    return (
      <MultiItemModalWrapper
        onClickHandler={(e: React.MouseEvent) => e.stopPropagation()}
      >
        <MultiItemContainer customStyle={customStyle}>
          {children}
        </MultiItemContainer>
      </MultiItemModalWrapper>
    );
  }

  const [activeSlide, setActiveSlide] = useState<{
    index: number;
    prevIndex: number;
    height: string;
    classSwitch: boolean;
  }>({
    index: 0,
    prevIndex: 0,
    height: 'auto',
    classSwitch: false,
  });

  const getSlideHeight = (slideIndex: number) => {
    const slides = document.querySelectorAll('.slideReel .slideContainer');
    let slideHeight = 'auto';

    if (slides && slides[slideIndex]) {
      slideHeight = `${slides[slideIndex].clientHeight}px`;
    }

    return slideHeight;
  };

  const updateSlideHeight = (index: number) => {
    setActiveSlide({
      ...activeSlide,
      height: getSlideHeight(index),
      classSwitch: !activeSlide.classSwitch,
    });
  };

  useEffect(() => {
    updateSlideHeight(0);
  }, []);

  const handleSlideChange = (newIndex: number) => {
    if (activeSlide.index !== newIndex) {
      setActiveSlide({
        index: newIndex,
        prevIndex: activeSlide.index,
        height: getSlideHeight(newIndex),
        classSwitch: !activeSlide.classSwitch,
      });
    }
  };

  return (
    <MultiItemModalWrapper
      onClickHandler={(e: React.MouseEvent) => e.stopPropagation()}
    >
      <MultiItemContainer customStyle={customStyle}>
        <SlideReel
          prevIndex={activeSlide.prevIndex}
          activeIndex={activeSlide.index}
          count={Children.count(children)}
          slideHeight={activeSlide.height}
          className={`slideReel ${
            activeSlide.classSwitch ? 'reelAnimate-1' : 'reelAnimate-2'
          }`}
        >
          {Children.map(children, (child, index) => (
            <SlideContainer
              key={`${child?.toString} ${index + 1}`}
              active={index === activeSlide.index}
              count={Children.count(children)}
              className={`slideContainer ${
                index === activeSlide.index ? 'active' : 'inactive'
              }`}
            >
              {cloneElement(child, {
                prevSlide: () => handleSlideChange(index - 1),
                updateSlideHeight: () => updateSlideHeight(index),
                nextSlide: () => handleSlideChange(index + 1),
              })}
            </SlideContainer>
          ))}
        </SlideReel>
        <ItemIndicatorWrap count={Children.count(children)}>
          {Children.map(children, (child, index) => (
            <ItemIndicator
              key={`${child?.toString} ${index + 1}`}
              onClick={() => (indicatorClick ? handleSlideChange(index) : null)}
              active={index === activeSlide.index}
              indicatorClick={indicatorClick}
            />
          ))}
        </ItemIndicatorWrap>
      </MultiItemContainer>
    </MultiItemModalWrapper>
  );
};

MultiItemModal.defaultProps = {
  customStyle: '',
  indicatorClick: false,
};

export default MultiItemModal;

import React, { useState, useEffect } from 'react';
import { SAIcon, SAIcons, SAIconSize } from '@saux/design-system-react';
import {
  ModalBackdrop,
  ModalButton,
  ModalContent,
  DynamicIcon,
  CloseText,
  ConfirmButton,
  DynamicText,
  ButtonWrapper,
  CloseButton,
  ConfirmText,
  ModalContainer,
  AboveContainer,
  TextContainer,
  TextWrapper,
  TextStyle,
  InfoText,
} from './PayrollAlertStyles';

interface PayrollAlertProps {
  isOpen: Boolean;
  modalType: string;
  effectiveDate: string;
  accountNumber: string;
  toggleModalHandler: Function;
}

const PayrollAlert = ({
  isOpen,
  accountNumber,
  effectiveDate,
  modalType,
  toggleModalHandler,
}: PayrollAlertProps) => {
  const [infoText, setInfoText] = useState<string>('');
  const [headerText, setHeaderText] = useState<string>('');

  useEffect(() => {
    if (modalType === 'info') {
      setHeaderText('Save and exit the report.');
      setInfoText(
        'Your payroll has not yet been submitted.  Do you want to exit?',
      );
    }
    if (modalType === 'success') {
      setHeaderText('The report has been submitted successfully!');
      setInfoText(
        'You have successfully completed your payroll report. If you are scheduled for automatic payments, this will be processed in the next business day.  If you are not scheduled for automatic payments, please return to the Home screen to manually complete your payment.',
      );
    }
    if (modalType === 'error') {
      setHeaderText('An error occured.');
      setInfoText(
        'Please try again later or contact 833-SAHelps (833.724.3577) for assistance.',
      );
    }
  }, [modalType]);

  const Icon = () => {
    if (modalType === 'info') {
      return (
        <SAIcon
          icon={SAIcons.information}
          size={SAIconSize.large}
          colorVariant="#017cC6"
        />
      );
    }
    if (modalType === 'success') {
      return (
        <SAIcon
          icon={SAIcons.success}
          size={SAIconSize.large}
          colorVariant="#4d840b"
        />
      );
    }
    return (
      <SAIcon
        icon={SAIcons.error}
        size={SAIconSize.large}
        colorVariant="#b00020"
      />
    );
  };

  return (
    <>
      {isOpen && (
        <ModalBackdrop>
          <ModalContent>
            <ModalContainer>
              <AboveContainer>
                <DynamicIcon>{Icon()}</DynamicIcon>
                <DynamicText>{headerText}</DynamicText>
                <TextContainer>
                  <TextWrapper>
                    <TextStyle>Effective Date : {effectiveDate}</TextStyle>
                  </TextWrapper>
                  <TextWrapper>
                    <TextStyle>Account Number : {accountNumber}</TextStyle>
                  </TextWrapper>
                </TextContainer>
                <InfoText>{infoText}</InfoText>
              </AboveContainer>
              <ButtonWrapper>
                <CloseButton>
                  <CloseText onClick={() => toggleModalHandler()}>
                    Close
                  </CloseText>
                </CloseButton>
                <ConfirmButton>
                  <ConfirmText onClick={() => window.open('/report-payroll?payg=true', '_self')}>
                    CONFIRM
                  </ConfirmText>
                </ConfirmButton>
              </ButtonWrapper>
            </ModalContainer>
          </ModalContent>
        </ModalBackdrop>
      )}
    </>
  );
};

export default PayrollAlert;

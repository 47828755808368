/* eslint-disable */
import { navigate } from '@reach/router';
import {
  SABox,
  SACard,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAStep,
  SAStepper,
  SAText,
  SAUX360Theme,
} from '@saux/design-system-react';
import React, { useState } from 'react';
import { ReactComponent as AgentIcon } from '../../assets/images/AgentIcon.svg';
import { ReactComponent as ClaimsIcon } from '../../assets/images/ClaimsIcon.svg';
import { ReactComponent as SmallMailIcon } from '../../assets/images/SmallMailIcon.svg';
import { ReactComponent as SmallPhoneIcon } from '../../assets/images/SmallPhoneIcon.svg';
import { formatPhone, formatTitleCase } from '../../util';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import {
  AgencyNameHolder,
  BlueContactCards,
  ClaimsNameHolder,
  ContactCollection,
  CustomerServiceNameHolder,
  CustomerServicePhoneHolder,
  EmailHolder,
  GreenContactCards,
  Heading,
  HeadingInnerText,
  IconSpacer,
  InnerCardContent,
  InnerPage,
  LoadingErrHolder,
  MailAdjust,
  Page,
  PhoneHolder,
  PhoneLink,
  RootRouteLink,
  StepperHolder,
} from './ContactPageStyles';

interface ContactPageProps {
  agencyInfo: AgencyInfo | undefined;
  loading: boolean;
  error: Error | undefined;
}

export interface AgencyInfo {
  name: string | null | undefined;
  phoneNumber: string | null | undefined;
  email: string | null | undefined;
}

export const ContactPageContent: Function = () => {
  return <SACard title="Account Details" variant="standard" />;
};

const ContactPage = ({ agencyInfo, loading, error }: ContactPageProps) => {
  const [activeStep, setActiveStep] = useState<number>(0);

  const steps: string[] = ['Dashboard', 'Contact Us'];
  const connector = (
    <SAIcon
      icon={SAIcons.chevronRightBold}
      size={SAIconSize.small}
      colorVariant={SAUX360Theme.color.dark.background}
    />
  );
  const formatEmail: Function = () => {
    if (agencyInfo?.email && agencyInfo?.email?.length > 29) {
      return `...${agencyInfo?.email?.substring(
        agencyInfo.email.length - 26,
        agencyInfo.email.length,
      )}`;
    }
    return agencyInfo?.email;
  };

  const formatName: Function = () => {
    if (agencyInfo?.name) {
      if (agencyInfo?.name?.length > 27) {
        return `${formatTitleCase(agencyInfo?.name?.substring(0, 27))}...`;
      }
      return formatTitleCase(agencyInfo?.name);
    }
  };

  const formattedEmail: string = formatEmail();
  const formattedName: string = formatName();

  function handleClick(e: any) {
    if (e.target.innerText === 'Dashboard') {
      setActiveStep(0);
      navigate('/overview');
    }
  }

  return (
    <Page>
      <StepperHolder>
        <SAStepper activeStep={activeStep} connector={connector} hideNumbers>
          {steps.map((label, index) => {
            if (index === 0) {
              return (
                <SAStep key={label} index={index}>
                  <RootRouteLink
                    onMouseOver={(e) => {
                      const target = e.target as HTMLTextAreaElement;
                      target.style.cursor = 'pointer';
                    }}
                    onClick={(e) => handleClick(e)}
                  >
                    <SAText text={label} type="paragraph" weight="bold" />
                  </RootRouteLink>
                </SAStep>
              );
            }
            return (
              <SAStep key={label} index={index}>
                <SAText text={label} type="paragraph" />
              </SAStep>
            );
          })}
        </SAStepper>
      </StepperHolder>
      <InnerPage>
        <Heading>
          <SAText type="heading-1" text="Contact Us" alignment="center" />
          <HeadingInnerText>
            <SABox>Get in touch and let us know how we can help</SABox>
          </HeadingInnerText>
        </Heading>
        <ContactCollection>
          <BlueContactCards>
            <InnerCardContent>
              <AgentIcon />
              {loading ? (
                <LoadingErrHolder>
                  <SAText text="Loading..." type="paragraph" weight="normal" />
                </LoadingErrHolder>
              ) : error ? (
                <LoadingErrHolder>
                  <SAText text="Error" type="paragraph" weight="normal" />
                </LoadingErrHolder>
              ) : (
                <>
                  {agencyInfo?.email === '-' ? (
                    <>
                      <ClaimsNameHolder>
                        <SAText type="heading-3" text={`${formattedName}`} />
                      </ClaimsNameHolder>
                      <PhoneHolder>
                        <SmallPhoneIcon />
                        <IconSpacer />
                        <PhoneLink
                          onClick={() => {
                            simpleGTMDataLayer({
                              event: `ContactAgencyCall-click`,
                              event_action: 'Contact Agency Call Button Click',
                              event_category: 'Contact',
                              event_label: 'Contact Agency Call Button Click',
                            });
                            simpleGTMDataLayer({
                              event: `Snowplow-ContactAgencyCall-click`,
                              event_action:
                                'Snowplow Contact Agency Call Button Click',
                              event_category: 'Snowplow Contact',
                              event_label:
                                'Snowplow Contact Agency Call Button Click',
                            });
                          }}
                          href={`tel:${agencyInfo?.phoneNumber}`}
                        >
                          <SAText
                            text={`${formatPhone(agencyInfo?.phoneNumber)}`}
                            type="paragraph"
                            weight="normal"
                            colorVariant="link"
                          />
                        </PhoneLink>
                      </PhoneHolder>
                    </>
                  ) : (
                    <>
                      <AgencyNameHolder>
                        <SAText type="heading-3" text={`${formattedName}`} />
                      </AgencyNameHolder>
                      <PhoneHolder>
                        <SmallPhoneIcon />
                        <IconSpacer />
                        <PhoneLink
                          onClick={() => {
                            simpleGTMDataLayer({
                              event: `ContactAgencyCall-click`,
                              event_action: 'Contact Agency Call Button Click',
                              event_category: 'Contact',
                              event_label: 'Contact Agency Call Button Click',
                            });
                            simpleGTMDataLayer({
                              event: `Snowplow-ContactAgencyCall-click`,
                              event_action:
                                'Snowplow Contact Agency Call Button Click',
                              event_category: 'Snowplow Contact',
                              event_label:
                                'Snowplow Contact Agency Call Button Click',
                            });
                          }}
                          href={`tel:${agencyInfo?.phoneNumber}`}
                        >
                          <SAText
                            text={`${formatPhone(agencyInfo?.phoneNumber)}`}
                            type="paragraph"
                            weight="normal"
                            colorVariant="link"
                          />
                        </PhoneLink>
                      </PhoneHolder>
                      <EmailHolder>
                        <MailAdjust>
                          <SmallMailIcon />
                        </MailAdjust>
                        <IconSpacer />
                        <PhoneLink
                          onClick={() => {
                            simpleGTMDataLayer({
                              event: `ContactAgencyEmail-click`,
                              event_action: 'Contact Agency Email Button Click',
                              event_category: 'Contact',
                              event_label: 'Contact Agency Email Button Click',
                            });
                            simpleGTMDataLayer({
                              event: `Snowplow-ContactAgencyEmail-click`,
                              event_action:
                                'Snowplow Contact Agency Email Button Click',
                              event_category: 'Snowplow Contact',
                              event_label:
                                'Snowplow Contact Agency Email Button Click',
                            });
                          }}
                          href={`mailto:${agencyInfo?.email}`}
                        >
                          <SAText
                            text={`${formattedEmail}`}
                            type="paragraph"
                            weight="normal"
                            colorVariant="link"
                          />
                        </PhoneLink>
                      </EmailHolder>
                    </>
                  )}
                </>
              )}
            </InnerCardContent>
          </BlueContactCards>
          <GreenContactCards>
            <InnerCardContent>
              <ClaimsIcon />
              <ClaimsNameHolder>
                <SAText type="heading-3" text="Claims" />
              </ClaimsNameHolder>
              <PhoneHolder>
                <SmallPhoneIcon />
                <IconSpacer />
                <PhoneLink
                  onClick={() => {
                    simpleGTMDataLayer({
                      event: `ContactClaimsCall-click`,
                      event_action: 'Contact Claims Call Button Click',
                      event_category: 'Contact',
                      event_label: 'Contact Claims Call Button Click',
                    });
                    simpleGTMDataLayer({
                      event: `Snowplow-ContactClaimsCall-click`,
                      event_action: 'Snowplow Contact Claims Call Button Click',
                      event_category: 'Snowplow Contact',
                      event_label: 'Snowplow Contact Claims Call Button Click',
                    });
                  }}
                  href="tel:8777225246"
                >
                  <SAText
                    text="1 (877) 722-5246"
                    type="paragraph"
                    weight="normal"
                    colorVariant="link"
                  />
                </PhoneLink>
              </PhoneHolder>
            </InnerCardContent>
          </GreenContactCards>
          <GreenContactCards>
            <InnerCardContent>
              <AgentIcon />
              <CustomerServiceNameHolder>
                <SAText type="heading-3" text="Customer Service" />
              </CustomerServiceNameHolder>
              <SAText type="standard" text="Monday through Friday," />
              <SAText type="standard" text="8 a.m. to 7 p.m. ET" />
              <CustomerServicePhoneHolder>
                <SmallPhoneIcon />
                <IconSpacer />
                <PhoneLink
                  onClick={() => {
                    simpleGTMDataLayer({
                      event: `ContactCustomerServiceCall-click`,
                      event_action:
                        'Contact Customer Service Call Button Click',
                      event_category: 'Contact',
                      event_label: 'Contact Customer Service Call Button Click',
                    });
                    simpleGTMDataLayer({
                      event: `Snowplow-ContactCustomerServiceCall-click`,
                      event_action:
                        'Snowplow Contact Customer Service Call Button Click',
                      event_category: 'Snowplow Contact',
                      event_label:
                        'Snowplow Contact Customer Service Call Button Click',
                    });
                  }}
                  href="tel:18337243577"
                >
                  <SAText
                    text="1 (833) 724-3577"
                    type="paragraph"
                    weight="normal"
                    colorVariant="link"
                  />
                </PhoneLink>
              </CustomerServicePhoneHolder>
            </InnerCardContent>
          </GreenContactCards>
        </ContactCollection>
      </InnerPage>
    </Page>
  );
};

export default ContactPage;

import React, { useState, useEffect } from 'react';
import AWSAppSyncClient from 'aws-appsync';
import { FetchResult } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { ApolloQueryResult } from 'apollo-client';
import {
  SAIcon,
  SAIcons,
  SAIconSize,
  SAText,
  SAUX360Theme,
} from '@saux/design-system-react';
import styled, { css } from 'styled-components';
import {
  PayrollMainWrapper,
  PayrollMessage,
  PayrollWrapper,
} from './ReportPayrollStyles';
import ReportPayroll from './ReportPayroll';
import { ResponseData, ResponseVariable } from './graphql/GET_PREMIUM_DETAILS';
import { QUERY_VARIABLES, RESPONSE_VARIABLES } from './graphql/GET_JOB_NUMBER';
import {
  EDIT_PREMIUM_QUERY_VARIABLES,
  EDIT_PREMIUM_RESPONSE_VARIABLES,
  PolicyResponse,
} from './graphql/EDIT_PREMIUM_AUDIT';
import { BIND_AUDIT_QUERY_VARIABLES } from './graphql/BIND_AUDIT_QUERY';
import { SavePremiumAuditRequest } from './graphql/SAVE_PREMIUM_AUDIT_DETAILS';
import Spinning from './Spinner';
import { Wrapper } from './JurisdictionDetailsStyles';
import { Text } from '../priorReport/PriorReportsStyles';

const getReportPayrollDetails = loader('./graphql/Get_Premium_Details.graphql');
const savePremiumMutation = loader('./graphql/Save_Premium_Audit.graphql');
const getJobNumber = loader('./graphql/Get_Job_Number.graphql');
const editPremiumAuditQuery = loader('./graphql/Edit_Premium_Audit.graphql');
const bindAuditQuery = loader('./graphql/Bind_Audit_Query.graphql');

interface ReportPayrollDataContainerProps {
  awsAppSyncClient: AWSAppSyncClient<any>;
  accountNumber: string;
}

export const fetchPremiumDetails = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  jobNumber: String,
): Promise<ApolloQueryResult<ResponseData>> => {
  const variables: ResponseVariable = {
    job_number: jobNumber,
    getPremium: true,
  } as ResponseVariable;
  const queryResult: Promise<
    ApolloQueryResult<ResponseData>
  > = awsAppSyncClient.query({
    query: getReportPayrollDetails,
    variables,
  }) as Promise<ApolloQueryResult<ResponseData>>;
  return queryResult;
};

export const getJobNumberFromEmail = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  username: String,
): Promise<ApolloQueryResult<RESPONSE_VARIABLES>> => {
  const variables: QUERY_VARIABLES = {
    username: username,
    getJobNumber: true,
  } as QUERY_VARIABLES;
  const queryResult: Promise<
    ApolloQueryResult<RESPONSE_VARIABLES>
  > = awsAppSyncClient.query({
    query: getJobNumber,
    variables,
  }) as Promise<ApolloQueryResult<RESPONSE_VARIABLES>>;
  return queryResult;
};

export const editPremiumAudit = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  job_number: String,
): Promise<ApolloQueryResult<EDIT_PREMIUM_RESPONSE_VARIABLES>> => {
  const variables: EDIT_PREMIUM_QUERY_VARIABLES = {
    isEdit: true,
    job_number: job_number,
  } as EDIT_PREMIUM_QUERY_VARIABLES;
  const queryResult: Promise<
    ApolloQueryResult<EDIT_PREMIUM_RESPONSE_VARIABLES>
  > = awsAppSyncClient.query({
    query: editPremiumAuditQuery,
    variables,
  }) as Promise<ApolloQueryResult<EDIT_PREMIUM_RESPONSE_VARIABLES>>;
  return queryResult;
};

export const bindAuditClickQuery = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  job_number: String,
): Promise<ApolloQueryResult<EDIT_PREMIUM_RESPONSE_VARIABLES>> => {
  const variables: BIND_AUDIT_QUERY_VARIABLES = {
    isBindAudit: true,
    job_number: job_number,
  } as BIND_AUDIT_QUERY_VARIABLES;
  const queryResult: Promise<
    ApolloQueryResult<EDIT_PREMIUM_RESPONSE_VARIABLES>
  > = awsAppSyncClient.query({
    query: bindAuditQuery,
    variables,
  }) as Promise<ApolloQueryResult<EDIT_PREMIUM_RESPONSE_VARIABLES>>;
  return queryResult;
};

export default ({
  awsAppSyncClient,
  accountNumber,
}: ReportPayrollDataContainerProps) => {
  const [premiumDetails, setPremiumDetails] = useState<ResponseData>();
  const [loading, setLoader] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [jobNumber, setJobNumber] = useState<string>('');
  const [modalType, setModalType] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [hideSubmit, setHideSubmit] = useState<boolean>(false);
  const [completedPayroll, setCompletedPayroll] = useState<boolean>(false);
  const [failureOnSave ,setFailureOnSave] = useState<boolean>(false);
  const eidUserName = sessionStorage.getItem('EIDuserName_PC') ?? '';

  const BannerIconWrapper = styled.div`
    ${({ size }: { size: string }) => css`
      width: ${size};
      flex-basis: ${size};
      margin-right: 20px;
      display: flex;
      align-items: center;
    `}
  `;
  const BannerTextContainer = styled.div`
    display: flex;
  `;

  const editClickHandler = () => {
    try {
      editPremiumAudit(awsAppSyncClient, jobNumber)
        .then((result: ApolloQueryResult<any>) => {
          if (result && result.data && result.data.editPremiumAudit) {
            const report = result.data.editPremiumAudit.result.currentReport;
            setStatus(report.premiumAuditBaseDetails?.transactionStatus);
          }
        })
        .catch((err: Error) => {
          console.error('ERROR WHILE FETCHING API: ', err);
          setError(true);
        });
    } catch (err) {
      console.error('ERROR BINDING: ', err);
      setError(true);
    }
  };

  const bindAuditClickHandler = () => {
    try {
      bindAuditClickQuery(awsAppSyncClient, jobNumber)
        .then((result: ApolloQueryResult<any>) => {
          if (
            result &&
            result?.data &&
            result?.data?.bindAudit?.message &&
            result?.data?.bindAudit?.message ===
              'Request failed with status code 400'
          ) {
            setModalType('error');
            setIsOpen(true);
            setHideSubmit(true);
          }
          if (
            result &&
            result.data &&
            result.data.bindAudit &&
            result.data.bindAudit.result
          ) {
            const resp: PolicyResponse = result?.data.bindAudit.result;
            if (
              resp?.currentReport &&
              resp?.currentReport?.premiumAuditBaseDetails &&
              resp?.currentReport?.premiumAuditBaseDetails?.auditStatus &&
              resp?.currentReport?.premiumAuditBaseDetails?.auditStatus ===
                'Completed' ||
                resp?.currentReport?.premiumAuditBaseDetails?.transactionStatus === 'AuditComplete'
            ) {
              setModalType('success');
              setIsOpen(true);
              setHideSubmit(true);
            }
          }
        })
        .catch((err: Error) => {
          console.error('ERROR WHILE FETCHING API: ', err);
          setError(true);
          setModalType('error');
          setIsOpen(true);
        });
    } catch (err) {
      console.error('ERROR: ', err);
      setError(true);
    }
  };

  useEffect(() => {
    if (eidUserName !== '') {
      try {
        setLoader(true);
        getJobNumberFromEmail(awsAppSyncClient, eidUserName)
          .then((result: ApolloQueryResult<any>) => {
            if (
              result &&
              result.data &&
              result.data.getAccountPolicySummaries &&
              result.data.getAccountPolicySummaries.result
            ) {
              const finalResult = result.data.getAccountPolicySummaries.result;
              if (finalResult === 'job number not found') {
                setLoader(false);
                setCompletedPayroll(true);
              } else {
                setJobNumber(JSON.parse(finalResult).jobNumber);
                setLoader(false);
              }
            }
          })
          .catch((err: Error) => {
            console.error('ERROR WHILE FETCHING API: ', err);
            setError(true);
            setLoader(false);
          });
      } catch (err) {
        console.error('ERROR: ', err);
        setError(true);
        setLoader(false);
      }
    }
  }, [eidUserName]);

  useEffect(() => {
    if (jobNumber && jobNumber !== '') {
      try {
        setLoader(true);
        fetchPremiumDetails(awsAppSyncClient, jobNumber || '')
          .then((result: ApolloQueryResult<ResponseData>) => {
            if (result && result.data) {
              setPremiumDetails(result.data);
              setError(false);
              setLoader(false);
            }
            if (
              result &&
              result.data &&
              result.data.getPremiumAuditTxnDetails &&
              result.data.getPremiumAuditTxnDetails.result &&
              result.data.getPremiumAuditTxnDetails?.result?.currentReport
            ) {
              const report =
                result.data.getPremiumAuditTxnDetails?.result?.currentReport;
              setStatus(report.premiumAuditBaseDetails?.transactionStatus);
            }
            if (Object.keys(result).length === 0) {
              setCompletedPayroll(true);
            }
          })
          .catch((err: Error) => {
            console.error('ERROR WHILE FETCHING API: ', err);
            setError(true);
            setLoader(false);
          });
      } catch (err) {
        console.error('ERROR: ', err);
        setError(true);
        setLoader(false);
      }
    }
  }, [jobNumber]);

  const handleSaveAPI = (
    dataFromChild: typeof savePremiumMutation,
    isLoading: boolean,
    setIsLoading: Function,
    setOnSaveData: Function,
  ) => {
    setIsLoading(true);
    const mutationResult: Promise<FetchResult<any>> = awsAppSyncClient.mutate({
      mutation: savePremiumMutation,
      fetchPolicy: 'no-cache',
      variables: dataFromChild,
    }) as Promise<FetchResult<any>>;
    mutationResult
      .then((fetchResult: FetchResult<any>) => {
        if (fetchResult) {
          console.log('response--> : ', fetchResult.data.savePremiumAudit);
          setOnSaveData(fetchResult.data.savePremiumAudit);
          setFailureOnSave(false);
        }
        setIsLoading(false);
      })
      .catch((err: Error) => {
        console.error(err);
        setFailureOnSave(true);
        setIsLoading(false);
      });
  };

  return (
    <>
      {loading ? (
        <Spinning />
      ) : (
        <>
          {completedPayroll && (
            <>
              <Wrapper>
                <Text>
                  <SAIcon
                    icon={SAIcons.success}
                    size={SAIconSize.large}
                    colorVariant="#4d840b"
                  />
                  <div style={{ marginTop: '5vh' }}>
                    You have already completed reporting your
                  </div>
                  <div> payroll for the current period. </div>
                </Text>
              </Wrapper>
            </>
          )}
          {premiumDetails ? (
            <ReportPayroll
              premiumDetails={premiumDetails}
              errorState={error}
              onChildData={handleSaveAPI}
              editbuttonHandler={editClickHandler}
              bindEditHandler={bindAuditClickHandler}
              accountNumber={accountNumber}
              modalType={modalType}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              status={status}
              hideSubmit={hideSubmit}
              failureOnSave={failureOnSave}
            />
          ) : (
            error && (
              <PayrollMainWrapper>
                <PayrollWrapper>
                  <PayrollMessage>
                    <div>
                      <BannerTextContainer>
                        <BannerIconWrapper size="25px">
                          <SAIcon
                            icon={SAIcons.alert}
                            size="25px"
                            colorVariant={SAUX360Theme.colors.error}
                          />
                        </BannerIconWrapper>
                        <b>
                          Unfortunately no payroll data is available at this
                          moment. Please try again after sometime.
                        </b>
                      </BannerTextContainer>
                    </div>
                  </PayrollMessage>
                </PayrollWrapper>
              </PayrollMainWrapper>
            )
          )}
        </>
      )}
    </>
  );
};

import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import {
  PriorReportWrapper,
  PriorReportMainWrapper,
  HeaderContainer,
  HeaderText,
  SummaryContainer,
  PolicyMainBox,
  PolicyLeftBox,
  PolicyRightBox,
  PolicyLabel,
  PolicyValue,
  PolicyLabelText,
  SummaryText,
  PolicyValueText,
  PolicyRightButton,
  WarningContainer,
} from './PriorReportsStyles';
import {
  PriorReportDetails,
  Jurisdiction,
  Location,
} from './graphql/GET_PRIOR_AUDIT_REPORT_DETAILS';
import PolicyDropdown from './PolicyDropdown';
import PayrollAlert from '../payrollAlerts/PayrollAlert';
import JurisdictionDetails from './JurisdictionDetails';
import { formatCurrency } from '../../util/formatters';
import { ReactComponent as WarningIcon } from '../../assets/images/WarningIcon.svg';
import Spinning from '../ReportPayroll/Spinner';
import AuditDetails from './AuditDetails';

interface PriorReportPayrollProps {
  policyNumberArray: string[] | [];
  selectedPolicy: string;
  selectedDate: string;
  reviewDateArray: string[] | [];
  handlePolicyChange: Function;
  handleDateChange: Function;
  selectedPriorReport: PriorReportDetails | {};
  getEffectiveDate: Function;
  accountNumber: string;
}

const PriorReports = ({
  policyNumberArray,
  selectedPolicy,
  selectedDate,
  reviewDateArray,
  handlePolicyChange,
  handleDateChange,
  selectedPriorReport,
  getEffectiveDate,
  accountNumber,
}: PriorReportPayrollProps) => {
  const [isOpen, setIsOpen] = useState<Boolean>(false);
  const [message, setMessage] = useState<string | null | undefined>();
  const [premiumDue, setPremiumDue] = useState<Number | null>(null);
  const [reportedPayroll, setReportedPayroll] = useState<
    Number | null | undefined
  >(null);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (
      'premiumAuditBaseDetails' in selectedPriorReport &&
      selectedPriorReport.premiumAuditBaseDetails
    ) {
      setPremiumDue(
        selectedPriorReport.premiumAuditBaseDetails.totalPremiumDue,
      );
      setReportedPayroll(
        selectedPriorReport.premiumAuditBaseDetails.totalReportedPayRoll,
      );
      setMessage(selectedPriorReport.premiumAuditBaseDetails.message?.content);
    }
  }, [selectedPriorReport, setPremiumDue, setReportedPayroll]);

  return (
    <PriorReportMainWrapper>
      <PriorReportWrapper>
        <HeaderContainer>
          <HeaderText>Search for a previous report.</HeaderText>
        </HeaderContainer>

        {policyNumberArray && policyNumberArray.length > 0 && (
          <PolicyDropdown
            policyNumberArray={policyNumberArray}
            selectedPolicy={selectedPolicy}
            selectedDate={selectedDate}
            reviewDateArray={reviewDateArray}
            handlePolicyChange={handlePolicyChange}
            handleDateChange={handleDateChange}
          />
        )}
        <SummaryContainer>
          <SummaryText>Summary</SummaryText>
        </SummaryContainer>
        {message ? (
          <WarningContainer>
            <span style={{ marginLeft: '1vw', marginRight: '1vw' }}>
              <WarningIcon />
            </span>
            <span>{message}</span>
          </WarningContainer>
        ) : null}
        <PolicyMainBox>
          <PolicyLeftBox>
            <PolicyLabel>
              <PolicyLabelText>Policy Number</PolicyLabelText>
              <PolicyLabelText>Effective Date</PolicyLabelText>
              <PolicyLabelText>Total Reported Payroll</PolicyLabelText>
              <PolicyLabelText>Total Premium Due</PolicyLabelText>
            </PolicyLabel>
            <PolicyValue>
              <PolicyValueText>
                {selectedPolicy || 'Select a Policy '}
              </PolicyValueText>
              <PolicyValueText>
                {(Object.keys(selectedPriorReport).length > 0 &&
                  getEffectiveDate(selectedPriorReport)) ||
                  'N/A'}
              </PolicyValueText>
              <PolicyValueText>
                {reportedPayroll !== undefined &&
                reportedPayroll !== null &&
                formatCurrency(reportedPayroll) !== 'N/A'
                  ? formatCurrency(reportedPayroll)
                  : 'N/A'}
              </PolicyValueText>
              <PolicyValueText>
                {premiumDue !== undefined &&
                premiumDue !== null &&
                formatCurrency(premiumDue) !== 'N/A'
                  ? formatCurrency(premiumDue)
                  : 'N/A'}
              </PolicyValueText>
            </PolicyValue>
          </PolicyLeftBox>
          <PolicyRightBox>
            <PolicyRightButton onClick={window.print}>Print</PolicyRightButton>
          </PolicyRightBox>
          {isOpen && (
            <PayrollAlert
              accountNumber={accountNumber}
              effectiveDate="06/01/2020 - 05/31/2021"
              isOpen
              toggleModalHandler={toggleModal}
              modalType="success"
            />
          )}
        </PolicyMainBox>
        <AuditDetails selectedPriorReport={selectedPriorReport} />
      </PriorReportWrapper>
    </PriorReportMainWrapper>
  );
};

export default PriorReports;

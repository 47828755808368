import {
  SAButton,
  SACard,
  SAIcon,
  SAIcons,
  SAInput,
  SASnackbar,
  SAText,
} from '@saux/design-system-react';
import React, { useState } from 'react';
import { ReactComponent as PhoneConfirmationIcon } from '../../assets/images/PhoneConfirmationIcon.svg';
import { formatPhone } from '../../util';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import { validatePhone } from '../profilePage/ProfilePageRow';
import {
  Backdrop,
  BottomContentWrapper,
  DesktopModalWrapper,
  InputWrapper,
  Modal,
  ModalContent,
  PhoneWrapper,
  PreviousButtonWrapper,
  SubTitleWrapper,
  TitleWrapper,
  ToolTipHolder,
  UpperContentWrapper,
} from './PhoneConfirmationModal.styles';

interface PhoneConfirmationModalProps {
  error: boolean;
  phoneInfo: PhoneInfo;
  setPhoneInfo: Function;
  userFirstName: string;
  setUpdatePhone: Function;
  setUpdateConfirmedPhoneNumber: Function;
  confirmedPhoneNumber: boolean;
  displaySuccess: boolean;
}

export interface PhoneInfo {
  originalPhone: string;
  updatedPhone: string;
  validPhone: boolean;
  contactId: string;
}

interface PhoneInputFieldProps {
  phoneInfo: PhoneInfo;
  setPhoneInfo: Function;
}

type Stage = 1 | 2;

interface BottomContentProps {
  stage: Stage;
  phoneInfo: PhoneInfo;
  setStage: Function;
  setUpdatePhone: Function;
  setUpdateConfirmedPhoneNumber: Function;
}

interface UpperContentProps {
  stage: Stage;
  userFirstName: string;
  phoneInfo: PhoneInfo;
  setPhoneInfo: Function;
}

export const PhoneInputField = ({
  phoneInfo,
  setPhoneInfo,
}: PhoneInputFieldProps) => {
  return phoneInfo.validPhone ? (
    <SAInput
      test-attr="profile-page-primary-phone-input"
      id="profile-page-primary-phone-input"
      label="Primary Phone Number"
      type="tel"
      fullWidth
      value={phoneInfo.updatedPhone}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneInfo({
          ...phoneInfo,
          updatedPhone: e.target.value,
          validPhone: validatePhone(e.target.value),
        });
      }}
    />
  ) : (
    <SAInput
      id="profile-page-primary-phone-input"
      label="Primary Phone Number"
      hint="Invalid Phone Number"
      type="tel"
      value={phoneInfo.updatedPhone}
      fullWidth
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneInfo({
          ...phoneInfo,
          updatedPhone: e.target.value,
          validPhone: validatePhone(e.target.value),
        });
      }}
    />
  );
};

const BottomContent = ({
  stage,
  phoneInfo,
  setStage,
  setUpdatePhone,
  setUpdateConfirmedPhoneNumber,
}: BottomContentProps) => {
  return stage === 1 ? (
    <BottomContentWrapper>
      <SAButton
        disabled={!phoneInfo.validPhone}
        test-attr="phone-confirmation-next-button"
        label="NEXT"
        variant="primary"
        onClick={() => setStage(2)}
      />
    </BottomContentWrapper>
  ) : (
    <BottomContentWrapper>
      <PreviousButtonWrapper>
        <SAButton
          test-attr="phone-confirmation-previous-button"
          label="PREVIOUS"
          variant="primary-link"
          onClick={() => setStage(1)}
        />
      </PreviousButtonWrapper>
      <SAButton
        test-attr="confirm-phone-number-button"
        label="CONFIRM"
        variant="primary"
        onClick={() => {
          if (phoneInfo.originalPhone !== phoneInfo.updatedPhone) {
            setUpdatePhone(true);
          } else {
            setUpdateConfirmedPhoneNumber(true);
          }

          simpleGTMDataLayer({
            event: 'Container-ConfirmPhoneModalConfirm-click',
            event_action: 'Container Confirm Phone Modal Confirm click',
            event_category: 'Container Confirm Phone Modal Confirm',
            event_label: 'Container Confirm Phone Modal Confirm',
          });
        }}
      />
    </BottomContentWrapper>
  );
};

const UpperContent = ({
  stage,
  userFirstName,
  phoneInfo,
  setPhoneInfo,
}: UpperContentProps) => {
  const formattedPhone = formatPhone(phoneInfo.updatedPhone) || '-';

  return (
    <UpperContentWrapper>
      <PhoneConfirmationIcon />
      <TitleWrapper>
        <SAText
          type="heading-1"
          colorVariant="secondary-background"
          text={`Welcome ${userFirstName}!`}
        />
      </TitleWrapper>
      <SubTitleWrapper>
        <SAText
          type="paragraph"
          colorVariant="default"
          text="Let's verify your primary phone number."
        />
      </SubTitleWrapper>
      {stage === 1 ? (
        <InputWrapper>
          <PhoneInputField phoneInfo={phoneInfo} setPhoneInfo={setPhoneInfo} />
        </InputWrapper>
      ) : (
        <PhoneWrapper>
          <SAText type="heading-1" text={formattedPhone} />
        </PhoneWrapper>
      )}
    </UpperContentWrapper>
  );
};

const PhoneConfirmationModal = ({
  error,
  phoneInfo,
  setPhoneInfo,
  userFirstName,
  setUpdatePhone,
  setUpdateConfirmedPhoneNumber,
  confirmedPhoneNumber,
  displaySuccess,
}: PhoneConfirmationModalProps) => {
  const [stage, setStage] = useState<Stage>(1);

  if (error) {
    document.body.style.overflow = 'auto';

    return (
      <ToolTipHolder>
        <SASnackbar
          open
          variant="snackbar-error"
          title="Something went wrong."
          subtitle="Please try again later."
          icon={SAIcons.error}
          closeable
          autoHideDuration={5000}
          position="top"
        />
      </ToolTipHolder>
    );
  }

  if (displaySuccess) {
    document.body.style.overflowY = 'auto';

    return (
      <ToolTipHolder>
        <SASnackbar
          open
          variant="snackbar-success"
          title="Your primary phone number has been verified"
          icon={SAIcons.checkmark}
          closeable
          autoHideDuration={5000}
          position="top"
        />
      </ToolTipHolder>
    );
  }

  if (!confirmedPhoneNumber) {
    document.body.style.overflowY = 'hidden';

    return (
      <Backdrop>
        <Modal
          onClick={(e: React.MouseEvent) => e.stopPropagation()}
          onTouchMove={(e: React.TouchEvent) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <DesktopModalWrapper>
            <SACard variant="simple">
              <ModalContent>
                <UpperContent
                  stage={stage}
                  userFirstName={userFirstName}
                  phoneInfo={phoneInfo}
                  setPhoneInfo={setPhoneInfo}
                />
                <BottomContent
                  stage={stage}
                  phoneInfo={phoneInfo}
                  setStage={setStage}
                  setUpdatePhone={setUpdatePhone}
                  setUpdateConfirmedPhoneNumber={setUpdateConfirmedPhoneNumber}
                />
              </ModalContent>
            </SACard>
          </DesktopModalWrapper>
        </Modal>
      </Backdrop>
    );
  }

  return <></>;
};

export default PhoneConfirmationModal;

import React, { useEffect, useState } from 'react';

import { loader } from 'graphql.macro';
import AWSAppSyncClient from 'aws-appsync';
import { ApolloQueryResult } from 'apollo-client';

import AgencyBilledModal, { AgencyInfo } from './AgencyBilledModal';

import {
  GET_AGENCY_INFO,
  GET_AGENCY_INFOVariables,
} from './graphql/Get_Agency_Contact_Info_Generated';
import AgencyBilledQuantity from '../../sharedTypes/AgencyBilledQuantity';

export const getAgencyInfoQuery = loader(
  './graphql/Get_Agency_Contact_Info.graphql',
);

interface AgencyBilledModalDataContainerProps {
  showModal: boolean;
  closeModal: Function;
  accountNumber: string | undefined;
  awsAppSyncClient: AWSAppSyncClient<any>;
  agencyBilledQuantity: AgencyBilledQuantity;
}

export const fetchAgencyInfo = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
): Promise<ApolloQueryResult<GET_AGENCY_INFO>> => {
  const variables: GET_AGENCY_INFOVariables = {
    account_number: accountNumber,
  } as GET_AGENCY_INFOVariables;

  const queryResult: Promise<
    ApolloQueryResult<GET_AGENCY_INFO>
  > = awsAppSyncClient.query({
    query: getAgencyInfoQuery,
    variables,
  }) as Promise<ApolloQueryResult<GET_AGENCY_INFO>>;

  return queryResult;
};

const defaultAgencyInfo = {
  name: 'State Auto Insurance Services',
  phoneNumber: '8663647489',
  email: 'saservice@stateauto.com',
};

export const processAgencyInfo = (data: GET_AGENCY_INFO) => {
  let { name, phoneNumber, email } = defaultAgencyInfo;

  const agencyInfoAccountItems = data?.account?.items;

  if (
    agencyInfoAccountItems &&
    agencyInfoAccountItems[0] &&
    agencyInfoAccountItems[0].service_tier?.toLowerCase() !== 'platform' &&
    agencyInfoAccountItems[0].agencyContactInfo &&
    agencyInfoAccountItems[0].agencyContactInfo.agency_name &&
    agencyInfoAccountItems[0].agencyContactInfo.business_phone &&
    agencyInfoAccountItems[0].agencyContactInfo.business_email
  ) {
    name = agencyInfoAccountItems[0].agencyContactInfo.agency_name;
    phoneNumber = agencyInfoAccountItems[0].agencyContactInfo.business_phone;
    email = agencyInfoAccountItems[0].agencyContactInfo.business_email;
  }

  return {
    name,
    phoneNumber,
    email,
  };
};

export default ({
  showModal,
  closeModal,
  accountNumber,
  awsAppSyncClient,
  agencyBilledQuantity,
}: AgencyBilledModalDataContainerProps) => {
  const [agencyInfo, setAgencyInfo] = useState<AgencyInfo>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);

    try {
      fetchAgencyInfo(awsAppSyncClient, accountNumber || '')
        .then((result: ApolloQueryResult<GET_AGENCY_INFO>) => {
          const processedAgencyInfo = processAgencyInfo(result.data);
          setAgencyInfo(processedAgencyInfo);
        })
        .catch((err: Error) => {
          setAgencyInfo(defaultAgencyInfo);
          console.error('GET_AGENCY_INFO ERROR: ', err);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      setAgencyInfo(defaultAgencyInfo);
      console.error('GET_AGENCY_INFO ERROR: ', err);
    }
  }, [accountNumber]);

  return (
    <AgencyBilledModal
      showModal={showModal}
      closeModal={closeModal}
      agencyInfo={agencyInfo}
      loading={loading}
      agencyBilledQuantity={agencyBilledQuantity}
    />
  );
};

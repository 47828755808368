import React, { useState, useEffect } from 'react';
import { loader } from 'graphql.macro';
import AWSAppSyncClient from 'aws-appsync';
import { ApolloQueryResult } from 'apollo-client';
import {
  SAText,
  SAUXTheme,
  SAIcons,
  SAIcon,
  SAIconSize,
} from '@saux/design-system-react';
import styled from 'styled-components';
import {
  GET_USERNAV_INFO,
  GET_USERNAV_INFOVariables,
} from '../../generated/GET_USERNAV_INFO';

interface DisplayNameProps {
  accountNumber: any;
  client: AWSAppSyncClient<any>;
  displayName?: string;
}

const getDisplayNameByAccount = loader(
  '../../graphql/queries/Get_UserNav_Info.graphql',
);

const getMigratedStatus = loader(
  '../../graphql/queries/Has_Migrated_Policy.graphql',
);

const AccountNumHolder = styled.span`
  color: hsl(0, 0%, 40%);
  font-size: 16px;
`;

const AccountNumWordsHolder = styled.span`
  user-select: none;
  color: hsl(0, 0%, 40%);
  font-size: 16px;
  margin-left: 2px;
`;

const UserNameHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
      margin: 0px 0px ${theme?.size?.spacer?.xs} 0px;
  `}
`;

export const SafecoModal = styled.div`
  margin-bottom: 20px;
`;

export const LeftSafecoModalContainer = styled.div`
  display: inline;
  flex-direction: row;
  background-color: #ffffff;
`;

export const SafecoModalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid #b5e6f0;
  border-radius: 5px;
  background: #e2f5f9;
  border-left: 5px solid #189ecc;
  color: #005c86;
  align-items: center;
  padding: 15px 20px;
`;

export const SafecoLink = styled.span`
  color: #005c99;
  font-weight: bold;
  cursor: pointer;
`;

export const InfoIconSizing = styled.div`
  padding-right: 25px;
`;

export function fetchName(
  accountNumber: string,
  awsAppSyncClient: AWSAppSyncClient<any>,
): Promise<ApolloQueryResult<GET_USERNAV_INFO>> {
  const variables: GET_USERNAV_INFOVariables = {
    account_number: accountNumber,
  } as GET_USERNAV_INFOVariables;
  return awsAppSyncClient.query({
    query: getDisplayNameByAccount,
    variables,
  }) as Promise<ApolloQueryResult<GET_USERNAV_INFO>>;
}

export interface GetMigratedStatusProps {
  hasMigratedPolicy: {
    hasMigratedPolicy: boolean;
  };
}

export function getMigratedPolicyData(
  accountNumber: string,
  awsAppSyncClient: AWSAppSyncClient<any>,
): Promise<ApolloQueryResult<GetMigratedStatusProps>> {
  const variables: GET_USERNAV_INFOVariables = {
    account_number: accountNumber,
  } as GET_USERNAV_INFOVariables;
  return awsAppSyncClient.query({
    query: getMigratedStatus,
    variables,
  }) as Promise<ApolloQueryResult<GetMigratedStatusProps>>;
}

const safecoClickHandler = () => {
  window.open('https://now.agent.safeco.com/start', '_blank');
};

const safecoLink = () => {
  return (
    <SafecoLink onClick={() => safecoClickHandler()}>
      update the Safeco
    </SafecoLink>
  );
};

export const DisplayName = ({
  accountNumber,
  client,
  displayName,
}: DisplayNameProps) => {
  const [customerName, setCustomerName] = useState<string>('');
  const [accountNum, setAccountNum] = useState<string>('');
  const [isMigrated, setIsMigrated] = useState<boolean>(false);

  useEffect(() => {
    fetchName(accountNumber, client)
      .then((apolloQueryResult: ApolloQueryResult<GET_USERNAV_INFO>) => {
        if (
          apolloQueryResult &&
          apolloQueryResult.data &&
          apolloQueryResult.data.account &&
          apolloQueryResult.data.account.items &&
          apolloQueryResult.data.account.items[0] &&
          apolloQueryResult.data.account.items[0].display_name
        ) {
          setCustomerName(
            apolloQueryResult?.data?.account?.items[0]?.display_name?.toUpperCase(),
          );
          setAccountNum(
            apolloQueryResult?.data?.account?.items[0]?.account_number,
          );
        }
      })
      .catch((err: Error) => {
        console.error('GET_USERNAV_INFO ERROR: ', err);
      });
  }, [accountNumber]);

  useEffect(() => {
    getMigratedPolicyData(accountNumber, client)
      .then((apolloQueryResult: ApolloQueryResult<GetMigratedStatusProps>) => {
        if (
          apolloQueryResult.data &&
          apolloQueryResult.data?.hasMigratedPolicy &&
          (apolloQueryResult.data?.hasMigratedPolicy?.hasMigratedPolicy !==
            null ||
            apolloQueryResult.data?.hasMigratedPolicy?.hasMigratedPolicy !==
              undefined)
        ) {
          setIsMigrated(
            apolloQueryResult.data.hasMigratedPolicy.hasMigratedPolicy,
          );
        }
      })
      .catch((err: Error) => {
        console.error('GET POLICY MIGRATED STATUS ERROR: ', err);
      });
  }, [accountNumber]);

  return (
    <>
      {isMigrated && (
        <SafecoModal>
          <LeftSafecoModalContainer>
            <SafecoModalWrapper>
              <InfoIconSizing>
                <SAIcon
                  icon={SAIcons.information}
                  size={SAIconSize.medium}
                  colorVariant="#005c86"
                />
              </InfoIconSizing>
              <div>
                <div>Make sure to {safecoLink()} account too!</div>
                <div>
                  Any changes made to the billing or payment info here won’t
                  appear in The Safeco account.
                </div>
              </div>
            </SafecoModalWrapper>
          </LeftSafecoModalContainer>
        </SafecoModal>
      )}
      {customerName ? (
        <UserNameHolder>
          <SAText type="heading-1" text={`${customerName}`} weight="normal" />
        </UserNameHolder>
      ) : (
        <UserNameHolder>
          <SAText type="heading-1" text={`${displayName}`} weight="normal" />
        </UserNameHolder>
      )}
      {accountNum && (
        <>
          <AccountNumWordsHolder>Account Number: </AccountNumWordsHolder>
          <AccountNumHolder className="account-number">
            {accountNum}
          </AccountNumHolder>
        </>
      )}
    </>
  );
};

export default DisplayName;

import React, { useEffect, useState } from 'react';
import { loader } from 'graphql.macro';
import AWSAppSyncClient from 'aws-appsync';
import { ApolloQueryResult } from 'apollo-client';
import { SAIcon, SAIcons, SAIconSize } from '@saux/design-system-react';
import {
  RESEND_EMAIL,
  RESEND_EMAIL_ACCOUNT_NUMBERVariables,
} from './graphql/RESEND_ESIGN_MUTATION';
import {
  GET_USERNAV_INFO,
  GET_USERNAV_INFOVariables,
} from '../../generated/GET_USERNAV_INFO';
import {
  MainWrapper,
  InnerWrapper,
  MainHeadingText,
  DetailText,
  TextWapper,
  ButtonWapper,
  ButtonText,
  ButtonTextWrapper,
  SentAlertWrapper,
  AlertStyle,
  LeftBorder,
  CloseButton,
} from './EsignAlert.styles';

interface EsignAlertProps {
  awsAppSyncClient: AWSAppSyncClient<any>;
  accountNumber: string | undefined;
}

const getEsignAlertModalData = loader('./graphql/Show_Esign_Alert.graphql');

const getDisplayNameByAccount = loader(
  '../../graphql/queries/Get_UserNav_Info.graphql',
);

const RESEND_ESIGN_MUTATION = loader('./graphql/Resend_Esign_Mutation.graphql');

interface ShowEsignAlertVariableProps {
  account_number: string;
}

interface ShowEsignAlertData {
  showAlert: boolean;
}

interface ShowEsignAlertProps {
  showEsignAlert: ShowEsignAlertData;
  __typename: 'showEsignAlert';
}

export function fetchName(
  accountNumber: string | undefined,
  awsAppSyncClient: AWSAppSyncClient<any>,
): Promise<ApolloQueryResult<GET_USERNAV_INFO>> {
  const variables: GET_USERNAV_INFOVariables = {
    account_number: accountNumber,
  } as GET_USERNAV_INFOVariables;
  return awsAppSyncClient.query({
    query: getDisplayNameByAccount,
    variables,
  }) as Promise<ApolloQueryResult<GET_USERNAV_INFO>>;
}

const EsignAlert = ({ awsAppSyncClient, accountNumber }: EsignAlertProps) => {
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [showSentAlert, setshowSentAlert] = useState<boolean>(false);
  const [customerEmail, setCustomerEmail] = useState<string>('');

  const getShowAlertData = (account: string | undefined) => {
    const variables: ShowEsignAlertVariableProps = {
      account_number: account,
    } as ShowEsignAlertVariableProps;
    return awsAppSyncClient.query({
      query: getEsignAlertModalData,
      variables,
    }) as Promise<ApolloQueryResult<ShowEsignAlertProps>>;
  };

  useEffect(() => {
    try {
      getShowAlertData(accountNumber).then(
        (apolloQueryResult: ApolloQueryResult<ShowEsignAlertProps>) => {
          if (
            apolloQueryResult &&
            apolloQueryResult?.data &&
            apolloQueryResult?.data?.showEsignAlert
          ) {
            const ESAQuery = apolloQueryResult?.data?.showEsignAlert;
            setshowAlert(ESAQuery.showAlert);
          }
        },
      );
    } catch (err) {
      console.error('GET_SHOW_ALERT_QUERY ERROR: ', err);
    }
  }, [accountNumber]);

  useEffect(() => {
    fetchName(accountNumber, awsAppSyncClient)
      .then((apolloQueryResult: ApolloQueryResult<GET_USERNAV_INFO>) => {
        if (
          apolloQueryResult &&
          apolloQueryResult.data &&
          apolloQueryResult.data.account &&
          apolloQueryResult.data.account.items &&
          apolloQueryResult.data.account.items[0] &&
          apolloQueryResult.data.account.items[0].email_address
        ) {
          setCustomerEmail(
            apolloQueryResult?.data?.account?.items[0]?.email_address,
          );
        }
      })
      .catch((err: Error) => {
        console.error('GET_USERNAV_INFO ERROR: ', err);
      });
  }, [accountNumber]);

  const mailReSentResponse = (sourceSystem: string) => {
    const variables: RESEND_EMAIL_ACCOUNT_NUMBERVariables = {
      account_number: accountNumber,
      sourceSystem: sourceSystem,
    } as RESEND_EMAIL_ACCOUNT_NUMBERVariables;

    return awsAppSyncClient.mutate({
      mutation: RESEND_ESIGN_MUTATION,
      variables,
    }) as Promise<ApolloQueryResult<RESEND_EMAIL>>;
  };

  const resendEmailHandler = () => {
    mailReSentResponse('c360').then(
      (apolloQueryResult: ApolloQueryResult<RESEND_EMAIL>) => {
        const data =
          apolloQueryResult &&
          apolloQueryResult.data &&
          apolloQueryResult.data.sendEsign;
        if (data && data?.success) {
          setshowAlert(false);
          setshowSentAlert(true);
        } else if (data && !data?.success) {
          setshowAlert(false);
        }
      },
    );
  };

  return (
    <>
      {showAlert && (
        <MainWrapper>
          <InnerWrapper>
            <TextWapper>
              <MainHeadingText>E-signature not Completed</MainHeadingText>
              <DetailText>
                Action is needed to keep one or more policies on this account
                active. Please complete the e-signature document you received by
                email.
              </DetailText>
            </TextWapper>
            <ButtonWapper>
              <ButtonTextWrapper>
                <ButtonText
                  onClick={() => {
                    resendEmailHandler();
                  }}
                >
                  RESEND THE EMAIL
                </ButtonText>
              </ButtonTextWrapper>
            </ButtonWapper>
          </InnerWrapper>
        </MainWrapper>
      )}
      {showSentAlert && (
        <SentAlertWrapper>
          <LeftBorder />
          <AlertStyle>
            <SAIcon
              icon={SAIcons.success}
              size={SAIconSize.medium}
              colorVariant="#264205"
            />
            The e-signature document(s) has been sent to {customerEmail}
            <CloseButton onClick={() => setshowSentAlert(false)}>
              <SAIcon
                icon={SAIcons.x}
                size={SAIconSize.small}
                colorVariant="#264205"
              />
            </CloseButton>
          </AlertStyle>
        </SentAlertWrapper>
      )}
    </>
  );
};

export default EsignAlert;

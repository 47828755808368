export default (address: string | undefined | null) => {
  if (!address) {
    return null;
  }
  const lowerCase = address.toLowerCase().split(' ');
  const titleCase = lowerCase.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return titleCase.join(' ');
};

import React, { useEffect, useState } from 'react';
import { ApolloQueryResult } from 'apollo-client';
import AWSAppSyncClient from 'aws-appsync';
import { loader } from 'graphql.macro';

import { useFeatureFlag } from '../../util/hooks';
import useIsResponsive from '../../util/useIsResponsive';
import customBase from '../../util/global.theme';

import {
  GET_OCTO_MODAL,
  GET_OCTO_MODALVariables,
  GET_OCTO_MODAL_ACCOUNT_ITEMS_POLICIES_ITEMS,
  GET_OCTO_MODAL_ACCOUNT_ITEMS_POLICIES_ITEMS_INSUREDASSETS_ITEMS,
} from './graphql/GET_OCTO_MODAL';

import {
  UPDATE_SPECIAL_EVENT_TRACKING,
  UPDATE_SPECIAL_EVENT_TRACKINGVariables,
} from './graphql/UPDATE_SPECIAL_EVENT_TRACKING';

import { MultiItemModal } from '..';
import OctoModal from './octoModal';

const breakpoint = customBase.global.breakpoints.medium;

const customStyle = `
  @media (min-width: ${breakpoint + 1}px) {
    width: 80%;
    min-width: 750px;
    max-width: 850px;
  }`;

interface OctoModalDataContainerProps {
  awsAppSyncClient: AWSAppSyncClient<any>;
  accountNumber: string | undefined;
}

const getOctoModalQuery = loader('./graphql/Get_Octo_Modal.graphql');

const specialEventTrackingMutation = loader(
  './graphql/Update_Special_Event_Tracking.graphql',
);

const getCompletedOctoModal = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string | undefined,
) => {
  const variables: GET_OCTO_MODALVariables = {
    account_number: accountNumber,
    id: accountNumber,
  } as GET_OCTO_MODALVariables;

  return awsAppSyncClient.query({
    query: getOctoModalQuery,
    variables,
  }) as Promise<ApolloQueryResult<GET_OCTO_MODAL>>;
};

const updateSpecialTracking = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string | undefined,
  fieldName: string,
) => {
  const variables: UPDATE_SPECIAL_EVENT_TRACKINGVariables = {
    account_number: accountNumber,
    field_name: fieldName,
  } as UPDATE_SPECIAL_EVENT_TRACKINGVariables;

  return awsAppSyncClient.mutate({
    mutation: specialEventTrackingMutation,
    variables,
  }) as Promise<ApolloQueryResult<UPDATE_SPECIAL_EVENT_TRACKING>>;
};

export const containsOctoTelematics = (
  items: (GET_OCTO_MODAL_ACCOUNT_ITEMS_POLICIES_ITEMS | null)[],
) => {
  let result: boolean = false;

  items.forEach((item: GET_OCTO_MODAL_ACCOUNT_ITEMS_POLICIES_ITEMS | null) => {
    item?.insuredassets?.items?.forEach(
      (
        asset: GET_OCTO_MODAL_ACCOUNT_ITEMS_POLICIES_ITEMS_INSUREDASSETS_ITEMS | null,
      ) => {
        if (asset?.telematics_vendor === 'OCTO') {
          result = true;
        }
      },
    );
  });

  return result;
};

const DataContainer = ({
  awsAppSyncClient,
  accountNumber,
}: OctoModalDataContainerProps) => {
  const [displayMultiModal, setDisplayMultiModal] = useState<boolean>(false);
  const [displayOctoModal, setDisplayOctoModal] = useState<boolean>(false);
  const [
    displayMobileAppMarketing,
    setDisplayMobileAppMarketing,
  ] = useState<boolean>(true);

  const isResponsive = useIsResponsive();

  const { flagDetails: OctoModalFlag } = useFeatureFlag('OctoModal');
  const { flagDetails: MobileAppPromoFlag } = useFeatureFlag('MobileAppPromo');

  const displayAppModal =
    !sessionStorage?.userLOB?.includes('Commercial') &&
    MobileAppPromoFlag?.enabled &&
    !isResponsive;

  useEffect(() => {
    if (OctoModalFlag?.enabled) {
      getCompletedOctoModal(awsAppSyncClient, accountNumber)
        .then((queryResult: ApolloQueryResult<GET_OCTO_MODAL>) => {
          let hasOctoTelematics = false;
          const completedOctoModal =
            queryResult?.data?.specialEventTracking?.c360_octo_modal;
          const confirmedPhoneNumber =
            queryResult?.data?.specialEventTracking?.phone_confirmation;
          const completedMobileAppMarketing =
            queryResult?.data?.specialEventTracking?.c360_mobile_app_marketing;

          if (queryResult?.data?.account?.items[0]?.policies?.items) {
            hasOctoTelematics = containsOctoTelematics(
              queryResult.data.account.items[0].policies.items,
            );
          }

          setDisplayOctoModal(
            !completedOctoModal && !!confirmedPhoneNumber && hasOctoTelematics,
          );
          if (completedMobileAppMarketing) {
            setDisplayMobileAppMarketing(false);
          }
          setDisplayMultiModal(true);
        })
        .catch((err: Error) => {
          console.error('GET_OCTO_MODAL ERROR: ', err);
          setDisplayOctoModal(false);
        });
    }
  }, [accountNumber, OctoModalFlag, MobileAppPromoFlag]);

  const updateOctoModal = () => {
    updateSpecialTracking(awsAppSyncClient, accountNumber, 'c360_octo_modal')
      .then((queryResult: ApolloQueryResult<UPDATE_SPECIAL_EVENT_TRACKING>) => {
        if (queryResult?.data?.specialEventTracking?.response !== 'Success') {
          throw new Error();
        }
      })
      .catch((err: Error) => {
        console.error('UPDATE_OCTO_MODAL_MUTATE ERROR: ', err);
      });
  };

  const updatePromoModal = () => {
    updateSpecialTracking(
      awsAppSyncClient,
      accountNumber,
      'c360_mobile_app_marketing',
    )
      .then((queryResult: ApolloQueryResult<UPDATE_SPECIAL_EVENT_TRACKING>) => {
        if (queryResult?.data?.specialEventTracking?.response !== 'Success') {
          throw new Error();
        }
      })
      .catch((err: Error) => {
        console.error('UPDATE_PROMO_MODAL ERROR: ', err);
      });
  };

  const ModalComponents = [];

  if (displayOctoModal) {
    ModalComponents.push(
      <OctoModal
        key="OctoModal"
        displayAppModal={displayAppModal}
        setDisplayOctoModal={setDisplayOctoModal}
        updateOctoModal={updateOctoModal}
      />,
    );
  }

  return OctoModalFlag &&
    MobileAppPromoFlag &&
    displayMultiModal &&
    ModalComponents.length !== 0 ? (
    <MultiItemModal customStyle={customStyle}>
      {ModalComponents.map((component) => component)}
    </MultiItemModal>
  ) : (
    <></>
  );
};

export default DataContainer;

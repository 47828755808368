import styled from 'styled-components';

import { responsiveCSS } from '../FeedbackModalBodyStyles';

export const FieldWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 12px 20px;

  ${responsiveCSS(`
    padding: 12px 30px;
  `)}

  &:nth-last-child(2) {
    padding-bottom: 0;
  }
`;

export const FieldLabel = styled.span`
  position: absolute;
  z-index: 1;
  left: 32px;
  top: 3px;
  line-height: 18px;
  font-size: 13px;
  padding-left: 3px;
  padding-right: 3px;
  background-color: #ffffff;
  color: #666666;

  ${responsiveCSS(`
    left: 42px;
  `)}

  &.error {
    color: #b00020;
  }

  &.active {
    color: #65a100;
  }
`;

export const ElementWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  border: 1px solid;
  border-radius: 5px;
  flex-grow: 1;
  border-color: #e7e7e7;

  &.error {
    border-color: #b00020;
  }

  &.active {
    border-color: #65a100;
  }
`;

export const SelectElement = styled.select`
  flex-grow: 1;
  height: 50px;
  border-radius: 5px;
  padding: 0 12px;
  border: none;
  outline: none;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  z-index: 1;
`;

export const SelectChevron = styled.div`
  display: block;
  position: absolute;
  right: 16px;
  top: calc(50% - 7.5px);
  z-index: 0;
`;

export const TextareaElement = styled.textarea`
  font-family: 'PT Sans';
  width: 100%;
  height: 135px;
  resize: none;
  border-radius: 5px;
  padding: 12px;
  border: none;
  outline: none;
`;

import React, { useEffect, useState } from 'react';
import { loader } from 'graphql.macro';
import AWSAppSyncClient from 'aws-appsync';
import { ApolloQueryResult } from 'apollo-client';
import ContactPage, { AgencyInfo } from './ContactPage';
import {
  GET_AGENCY_INFO,
  GET_AGENCY_INFOVariables,
} from '../../generated/GET_AGENCY_INFO';

interface ContactPageDataContainerProps {
  accountNumber: string | undefined;
  awsAppSyncClient: AWSAppSyncClient<any>;
}

const getAgencyInfoQuery = loader(
  '../../graphql/queries/Get_Agency_Info.graphql',
);

export function fetchAgencyInfo(
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
): Promise<ApolloQueryResult<GET_AGENCY_INFO>> {
  const variables: GET_AGENCY_INFOVariables = {
    account_number: accountNumber,
  } as GET_AGENCY_INFOVariables;

  const queryResult: Promise<
    ApolloQueryResult<GET_AGENCY_INFO>
  > = awsAppSyncClient.query({
    query: getAgencyInfoQuery,
    variables,
  }) as Promise<ApolloQueryResult<GET_AGENCY_INFO>>;
  return queryResult;
}

export function isEmpty(obj: any) {
  return Object.values(obj).every((x) => x === null || x === '-');
}

export function processAgencyInfo(data: GET_AGENCY_INFO) {
  let agencyName: string | null | undefined = '-';
  let businessPhone: string | null | undefined = '-';
  let businessEmail: string | null | undefined = '-';

  const agencyInfoAccountItems = data?.account?.items;

  if (
    agencyInfoAccountItems &&
    agencyInfoAccountItems[0] &&
    agencyInfoAccountItems[0].agencyContactInfo
  ) {
    if (agencyInfoAccountItems.length === 1) {
      const queriedAgencyName =
        agencyInfoAccountItems[0]?.agencyContactInfo?.agency_name;
      const queriedBusinessPhone =
        agencyInfoAccountItems[0]?.agencyContactInfo?.business_phone;
      const queriedBusinessEmail =
        agencyInfoAccountItems[0]?.agencyContactInfo?.business_email;

      if (
        agencyInfoAccountItems[0]?.service_tier?.toLowerCase() === 'platform'
      ) {
        agencyName = 'State Auto Insurance Services';
        businessPhone = '8663647489';
        businessEmail = 'platformservice@stateauto.com';
      } else {
        if (
          queriedAgencyName !== '' &&
          queriedAgencyName !== null &&
          queriedAgencyName !== undefined
        ) {
          agencyName = queriedAgencyName;
        } else agencyName = '-';

        if (
          queriedBusinessPhone !== '' &&
          queriedBusinessPhone !== null &&
          queriedBusinessPhone !== undefined
        ) {
          businessPhone = queriedBusinessPhone;
        } else businessPhone = '-';

        if (
          queriedBusinessEmail !== '' &&
          queriedBusinessEmail !== null &&
          queriedBusinessEmail !== undefined
        ) {
          businessEmail = queriedBusinessEmail;
        } else businessEmail = '-';
      }
    } else {
      agencyInfoAccountItems.forEach((agencyObj) => {
        if (!isEmpty(agencyObj?.agencyContactInfo)) {
          const queriedAgencyName = agencyObj?.agencyContactInfo?.agency_name;
          const queriedBusinessPhone =
            agencyObj?.agencyContactInfo?.business_phone;
          const queriedBusinessEmail =
            agencyObj?.agencyContactInfo?.business_email;

          if (agencyObj?.service_tier?.toLowerCase() === 'platform') {
            agencyName = 'State Auto Insurance Services';
            businessPhone = '8663647489';
            businessEmail = 'platformservice@stateauto.com';
          } else {
            if (
              queriedAgencyName !== '' &&
              queriedAgencyName !== null &&
              queriedAgencyName !== undefined
            ) {
              agencyName = queriedAgencyName;
            } else agencyName = '-';

            if (
              queriedBusinessPhone !== '' &&
              queriedBusinessPhone !== null &&
              queriedBusinessPhone !== undefined
            ) {
              businessPhone = queriedBusinessPhone;
            } else businessPhone = '-';

            if (
              queriedBusinessEmail !== '' &&
              queriedBusinessEmail !== null &&
              queriedBusinessEmail !== undefined
            ) {
              businessEmail = queriedBusinessEmail;
            } else businessEmail = '-';
          }
        }
      });
    }
  }
  return {
    name: agencyName,
    phoneNumber: businessPhone,
    email: businessEmail,
  };
}

const ContactPageDataContainer = ({
  accountNumber,
  awsAppSyncClient,
}: ContactPageDataContainerProps) => {
  const [agencyInfo, setAgencyInfo] = useState<AgencyInfo>();
  const [error, setError] = useState<Error | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (accountNumber) {
      fetchAgencyInfo(awsAppSyncClient, accountNumber)
        .then((result: ApolloQueryResult<GET_AGENCY_INFO>) => {
          const processedAgencyInfo = processAgencyInfo(result.data);
          if (!isEmpty(processedAgencyInfo)) {
            setAgencyInfo(processedAgencyInfo);
          } else setError(new Error('Empty Agency Values'));
          setLoading(false);
        })
        .catch((err: Error) => {
          console.error('GET_AGENCY_INFO ERROR: ', err);
          setError(err);
          setLoading(false);
        });
    }
  }, [accountNumber]);

  return (
    <ContactPage agencyInfo={agencyInfo} error={error} loading={loading} />
  );
};

export default ContactPageDataContainer;

import React from 'react';

import { SAIcon, SAIcons, SAIconSize } from '@saux/design-system-react';

import {
  LaterButton,
  ModalAction,
  ModalActionsWrap,
  ModalBody,
  ModalBodyLeft,
  ModalBodyRight,
  ModalButtonWrap,
  ModalDescription,
  ModalDesktopImage,
  ModalMobileImage,
  ModalSubTitle,
  ModalTextWrap,
  ModalTitle,
  SwitchNowButton,
} from './OctoModalStyles';

import desktopImg from '../../../assets/images/OctoDesktop.svg';
import mobileImg from '../../../assets/images/OctoMobile.svg';
import { simpleGTMDataLayer } from '../../../util/GTMHelpers';

interface OctoModalProps {
  nextSlide?: Function;
  displayAppModal: boolean;
  setDisplayOctoModal: Function;
  updateOctoModal: Function;
}

const OctoModal = ({
  nextSlide,
  displayAppModal,
  setDisplayOctoModal,
  updateOctoModal,
}: OctoModalProps) => {
  const closeModal = () => {
    updateOctoModal();
    setDisplayOctoModal(false);
  };

  const switchNowClickHandler = () => {
    window.open('http://stateauto.com/switch', '_blank');
    closeModal();
  };

  const nextClickHandler = () => {
    updateOctoModal();
    if (nextSlide) nextSlide();
  };

  return (
    <ModalBody>
      <ModalBodyLeft>
        <ModalDesktopImage src={desktopImg} />
        <ModalMobileImage src={mobileImg} />
      </ModalBodyLeft>
      <ModalBodyRight>
        <ModalTextWrap displayAppModal={displayAppModal}>
          <ModalTitle>Introducing the Tag from State Auto!</ModalTitle>
          <ModalSubTitle>
            It&#39;s time to ditch the Dongle and upgrade to State Auto&#39;s
            latest Auto telematics device — the Tag.
          </ModalSubTitle>
          <ModalDescription>
            <b>Beginning January 1, 2022</b>, we&#39;ll discontinue support for
            the Dongle device that&#39;s associated with our State Auto Safety
            360&#174; program. Your driving score at that point in time will
            remain as is without the ability to improve it if you don&#39;t make
            the switch to the Tag device.
          </ModalDescription>
        </ModalTextWrap>
        <ModalButtonWrap displayAppModal={displayAppModal}>
          {!displayAppModal && (
            <LaterButton
              onClick={() => {
                simpleGTMDataLayer({
                  event: 'MobileAppMarketing-OctoCloseButton-click',
                  event_action: 'MobileAppMarketing OctoCloseButton Click',
                  event_category: 'MobileAppMarketing',
                  event_label:
                    'MobileAppMarketing OctoCloseButton Button Click',
                  event_category_snowplow: 'Snowplow MobileAppMarketing',
                });
                closeModal();
              }}
            >
              Maybe Later
            </LaterButton>
          )}
          <SwitchNowButton onClick={() => switchNowClickHandler()}>
            SWITCH NOW
          </SwitchNowButton>
        </ModalButtonWrap>
        {displayAppModal && (
          <ModalActionsWrap>
            <ModalAction onClick={() => nextClickHandler()}>
              NEXT
              <SAIcon
                icon={SAIcons.chevronRight}
                size={SAIconSize.medium}
                colorVariant="primary"
              />
            </ModalAction>
          </ModalActionsWrap>
        )}
      </ModalBodyRight>
    </ModalBody>
  );
};

OctoModal.defaultProps = {
  nextSlide: () => {},
};

export default OctoModal;

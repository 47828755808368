import { ApolloQueryResult } from 'apollo-client';
import AWSAppSyncClient from 'aws-appsync';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import {
  GET_CONFIRMED_PHONE_NUMBER,
  GET_CONFIRMED_PHONE_NUMBERVariables,
} from '../../generated/GET_CONFIRMED_PHONE_NUMBER';
import {
  GET_CUSTOMER_INFO,
  GET_CUSTOMER_INFO_account_items,
} from '../../generated/GET_CUSTOMER_INFO';
import {
  UPDATE_SPECIAL_EVENT_TRACKING,
  UPDATE_SPECIAL_EVENT_TRACKINGVariables,
} from '../../generated/UPDATE_SPECIAL_EVENT_TRACKING';
import {
  fetchCustomerInfo,
  parsePrimaryPhone,
  updatePrimaryPhoneNumber,
} from '../profilePage/DataContainer';

import { validatePhone } from '../profilePage/ProfilePageRow';
import PhoneConfirmationModal, { PhoneInfo } from './PhoneConfirmationModal';

const getConfirmedPhoneNumberQuery = loader(
  '../../graphql/queries/Get_Confirmed_Phone_Number.graphql',
);
const phoneConfirmationMutation = loader(
  '../../graphql/mutations/Update_Special_Event_Tracking.graphql',
);

interface PhoneConfirmationModalDataContainerProps {
  awsAppSyncClient: AWSAppSyncClient<any>;
  accountNumber: string | undefined;
  userName: string;
}

const setDefaultPhoneInfo = (
  data: (GET_CUSTOMER_INFO_account_items | null) | null | undefined,
  setPhone: Function,
) => {
  if (data?.primary_phone) {
    const phoneNum = parsePrimaryPhone(data, data?.primary_phone);
    const validPhoneNum = validatePhone(phoneNum);

    setPhone({
      originalPhone: phoneNum,
      updatedPhone: phoneNum,
      validPhone: validPhoneNum,
      contactId: data?.publicid,
    });
  }
};

const getConfirmedPhoneNumber = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string | undefined,
) => {
  const variables: GET_CONFIRMED_PHONE_NUMBERVariables = {
    account_number: accountNumber,
  } as GET_CONFIRMED_PHONE_NUMBERVariables;

  return awsAppSyncClient.query({
    query: getConfirmedPhoneNumberQuery,
    variables,
  }) as Promise<ApolloQueryResult<GET_CONFIRMED_PHONE_NUMBER>>;
};

const updateConfirmedPhoneNumberMutate = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string | undefined,
) => {
  const variables: UPDATE_SPECIAL_EVENT_TRACKINGVariables = {
    account_number: accountNumber,
    field_name: 'phone_confirmation',
  } as UPDATE_SPECIAL_EVENT_TRACKINGVariables;

  return awsAppSyncClient.mutate({
    mutation: phoneConfirmationMutation,
    variables,
  }) as Promise<ApolloQueryResult<UPDATE_SPECIAL_EVENT_TRACKING>>;
};

export const processUpdateConfirmPhoneNumber = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string | undefined,
  setUpdateConfirmedPhoneNumber: Function,
  setConfirmedPhoneNumber: Function,
  setError: Function,
  setDisplaySuccess: Function,
) => {
  updateConfirmedPhoneNumberMutate(awsAppSyncClient, accountNumber)
    .then((queryResult: ApolloQueryResult<UPDATE_SPECIAL_EVENT_TRACKING>) => {
      if (queryResult?.data?.specialEventTracking?.response === 'Success') {
        setConfirmedPhoneNumber(true);
        setUpdateConfirmedPhoneNumber(false);
        setDisplaySuccess(true);
      } else {
        throw new Error();
      }
    })
    .catch((err: Error) => {
      console.error('UPDATE_CONFIRMED_PHONE_NUMBER_MUTATE ERROR: ', err);
      setError(true);
    });
};

export const processUpdatePhoneNumber = async (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
  phoneInfo: PhoneInfo,
  setSuccess: Function,
  setError: Function,
) => {
  updatePrimaryPhoneNumber(
    awsAppSyncClient,
    accountNumber,
    'updateContactPhone',
    phoneInfo.originalPhone,
    phoneInfo.updatedPhone,
    phoneInfo.contactId,
    'MOBILE',
  )
    .then((response: any) => {
      const { responseMsg } = response.data.updateContactNumber;

      if (responseMsg.includes('Contact Successfully updated')) {
        setSuccess(true);
      } else {
        setSuccess(false);
        setError(true);
      }
    })
    .catch((err: Error) => {
      console.error('SAVE PRIMARY PHONE NUMBER ERROR: ', err);
      setSuccess(false);
      setError(true);
    });
};

export const parseFirstNameFromUserName = (userName: string): string => {
  const firstName = userName.split(' ')[0];

  return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
};

const PhoneConfirmationModalDataContainer = ({
  awsAppSyncClient,
  accountNumber,
  userName,
}: PhoneConfirmationModalDataContainerProps) => {
  const [error, setError] = useState<boolean>(false);
  const [updatePhone, setUpdatePhone] = useState<boolean>(false);
  const [phoneUpdateSuccess, setPhoneUpdateSuccess] = useState<
    boolean | undefined
  >(undefined);
  const [phoneInfo, setPhoneInfo] = useState<PhoneInfo>({
    originalPhone: '',
    updatedPhone: '',
    validPhone: false,
    contactId: '',
  });
  const [confirmedPhoneNumber, setConfirmedPhoneNumber] = useState<boolean>(
    true,
  );
  const [
    updateConfirmedPhoneNumber,
    setUpdateConfirmedPhoneNumber,
  ] = useState<boolean>(false);
  const [displaySuccess, setDisplaySuccess] = useState<boolean>(false);

  useEffect(() => {
    getConfirmedPhoneNumber(awsAppSyncClient, accountNumber)
      .then((queryResult: ApolloQueryResult<GET_CONFIRMED_PHONE_NUMBER>) => {
        const phoneConfirmation =
          queryResult?.data?.specialEventTracking?.phone_confirmation;

        setConfirmedPhoneNumber(phoneConfirmation === true);
      })
      .catch((err: Error) => {
        console.error('GET_CONFIRMED_PHONE_NUMBER ERROR: ', err);
        setError(true);
      });

    fetchCustomerInfo(awsAppSyncClient, accountNumber)
      .then((apolloQueryResult: ApolloQueryResult<GET_CUSTOMER_INFO>) => {
        const data =
          apolloQueryResult?.data?.account?.items &&
          apolloQueryResult.data.account.items[0];

        setDefaultPhoneInfo(data, setPhoneInfo);
      })
      .catch((err: Error) => {
        console.error('GET_CUSTOMER_INFO ERROR: ', err);
        setError(true);
      });
  }, [accountNumber]);

  useEffect(() => {
    if (updatePhone && accountNumber) {
      processUpdatePhoneNumber(
        awsAppSyncClient,
        accountNumber,
        phoneInfo,
        setPhoneUpdateSuccess,
        setError,
      );
    }
  }, [updatePhone]);

  useEffect(() => {
    if ((updateConfirmedPhoneNumber || phoneUpdateSuccess) && accountNumber) {
      processUpdateConfirmPhoneNumber(
        awsAppSyncClient,
        accountNumber,
        setUpdateConfirmedPhoneNumber,
        setConfirmedPhoneNumber,
        setError,
        setDisplaySuccess,
      );
    }

    setUpdateConfirmedPhoneNumber(false);
  }, [updateConfirmedPhoneNumber, phoneUpdateSuccess]);

  return (
    <PhoneConfirmationModal
      error={error}
      phoneInfo={phoneInfo}
      confirmedPhoneNumber={confirmedPhoneNumber}
      displaySuccess={displaySuccess}
      setPhoneInfo={setPhoneInfo}
      userFirstName={parseFirstNameFromUserName(userName)}
      setUpdatePhone={setUpdatePhone}
      setUpdateConfirmedPhoneNumber={setUpdateConfirmedPhoneNumber}
    />
  );
};

export default PhoneConfirmationModalDataContainer;

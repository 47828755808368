import React from 'react';
import EmailIcon from '@material-ui/icons/Email';
import {
  FeedbackButtonContent,
  OpenFeedbackButton,
  EmailIconHolder,
  WordingHolder,
} from './FeedbackFloatingButtonStyles';

interface Props {
  showModal: Function;
}

export const FeedbackTriggerBtn = ({ showModal }: Props) => (
  <OpenFeedbackButton
    className="open-feedback-modal-button"
    type="button"
    onClick={() => showModal()}
  >
    <FeedbackButtonContent>
      <EmailIconHolder>
        <EmailIcon fontSize="small" />
      </EmailIconHolder>
      <WordingHolder>FEEDBACK</WordingHolder>
    </FeedbackButtonContent>
  </OpenFeedbackButton>
);

export default FeedbackTriggerBtn;

import React from 'react';
import { SAIcon, SAIcons, SAIconSize, SAText } from '@saux/design-system-react';
import { navigate } from '@reach/router';
import SlideOut from '../slideOut/SlideOut';
import {
  CloseHolder,
  ContactUsLinkHolder,
  IconNameCloseHolder,
  LinkHolder,
  MenuInitialsIcon,
  MenuOption,
  MenuOptionSpacer,
  Name,
  UserInfoHolder,
  UserName,
} from './AccountSlideOut.styles';
import formatInitials from '../../util/formatters/formatInitials';
import { ReactComponent as ProfileIcon } from '../../assets/images/ProfileIcon.svg';
import { ReactComponent as LogoutIcon } from '../../assets/images/LogoutIcon.svg';
import { ReactComponent as PhoneIcon } from '../../assets/images/PhoneIcon.svg';

interface AccountSlideOutProps {
  showSideModal: Function;
  logOutOnClick: Function;
  displayName: string;
}

export const responsiveAccountSlideOut = (
  userInitials: string,
  displayName: string,
  showSideModal: Function,
  logOutOnClick: Function,
) => {
  return (
    <SlideOut>
      <UserInfoHolder>
        <IconNameCloseHolder>
          <MenuInitialsIcon>
            <SAText
              text={userInitials}
              colorVariant="active-foreground"
              weight="bold"
              type="heading-2"
            />
          </MenuInitialsIcon>
          <UserName>
            <Name>{displayName}</Name>
          </UserName>
          <CloseHolder
            onClick={() => {
              showSideModal(false);
            }}
            test-attr="account-slide-out-close-button"
          >
            <SAIcon
              icon={SAIcons.x}
              size={SAIconSize.medium}
              colorVariant="dark"
            />
          </CloseHolder>
        </IconNameCloseHolder>
      </UserInfoHolder>
      <MenuOptionSpacer />
      <MenuOption
        onClick={() => {
          showSideModal(false);
          navigate('/profile');
        }}
        test-attr="account-slide-out-profile-option"
      >
        <ProfileIcon />
        <LinkHolder>
          <SAText type="heading-4" text="Profile" casing="uppercase" />
        </LinkHolder>
      </MenuOption>
      <MenuOption
        test-attr="account-slide-out-contact-option"
        onClick={() => {
          showSideModal(false);
          navigate('/contact');
        }}
      >
        <PhoneIcon />
        <ContactUsLinkHolder>
          <SAText type="heading-4" text="Contact Us" casing="uppercase" />
        </ContactUsLinkHolder>
      </MenuOption>
      <MenuOption
        onClick={() => {
          logOutOnClick();
        }}
        test-attr="account-slide-out-log-out-option"
      >
        <LogoutIcon />
        <LinkHolder>
          <SAText type="heading-4" text="Log Out" casing="uppercase" />
        </LinkHolder>
      </MenuOption>
    </SlideOut>
  );
};

const AccountSlideOut = ({
  showSideModal,
  logOutOnClick,
  displayName,
}: AccountSlideOutProps) => {
  const userInitials = formatInitials(displayName);
  if (window.screen.availWidth <= 768) {
    return responsiveAccountSlideOut(
      userInitials,
      displayName,
      showSideModal,
      logOutOnClick,
    );
  }
  return (
    <SlideOut>
      <UserInfoHolder>
        <IconNameCloseHolder>
          <MenuInitialsIcon>
            <SAText
              text={userInitials}
              colorVariant="active-foreground"
              weight="bold"
              type="heading-2"
            />
          </MenuInitialsIcon>
          <UserName>
            <Name>{displayName}</Name>
          </UserName>

          <CloseHolder
            onClick={() => {
              showSideModal(false);
            }}
            test-attr="account-slide-out-close-button"
          >
            <SAIcon
              icon={SAIcons.x}
              size={SAIconSize.medium}
              colorVariant="dark"
            />
          </CloseHolder>
        </IconNameCloseHolder>
      </UserInfoHolder>
      <MenuOptionSpacer />
      <MenuOption
        onClick={() => {
          showSideModal(false);
          navigate('/profile');
        }}
        test-attr="account-slide-out-profile-option"
      >
        <ProfileIcon />
        <LinkHolder>
          <SAText type="heading-4" text="Profile" casing="uppercase" />
        </LinkHolder>
      </MenuOption>
      <MenuOption
        test-attr="account-slide-out-contact-option"
        onClick={() => {
          showSideModal(false);
          navigate('/contact');
        }}
      >
        <PhoneIcon />
        <ContactUsLinkHolder>
          <SAText type="heading-4" text="Contact Us" casing="uppercase" />
        </ContactUsLinkHolder>
      </MenuOption>
      <MenuOption
        onClick={() => {
          logOutOnClick();
        }}
        test-attr="account-slide-out-log-out-option"
      >
        <LogoutIcon />
        <LinkHolder>
          <SAText type="heading-4" text="Log Out" casing="uppercase" />
        </LinkHolder>
      </MenuOption>
    </SlideOut>
  );
};

export default AccountSlideOut;

import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';

export const ErrorBanner = styled.div`
  width: 100vw;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  @media screen and (max-width: 600px) {
    height: 100px;
  }
`;

export const TextHolder = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 600px) {
    margin: 15px 10px 15px 0px;
  }
`;

export const TopText = styled.div`
  margin-bottom: 2px;
  @media screen and (max-width: 600px) {
    margin-bottom: 5px;
  }
`;

export const IconHolder = styled.div`
  margin: 0px 20px 0px 25px;
  @media screen and (max-width: 600px) {
    margin-left: 15px;
  }
`;

export const HeaderHolder = styled.div`
  background-color: #0174c6;

  & > header > section {
    justify-content: end;
    display: flex;
    align-items: center;
  }
`;

export const BarHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    max-width: 300px;
    height: 40px;
    border-radius: ${theme.size.spacer.xs};
    background-color: ${theme.color.light.background};
    cursor: pointer;
    margin-right: 30px;

    @media screen and (max-width: 600px) {
      margin: 0 15px;
    }
  `}
`;

export const Input = styled.input`
  ${({ theme }: { theme: SAUXTheme }) => `
    width: 100%;
    height: 100%;
    margin: 0 ${theme.size.spacer.small};
    border: none;
    border-radius: ${theme.size.spacer.xs};
    font-size: 16px;
    font-weight: 700;
    color: ${theme.color.background.foreground};
    &:focus {
        outline: 0;
    }
  `}
`;

export const MenuHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    display: flex;
    flex-direction: row;
    width: ${theme?.size?.spacer?.xl};
    height: ${theme?.size?.spacer?.xl};
    cursor: pointer;
  `}
`;

export const DropDown = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    position: absolute;
    top: ${theme.size.spacer.xl};
    right: 40px;
    width: 80px;
    height: ${theme.size.spacer.large};
    border: 1.5px solid #d3d3d3;
    border-radius: ${theme.size.spacer.xs};
    padding: 2.5px;
    background-color: #eef2f7;
    &:hover {
      cursor: pointer;
    }
    user-select: none;
    @media screen and (max-width: 600px) {
      width: 75px;
      height: ${theme.size.spacer.large};
      right: ${theme.size.spacer.large};
      margin-top: -${theme.size.spacer.medium};
    }
    `}
`;

import { ApolloQueryResult } from 'apollo-client';
import AWSAppSyncClient from 'aws-appsync';
import { loader } from 'graphql.macro';

const getSourceSystem = loader('../graphql/queries/Get_Source_System.graphql');
const getPAYGInfo = loader('../graphql/queries/Get_PAYG_Data_From_PC.graphql');

const getPayAsYouGo = loader(
  '../graphql/queries/Get_Policies_PayAsYouGo.graphql',
);

class AmpRedirect {
  private client: AWSAppSyncClient<any>;

  constructor(appsyncClient: AWSAppSyncClient<any>) {
    this.client = appsyncClient;
  }

  getSourceSystem = () => {
    return this.client.query({ query: getSourceSystem }) as Promise<
      ApolloQueryResult<any>
    >;
  };

  getPayAsYouGoStatus = (accountNum: string) => {
    return this.client.query({
      query: getPayAsYouGo,
      variables: { account_number: accountNum },
    }) as Promise<ApolloQueryResult<any>>;
  };

  fetchInfo = (username: string) => {
    return this.client.query({
      query: getPAYGInfo,
      variables: { username: username, getJobNumber: true },
    }) as Promise<ApolloQueryResult<any>>;
  };
}
// eslint-disable-next-line
export { AmpRedirect };

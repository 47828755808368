/* eslint-disable no-useless-escape */
import { FetchResult } from '@apollo/react-hooks';
import { ApolloQueryResult } from 'apollo-client';
import AWSAppSyncClient from 'aws-appsync';
import { loader } from 'graphql.macro';
import React, { useEffect, useReducer, useState } from 'react';
import {
  GET_CONFIRMED_PHONE_NUMBER,
  GET_CONFIRMED_PHONE_NUMBERVariables,
} from './graphql/generated/GET_CONFIRMED_PHONE_NUMBER';
import {
  GET_CUSTOMER_INFO,
  GET_CUSTOMER_INFOVariables,
  GET_CUSTOMER_INFO_account_items,
} from './graphql/generated/GET_CUSTOMER_INFO';
import {
  UPDATE_SPECIAL_EVENT_TRACKING,
  UPDATE_SPECIAL_EVENT_TRACKINGVariables,
} from './graphql/generated/UPDATE_SPECIAL_EVENT_TRACKING';
import {
  UPDATE_CONTACT_NUMBER,
  UPDATE_CONTACT_NUMBERVariables,
} from './graphql/generated/UPDATE_CONTACT_NUMBER';
import {
  UPDATE_EMAIL_ADDRESS,
  UPDATE_EMAIL_ADDRESSVariables,
} from './graphql/generated/UPDATE_EMAIL_ADDRESS';
import {
  GET_INSURED_PARTIES,
  GET_INSURED_PARTIESVariables,
  GET_INSURED_PARTIES_account_items_policy_insuredparties_items,
} from './graphql/generated/GET_INSURED_PARTIES';
import ProfilePage from './ProfilePage';
import { validatePhone, validatePrimaryPhoneType } from './ProfilePageRow';
import reducer, { initState, ProfilePageState } from './ProfileState';
import { useFeatureFlag } from '../../util/hooks';

const getCustomerInfo = loader('./graphql/query/Get_Customer_Info.graphql');
const getInsuredParties = loader('./graphql/query/Get_Insured_Parties.graphql');
const updateEmailMutation = loader(
  './graphql/mutation/Update_Email_Address.graphql',
);
const updatePrimaryPhoneMutation = loader(
  './graphql/mutation/Update_Contact_Number.graphql',
);
const getConfirmedPhoneNumberQuery = loader(
  './graphql/query/Get_Confirmed_Phone_Number.graphql',
);
const phoneConfirmationMutation = loader(
  './graphql/mutation/Update_Special_Event_Tracking.graphql',
);
const updatePasswordEIDMutation = loader(
  './graphql/mutation/Update_Password_EID.graphql',
);

interface ProfilePageDataContainerProps {
  awsAppSyncClient: AWSAppSyncClient<any>;
  accountNumber: any;
  displayName: JSX.Element;
  userId: string;
}

export const fetchCustomerInfo = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string | undefined,
): Promise<ApolloQueryResult<GET_CUSTOMER_INFO>> => {
  const variables: GET_CUSTOMER_INFOVariables = {
    account_number: accountNumber,
  } as GET_CUSTOMER_INFOVariables;

  return awsAppSyncClient.query({
    query: getCustomerInfo,
    variables,
  }) as Promise<ApolloQueryResult<GET_CUSTOMER_INFO>>;
};

export const fetchInsuredParties = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string | undefined,
  policyNumber: string,
): Promise<ApolloQueryResult<GET_INSURED_PARTIES>> => {
  const variables: GET_INSURED_PARTIESVariables = {
    account_number: accountNumber,
    policy_number: policyNumber,
  } as GET_INSURED_PARTIESVariables;

  return awsAppSyncClient.query({
    query: getInsuredParties,
    variables,
  }) as Promise<ApolloQueryResult<GET_INSURED_PARTIES>>;
};

const getConfirmedPhoneNumber = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string | undefined,
) => {
  const variables: GET_CONFIRMED_PHONE_NUMBERVariables = {
    account_number: accountNumber,
  } as GET_CONFIRMED_PHONE_NUMBERVariables;

  return awsAppSyncClient.query({
    query: getConfirmedPhoneNumberQuery,
    variables,
  }) as Promise<ApolloQueryResult<GET_CONFIRMED_PHONE_NUMBER>>;
};

export const updateConfirmedPhoneNumberMutate = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string | undefined,
) => {
  const variables: UPDATE_SPECIAL_EVENT_TRACKINGVariables = {
    account_number: accountNumber,
    field_name: 'phone_confirmation',
  } as UPDATE_SPECIAL_EVENT_TRACKINGVariables;

  return awsAppSyncClient.mutate({
    mutation: phoneConfirmationMutation,
    variables,
  }) as Promise<ApolloQueryResult<UPDATE_SPECIAL_EVENT_TRACKING>>;
};

export const updatePasswordEID = async (
  awsAppSyncClient: AWSAppSyncClient<any>,
  username: string,
  original: string,
  updated: string,
  dispatch: Function,
  setErrorField: Function,
) => {
  const variables = {
    username,
    original,
    updated,
  };

  return (awsAppSyncClient.mutate({
    mutation: updatePasswordEIDMutation,
    variables,
  }) as Promise<ApolloQueryResult<any>>)
    .then((response) => {
      if (
        response?.data?.updatePasswordEID?.response?.includes('successfully')
      ) {
        dispatch({ type: 'setEditing', data: false });
        dispatch({ type: 'saveCustomerInfo', data: false });
        dispatch({ type: 'displaySuccessSnackbar', data: true });
      } else {
        setErrorField('Password');
        dispatch({ type: 'saveCustomerInfo', data: false });
        dispatch({ type: 'displayErrorSnackbar', data: true });
        dispatch({ type: 'updateUI' });
      }
    })
    .catch((err: Error) => {
      console.error('UPDATE PASSWORD ERROR: ', err);
      setErrorField('Password');
      dispatch({ type: 'saveCustomerInfo', data: false });
      dispatch({ type: 'displayErrorSnackbar', data: true });
      dispatch({ type: 'updateUI' });
    });
};

export const updateEmailAddress = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
  oldEmail: string,
  newEmail: string,
  contactID: string,
  emailType: string,
  eidEmailUpdateFF: { enabled: boolean },
): Promise<FetchResult<UPDATE_EMAIL_ADDRESS>> => {
  const eidUserName = sessionStorage.getItem('EIDuserName') ?? '';

  const variables: UPDATE_EMAIL_ADDRESSVariables = {
    account_number: accountNumber,
    oldEmail,
    newEmail,
    contactID,
    emailType,
    eidUsername: eidEmailUpdateFF?.enabled ? eidUserName : null,
  };

  return awsAppSyncClient.mutate({
    mutation: updateEmailMutation,
    variables,
  }) as Promise<FetchResult<UPDATE_EMAIL_ADDRESS>>;
};

export const updatePrimaryPhoneNumber = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
  method: string,
  oldPhone: string,
  newPhone: string,
  contactID: string,
  phoneType: string,
): Promise<FetchResult<UPDATE_CONTACT_NUMBER>> => {
  const variables: UPDATE_CONTACT_NUMBERVariables = {
    account_number: accountNumber,
    method,
    oldPhone,
    newPhone,
    contactID,
    phoneType,
  };

  return awsAppSyncClient.mutate({
    mutation: updatePrimaryPhoneMutation,
    variables,
  }) as Promise<FetchResult<UPDATE_CONTACT_NUMBER>>;
};

export const parsePrimaryPhone = (
  data:
    | GET_CUSTOMER_INFO_account_items
    | GET_INSURED_PARTIES_account_items_policy_insuredparties_items
    | null,
  type: string | null | undefined,
) => {
  if (data && type) {
    switch (type) {
      case 'WORK':
        return data.work_phone || '';
      case 'HOME':
        return data.home_phone || '';
      case 'MOBILE':
        return data.cell_phone || '';
      default:
        return '-';
    }
  }

  return '-';
};

export const processFetchCustomerInfo = async (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
  dispatch: Function,
) => {
  if (sessionStorage.getItem('userLOB') === 'Commercial Lines') {
    const policyNum = sessionStorage.getItem('policyNumber') ?? '';
    fetchInsuredParties(awsAppSyncClient, accountNumber, policyNum)
      .then((apolloQueryResult: ApolloQueryResult<GET_INSURED_PARTIES>) => {
        let primaryContact;
        const data =
          apolloQueryResult &&
          apolloQueryResult?.data?.account &&
          apolloQueryResult?.data?.account?.items &&
          apolloQueryResult?.data?.account?.items[0] &&
          apolloQueryResult?.data?.account?.items[0]?.policy &&
          apolloQueryResult?.data?.account?.items[0]?.policy.insuredparties &&
          apolloQueryResult?.data?.account?.items[0]?.policy.insuredparties
            .items;

        if (data && data.length) {
          for (let i = 0; i < data.length; i += 1) {
            if (data[i].role_type && data[i].role_type === 'PRIMARYCONTACT') {
              primaryContact = data[i];
              break;
            }
          }
        }

        if (!apolloQueryResult.loading) {
          dispatch({ type: 'setLoading', data: false });
        }

        if (primaryContact?.email_address) {
          dispatch({
            type: 'setOriginalEmail',
            data: primaryContact?.email_address,
          });
          dispatch({
            type: 'setUpdatedEmail',
            data: primaryContact?.email_address,
          });
        }

        if (primaryContact?.primary_phone) {
          const phoneNum = parsePrimaryPhone(
            primaryContact,
            primaryContact?.primary_phone,
          );
          const validPhoneNum = validatePhone(phoneNum);
          const validPhoneType = validatePrimaryPhoneType(
            primaryContact?.primary_phone.toUpperCase(),
          );

          dispatch({
            type: 'setOriginalPrimaryPhone',
            data: {
              number: phoneNum,
              type: primaryContact?.primary_phone,
              validType: validPhoneType,
              validNumber: validPhoneNum,
            },
          });
          dispatch({
            type: 'setUpdatedPrimaryPhone',
            data: {
              number: phoneNum,
              type: primaryContact?.primary_phone,
              validType: validPhoneType,
              validNumber: validPhoneNum,
            },
          });
        }
      })
      .catch((err: Error) => {
        console.error('GET_INSURED_PARTIES ERROR: ', err);
        dispatch({ type: 'setLoading', data: false });
        dispatch({ type: 'setError', data: true });
      });
  } else {
    fetchCustomerInfo(awsAppSyncClient, accountNumber)
      .then((apolloQueryResult: ApolloQueryResult<GET_CUSTOMER_INFO>) => {
        const data =
          apolloQueryResult &&
          apolloQueryResult?.data?.account &&
          apolloQueryResult?.data?.account?.items &&
          apolloQueryResult?.data?.account?.items[0];

        if (!apolloQueryResult.loading) {
          dispatch({ type: 'setLoading', data: false });
        }

        if (data?.email_address) {
          dispatch({ type: 'setOriginalEmail', data: data?.email_address });
          dispatch({ type: 'setUpdatedEmail', data: data?.email_address });
        }

        if (data?.primary_phone) {
          const phoneNum = parsePrimaryPhone(data, data?.primary_phone);
          const validPhoneNum = validatePhone(phoneNum);
          const validPhoneType = validatePrimaryPhoneType(
            data?.primary_phone.toUpperCase(),
          );

          dispatch({
            type: 'setOriginalPrimaryPhone',
            data: {
              number: phoneNum,
              type: data?.primary_phone,
              validType: validPhoneType,
              validNumber: validPhoneNum,
            },
          });
          dispatch({
            type: 'setUpdatedPrimaryPhone',
            data: {
              number: phoneNum,
              type: data?.primary_phone,
              validType: validPhoneType,
              validNumber: validPhoneNum,
            },
          });
        }

        if (data?.publicid) {
          dispatch({ type: 'setContactID', data: data?.publicid });
        }
      })
      .catch((err: Error) => {
        console.error('GET_CUSTOMER_INFO ERROR: ', err);
        dispatch({ type: 'setLoading', data: false });
        dispatch({ type: 'setError', data: true });
      });
  }
};

export const processUpdateEmail = async (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
  state: ProfilePageState,
  dispatch: Function,
  setErrorField: Function,
  eidEmailUpdateFF: { enabled: boolean },
) => {
  if (state.originalEmail.address !== state.updatedEmail.address) {
    await updateEmailAddress(
      awsAppSyncClient,
      accountNumber,
      state.originalEmail.address,
      state.updatedEmail.address,
      state.contactID,
      'primary',
      eidEmailUpdateFF,
    )
      .then((response: any) => {
        const { responseCode, responseMsg } = response.data.updateEmailAddress;
        if (
          responseCode ||
          responseMsg.includes('Not a valid Email Address') ||
          responseMsg.includes('Email is not in proper syntax')
        ) {
          setErrorField('Email address');
          dispatch({ type: 'setEditing', data: false });
          dispatch({ type: 'saveCustomerInfo', data: false });
          dispatch({ type: 'displayErrorSnackbar', data: true });
          dispatch({ type: 'updateUI' });
        }

        if (responseMsg.includes('Contact Successfully updated')) {
          dispatch({ type: 'setEditing', data: false });
          dispatch({ type: 'saveCustomerInfo', data: false });
          dispatch({ type: 'displaySuccessSnackbar', data: true });
        }
      })
      .catch((err: Error) => {
        console.error('SAVE EMAIL ERROR: ', err);
      });
  }
};

export const processUpdatePrimaryPhoneType = async (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
  state: ProfilePageState,
  dispatch: Function,
  setErrorField: Function,
) => {
  // Updates the phone type, MUST pass in original primary phone twice in order for this to work
  // It is a known bug in the API/appsync repo
  if (state.originalPrimaryPhone.type !== state.updatedPrimaryPhone.type) {
    updatePrimaryPhoneNumber(
      awsAppSyncClient,
      accountNumber,
      'updatePrimaryPhoneType',
      state.originalPrimaryPhone.number,
      state.originalPrimaryPhone.number,
      state.contactID,
      state.updatedPrimaryPhone.type,
    )
      .then((response: any) => {
        const { responseMsg } = response.data.updateContactNumber;

        if (responseMsg.includes('Contact Successfully updated')) {
          dispatch({ type: 'setEditing', data: false });
          dispatch({ type: 'saveCustomerInfo', data: false });
          dispatch({ type: 'displaySuccessSnackbar', data: true });
        } else {
          setErrorField('Phone number');
          dispatch({ type: 'setEditing', data: false });
          dispatch({ type: 'saveCustomerInfo', data: false });
          dispatch({ type: 'displayErrorSnackbar', data: true });
          dispatch({ type: 'updateUI' });
        }
      })
      .catch((err: Error) =>
        console.error('SAVE PRIMARY PHONE TYPE ERROR: ', err),
      );
  }
};

export const processUpdatePrimaryPhoneNumber = async (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
  state: ProfilePageState,
  dispatch: Function,
  setErrorField: Function,
) => {
  if (state.originalPrimaryPhone.number !== state.updatedPrimaryPhone.number) {
    updatePrimaryPhoneNumber(
      awsAppSyncClient,
      accountNumber,
      'updateContactPhone',
      state.originalPrimaryPhone.number,
      state.updatedPrimaryPhone.number,
      state.contactID,
      state.updatedPrimaryPhone.type,
    )
      .then((response: any) => {
        const { responseMsg } = response.data.updateContactNumber;

        if (responseMsg.includes('Contact Successfully updated')) {
          dispatch({ type: 'setEditing', data: false });
          dispatch({ type: 'saveCustomerInfo', data: false });
          dispatch({ type: 'displaySuccessSnackbar', data: true });
          dispatch({ type: 'setUpdatePrimaryPhoneSuccessful', data: true });
        } else {
          setErrorField('Phone number');
          dispatch({ type: 'setEditing', data: false });
          dispatch({ type: 'saveCustomerInfo', data: false });
          dispatch({ type: 'displayErrorSnackbar', data: true });
          dispatch({ type: 'updateUI' });
        }
      })
      .catch((err: Error) =>
        console.error('SAVE PRIMARY PHONE NUMBER ERROR: ', err),
      );
  }
};

export const processUpdateConfirmedPhoneNumber = async (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
  state: ProfilePageState,
  dispatch: Function,
) => {
  if (state.updatedPrimaryPhoneSuccessful) {
    updateConfirmedPhoneNumberMutate(awsAppSyncClient, accountNumber)
      .then((queryResult: ApolloQueryResult<UPDATE_SPECIAL_EVENT_TRACKING>) => {
        if (queryResult?.data?.specialEventTracking?.response === 'Success') {
          dispatch({
            type: 'setConfirmedPrimaryPhone',
            data: true,
          });
        } else {
          throw new Error();
        }
      })
      .catch((err: Error) => {
        console.error('UPDATE_CONFIRMED_PHONE_NUMBER_MUTATE ERROR: ', err);
      });
  }
};

export const cancelClickHandler = (dispatch: Function) => {
  dispatch({ type: 'resetEmail' });
  dispatch({ type: 'resetPrimaryPhone' });
  dispatch({ type: 'updateUI' });
  dispatch({ type: 'setEditPassword', data: false });
  dispatch({ type: 'setEditProfile', data: false });
};

const ProfilePageDataContainer = ({
  awsAppSyncClient,
  accountNumber,
  displayName,
  userId,
}: ProfilePageDataContainerProps) => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [oldPassword, setOldPassword] = useState<string | undefined>();
  const [newPassword, setNewPassword] = useState<string | undefined>();
  const [confirmPassword, setConfirmPassword] = useState<string | undefined>();
  const [passwordIsLongEnough, setPasswordIsLongEnough] = useState<boolean>(
    false,
  );
  const [passwordHasCapital, setPasswordHasCapital] = useState<boolean>(false);
  const [passwordHasNumber, setPasswordHasNumber] = useState<boolean>(false);
  const [passwordHasLowercase, setPasswordHasLowercase] = useState<boolean>(
    false,
  );
  const [passwordHasSpecialChar, setPasswordHasSpecialChar] = useState<boolean>(
    false,
  );
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [passwordsDoNotMatch, setPasswordsDoNotMatch] = useState<boolean>(
    false,
  );
  const [errorField, setErrorField] = useState<string>('One or more fields');
  const { flagDetails: eidEmailUpdateFF } = useFeatureFlag('updateEmailInEID');

  useEffect(() => {
    if (newPassword) {
      if (newPassword.length > 11) {
        setPasswordIsLongEnough(true);
      } else setPasswordIsLongEnough(false);
      if (/[A-Z]/g.test(newPassword)) {
        setPasswordHasCapital(true);
      } else setPasswordHasCapital(false);
      if (/[a-z]/g.test(newPassword)) {
        setPasswordHasLowercase(true);
      } else setPasswordHasLowercase(false);
      if (/[0-9]/g.test(newPassword)) {
        setPasswordHasNumber(true);
      } else setPasswordHasNumber(false);
      if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g.test(newPassword)) {
        setPasswordHasSpecialChar(true);
      } else setPasswordHasSpecialChar(false);
    } else {
      setPasswordIsLongEnough(false);
      setPasswordHasCapital(false);
      setPasswordHasNumber(false);
      setPasswordHasLowercase(false);
      setPasswordHasSpecialChar(false);
    }
  }, [newPassword]);

  useEffect(() => {
    processFetchCustomerInfo(awsAppSyncClient, accountNumber, dispatch);
    getConfirmedPhoneNumber(awsAppSyncClient, accountNumber)
      .then((queryResult: ApolloQueryResult<GET_CONFIRMED_PHONE_NUMBER>) => {
        const confirmedAccountNumber =
          queryResult?.data?.specialEventTracking?.phone_confirmation;

        dispatch({
          type: 'setConfirmedPrimaryPhone',
          data: confirmedAccountNumber === accountNumber,
        });
      })
      .catch((err: Error) => {
        console.error('GET_CONFIRMED_PHONE_NUMBER ERROR: ', err);
        dispatch({ type: 'setError', data: true });
      });
  }, [accountNumber, state.updateUI]);

  useEffect(() => {
    // TODO - when the primary phone type dropdown is enabled, we need to check that it is a valid type here before enabling save
    if (state.updatedPrimaryPhone.validNumber && state.updatedEmail.valid) {
      dispatch({ type: 'setSaveEnabled', data: true });
    } else {
      dispatch({ type: 'setSaveEnabled', data: false });
    }
  }, [state.updatedEmail.valid, state.updatedPrimaryPhone.validNumber]);

  useEffect(() => {
    if (state.saveCustomerInfo) {
      processUpdateEmail(
        awsAppSyncClient,
        accountNumber,
        state,
        dispatch,
        setErrorField,
        eidEmailUpdateFF,
      );
      processUpdatePrimaryPhoneType(
        awsAppSyncClient,
        accountNumber,
        state,
        dispatch,
        setErrorField,
      );
      processUpdatePrimaryPhoneNumber(
        awsAppSyncClient,
        accountNumber,
        state,
        dispatch,
        setErrorField,
      );
      if (
        oldPassword &&
        newPassword &&
        passwordIsLongEnough &&
        passwordHasCapital &&
        passwordHasNumber &&
        passwordHasLowercase &&
        passwordHasSpecialChar
      ) {
        updatePasswordEID(
          awsAppSyncClient,
          sessionStorage?.getItem('EIDuserName') ?? '',
          oldPassword,
          newPassword,
          dispatch,
          setErrorField,
        );
      }
    }
  }, [state.saveCustomerInfo]);

  useEffect(() => {
    processUpdateConfirmedPhoneNumber(
      awsAppSyncClient,
      accountNumber,
      state,
      dispatch,
    );
  }, [state.updatedPrimaryPhoneSuccessful]);

  return (
    <ProfilePage
      state={state}
      dispatch={dispatch}
      displayName={displayName}
      userId={userId}
      cancelClickHandler={cancelClickHandler}
      oldPassword={oldPassword}
      newPassword={newPassword}
      confirmPassword={confirmPassword}
      setOldPassword={setOldPassword}
      setNewPassword={setNewPassword}
      setConfirmPassword={setConfirmPassword}
      passwordIsLongEnough={passwordIsLongEnough}
      passwordHasCapital={passwordHasCapital}
      passwordHasNumber={passwordHasNumber}
      passwordHasLowercase={passwordHasLowercase}
      passwordHasSpecialChar={passwordHasSpecialChar}
      passwordError={passwordError}
      passwordsDoNotMatch={passwordsDoNotMatch}
      setPasswordsDoNotMatch={setPasswordsDoNotMatch}
      setPasswordError={setPasswordError}
      errorField={errorField}
    />
  );
};

export default ProfilePageDataContainer;

export interface ProfilePageAction {
  type: string;
  data?: any;
}

export interface PhoneNumber {
  number: string;
  type: string;
  validNumber: boolean;
  validType: boolean;
}

export interface Email {
  address: string;
  valid: boolean;
}

export interface ProfilePageState {
  loading: boolean;
  error: boolean;
  editing: boolean;
  saveEnabled: boolean;
  saveCustomerInfo: boolean;
  originalEmail: Email;
  updatedEmail: Email;
  originalPrimaryPhone: PhoneNumber;
  updatedPrimaryPhone: PhoneNumber;
  confirmedPrimaryPhone: boolean;
  updatedPrimaryPhoneSuccessful: boolean;
  contactID: string;
  displayErrorSnackbar: boolean;
  displaySuccessSnackbar: boolean;
  updateUI: boolean;
  editPassword: boolean;
  editProfile: boolean;
}

export const initState: ProfilePageState = {
  loading: true,
  error: false,
  editing: false,
  saveEnabled: true,
  saveCustomerInfo: false,
  originalEmail: { address: '', valid: true },
  updatedEmail: { address: '', valid: true },
  originalPrimaryPhone: {
    number: '',
    type: '',
    validType: true,
    validNumber: true,
  },
  updatedPrimaryPhone: {
    number: '',
    type: '',
    validType: true,
    validNumber: true,
  },
  confirmedPrimaryPhone: false,
  updatedPrimaryPhoneSuccessful: false,
  contactID: '',
  displayErrorSnackbar: false,
  displaySuccessSnackbar: false,
  updateUI: true,
  editPassword: false,
  editProfile: false,
};

const reducer = (
  state: ProfilePageState,
  action: ProfilePageAction,
): ProfilePageState => {
  switch (action.type) {
    // PAGE STATUSES
    case 'setLoading':
      return { ...state, loading: action.data };
    case 'setError':
      return { ...state, error: action.data };
    case 'setEditing':
      return { ...state, editing: action.data };
    case 'setSaveEnabled':
      return { ...state, saveEnabled: action.data };
    case 'setEditPassword':
      return { ...state, editPassword: action.data };
    case 'setEditProfile':
      return { ...state, editProfile: action.data };

    // EMAIL
    case 'setOriginalEmail':
      return {
        ...state,
        originalEmail: { ...state.originalEmail, address: action.data },
      };
    case 'setUpdatedEmail':
      return {
        ...state,
        updatedEmail: { ...state.updatedEmail, address: action.data },
      };
    case 'setValidUpdatedEmail':
      return {
        ...state,
        updatedEmail: { ...state.updatedEmail, valid: action.data },
      };
    case 'resetEmail':
      return { ...state, updatedEmail: state.originalEmail };

    // PRIMARY PHONE
    case 'setOriginalPrimaryPhone':
      return { ...state, originalPrimaryPhone: action.data };
    case 'setUpdatedPrimaryPhone':
      return { ...state, updatedPrimaryPhone: action.data };
    case 'resetPrimaryPhone':
      return { ...state, updatedPrimaryPhone: state.originalPrimaryPhone };
    case 'setConfirmedPrimaryPhone':
      return { ...state, confirmedPrimaryPhone: action.data };
    case 'setUpdatePrimaryPhoneSuccessful':
      return { ...state, updatedPrimaryPhoneSuccessful: action.data };
    case 'setValidPrimaryPhoneType':
      return {
        ...state,
        updatedPrimaryPhone: {
          ...state.updatedPrimaryPhone,
          validType: action.data,
        },
      };

    // MISC
    case 'setContactID':
      return { ...state, contactID: action.data };
    case 'saveCustomerInfo':
      return { ...state, saveCustomerInfo: action.data };
    case 'displayErrorSnackbar':
      return { ...state, displayErrorSnackbar: action.data };
    case 'displaySuccessSnackbar':
      if (!state.displayErrorSnackbar) {
        return { ...state, displaySuccessSnackbar: action.data };
      }

      return state;
    case 'updateUI':
      return { ...state, updateUI: !state.updateUI };

    // DEFAULT
    default:
      console.error('ERROR: INVALID ACTION DISPATCHED');
      return state;
  }
};

export default reducer;

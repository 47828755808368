/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import { SAIcon, SAIconSize, SAUXTheme } from '@saux/design-system-react';
import {
  InputButtonStyles,
  SAInputContainerProps,
  SAInputFieldProps,
} from '@saux/design-system-react/input/variants';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

type StyleProps = {
  id: string;
  color?: string;
  error?: boolean;
  hasOnClickIcon?: boolean;
  theme?: SAUXTheme;
  variant?:
    | 'button-link'
    | 'button-link-small'
    | 'button-primary'
    | 'button-primary-outline'
    | 'button-primary-small'
    | 'button-primary-small-outline'
    | 'button-secondary'
    | 'button-secondary-outline'
    | 'button-secondary-small'
    | 'button-secondary-small-outline'
    | 'search';
};

// TODO: Figure out why any is needed again...
const Container: any = styled.div`
  ${(props: StyleProps) => {
    const { theme, color, error, variant, hasOnClickIcon } = props;
    const baseColor = color || theme?.color.primary.background;
    const inputs = `input[type='date'], input[type='email'], input[type='file'], input[type='month'], input[type='number'], input[type='password'], input[type='search'], input[type='text'], input[type='tel'], input[type='time'], input[type='url'], input[type='week'], select`;
    const borderRadius = variant?.includes('search')
      ? '25px'
      : theme?.size.radius.small;
    const visibility = variant?.includes('search') ? 'hidden' : 'visible';
    const fontSize = variant?.includes('search')
      ? `font-size: ${theme?.font.primary.size.smallTEMP};`
      : '';
    const height = variant?.includes('search') ? 'height: 40px;' : '';
    const boxSizing = variant?.includes('search')
      ? 'box-sizing: border-box;'
      : '';
    const padding = variant?.includes('search')
      ? theme?.size.spacer.small
      : theme?.size.spacer.medium;
    const alignment = variant?.includes('search')
      ? `left: ${theme?.size.spacer.medium};`
      : `right: ${theme?.size.spacer.medium};`;
    const alignmentInitial = variant?.includes('search')
      ? 'left: initial;'
      : 'right: initial;';
    const iconPadding = variant?.includes('search')
      ? 'padding-left: 40px;'
      : 'padding-right: 40px;';
    const verticalAlign = variant?.includes('search')
      ? 'vertical-align: middle;'
      : '';
    const cursor = hasOnClickIcon ? 'pointer' : 'initial';

    return css`
      .textLikeInput {
        align-items: center;
        display: inline-flex;
        &.fullWidthInput {
          display: flex;
        }
        position: relative;
        padding-top: ${theme?.size.spacer.xs};

        label {
          background-color: ${theme?.color.container.background};
          color: ${error ? theme?.color.alert.background : '#666666'};
          font-size: 12px;
          left: 0;
          padding-top: inherit;
          padding-left: ${theme?.size.spacer.xs};
          padding-right: ${theme?.size.spacer.xs};
          position: absolute;
          top: 0;
          transform: translate(
            calc(${theme?.size.spacer.medium} - ${theme?.size.spacer.xs}),
            -8px
          );
          visibility: ${visibility};
        }

        ${inputs} {
          border: 1px solid ${error ? theme?.color.alert.background : '#dedede'}; // TODO: Set to theme variable.
          border-radius: ${borderRadius};
          font-family: ${theme?.font.primary.family};
          ${fontSize}
          padding: ${padding};
          width: 100%;
          ${height}
          ${boxSizing}
          &:active,
          &:focus {
            outline: none;
          }
        }

        svg,
        .icon-button {
          position: absolute;
          ${alignment}
        }

        .icon-button {
          appearance: none;
          border: none;
          background: none;
          svg {
            position: relative;
            ${alignmentInitial}
            ${verticalAlign}
            &:hover {
              cursor: ${cursor};
            }
          }
        }
      }

      &.focused .textLikeInput {
        label {
          color: ${baseColor};
        }

        ${inputs} {
          border: 1px solid ${baseColor};
        }
      }

      .iconInput {
        ${inputs} {
          ${iconPadding}
        }
      }

      ${InputButtonStyles(props)};
    `;
  }};
`;

interface HelpTextProps {
  theme: SAUXTheme;
}

export const HelpText = styled.span`
  ${({ theme }: HelpTextProps) => {
    return `
      color: #666666;
      display: block;
      font-size: 12px;
      padding: ${theme?.size.spacer.xs} 0 0 ${theme?.size.spacer.medium};
    `;
  }};
`;

/** [`SAInput` is the preferred way to implement inputs with SAUX](/docs/input--text-like-inputs). While `SAInputContainer` is technically also in beta, it will likely be deprecated due to its less intuitive behavior (e.g. can/should `SAInputContainer` be able to contain more than 1 input, should it be able to contain non-input children). If you have thoughts on this topic, please speak up in this [GitHub issue](https://github.ent.stateauto.com/DigitalLabs/saux-design-system-react/issues/63). */
export function SAInputContainer({
  id,
  children,
  hint,
  icon,
  iconColor,
  iconSize,
  label,
  onClickIcon,
  className,
  color,
  error,
  variant,
  theme,
  fullWidth,
  ...rest
}: SAInputContainerProps) {
  const [focused, setFocused] = useState(false);
  const LabelComponent = label && (
    <label htmlFor={id} className="saInputLabel">
      {label}
    </label>
  );
  const icSize: string = iconSize || SAIconSize.small;
  const icColor: string = iconColor || 'primary';
  const IconComponent = icon ? (
    <SAIcon icon={icon} size={icSize} colorVariant={icColor} />
  ) : null;
  const ClickableIcon =
    IconComponent && onClickIcon ? (
      <button
        className="icon-button"
        type="button"
        onClick={onClickIcon}
        aria-label="clear input"
      >
        {IconComponent}
      </button>
    ) : null;
  const classes = `${focused ? 'focused' : ''} ${className || ''}`;

  function onBlur() {
    setFocused(false);
  }

  function onFocus() {
    setFocused(true);
  }

  const textLikeInput = [
    'password',
    'search',
    'tel',
    'text',
    'url',
    'date',
    'datetime-local',
    'email',
    'month',
    'time',
    'week',
    'number',
  ];

  const isOnlyInput = (
    inputs: React.ReactNode | React.ReactElement,
  ): inputs is React.ReactElement =>
    !!(
      inputs !== undefined &&
      React.isValidElement(inputs) &&
      (inputs.type === 'input' || inputs.type === 'select')
    );

  const cloneInputs = (input: React.ReactNode) => {
    const newProps = {
      id,
      'aria-describedby': hint ? `${id}-hint` : undefined,
      theme,
      ...rest,
    };
    const clone = (inputItem: React.ReactNode) => {
      if (isOnlyInput(inputItem)) {
        return React.cloneElement(inputItem, newProps);
      }
      return new Error('SAInputContainer only accepts one child');
    };
    return clone(input);
  };

  const renderInputElem = (elements: React.ReactNode) => {
    if (isOnlyInput(elements)) {
      if (
        textLikeInput.includes(elements.props.type) ||
        elements.type === 'select'
      ) {
        return (
          <div
            className={`saInputWrapper ${className || ''}`}
            data-testid="saInputWrapper"
          >
            <div
              className={`textLikeInput${icon ? ' iconInput' : ''}${
                fullWidth ? ' fullWidthInput' : ''
              }`}
            >
              {ClickableIcon || IconComponent}
              {LabelComponent}
              {cloneInputs(elements)}
            </div>

            {hint && (
              <HelpText id={`${id}-hint`} className="saHelpText" theme={theme}>
                {hint}
              </HelpText>
            )}
          </div>
        );
      }
      if (elements.props.type === 'checkbox') {
        return (
          <div className="checkBox">
            {cloneInputs(elements)}
            {LabelComponent}
          </div>
        );
      }
    }
    return cloneInputs(elements);
  };

  return (
    <Container
      id={id}
      onBlur={onBlur}
      onFocus={onFocus}
      className={classes}
      color={color}
      variant={variant}
      error={error}
      theme={theme}
      // TODO: The following onClickIcon prop is throwing a warning in the console "Unknown event handler property 'onClickIcon'.  It will be ignored."
      hasOnClickIcon={!!onClickIcon}
    >
      {renderInputElem(children)}
    </Container>
  );
}

/** `SAInput` is the preferred way to implement inputs with SAUX. While `SAInputContainer` is technically also in beta, it will likely be deprecated due to its less intuitive behavior (e.g. can/should `SAInputContainer` be able to contain more than 1 input, should it be able to contain non-input children). If you have thoughts on this topic, please speak up in this [GitHub issue](https://github.ent.stateauto.com/DigitalLabs/saux-design-system-react/issues/63). */
export const SAInput = React.forwardRef<HTMLInputElement, SAInputFieldProps>(
  (
    {
      id,
      hint,
      icon,
      iconSize,
      iconColor,
      label,
      onClickIcon,
      color,
      className,
      error,
      theme,
      variant,
      value,
      fullWidth,
      ...additionalProps
    },
    ref,
  ) => {
    return (
      <SAInputContainer
        id={id}
        hint={hint}
        icon={icon}
        iconSize={iconSize}
        iconColor={iconColor}
        label={label}
        onClickIcon={onClickIcon}
        className={className}
        color={color}
        error={error}
        theme={theme}
        variant={variant}
        fullWidth={fullWidth}
      >
        <input {...additionalProps} ref={ref} value={value} />
      </SAInputContainer>
    );
  },
);

SAInput.defaultProps = {
  id: '',
  hint: '',
  icon: undefined,
  iconSize: '',
  iconColor: '',
  label: '',
  onClickIcon: undefined,
  className: '',
  color: undefined,
  error: false,
  theme: undefined,
  variant: undefined,
  fullWidth: false,
};

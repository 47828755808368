import React, { useState, ChangeEvent, ReactNode } from 'react';

import { SAIcon, SAIcons, SAIconSize } from '@saux/design-system-react';

import {
  FieldWrap,
  FieldLabel,
  ElementWrap,
  SelectElement,
  SelectChevron,
  TextareaElement,
} from './FieldGroupStyles';

interface FieldGroupProps {
  label: string;
  active: boolean;
  error: boolean;
  children: ReactNode;
}

const FieldGroup = ({ label, active, error, children }: FieldGroupProps) => (
  <FieldWrap>
    <FieldLabel className={`${active ? 'active' : ''} ${error ? 'error' : ''}`}>
      {label}
    </FieldLabel>
    <ElementWrap
      className={`${active ? 'active' : ''} ${error ? 'error' : ''}`}
    >
      {children}
    </ElementWrap>
  </FieldWrap>
);

export const topicOptions: string[] = [
  'Select a Topic',
  'Billing',
  'Claims',
  'Policy',
  'Other (not account-related)',
];

interface SelectProps {
  error: boolean;
  value: string;
  onChange: Function;
}

export const SelectGroup = ({ error, value, onChange }: SelectProps) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const handleChange = (selectedOption: string) => {
    onChange(selectedOption);
    (document.querySelector('select:focus') as HTMLElement)?.blur();
  };

  return (
    <FieldGroup label="Select a topic" active={isActive} error={error}>
      <SelectElement
        onFocus={() => setIsActive(true)}
        onBlur={() => setIsActive(false)}
        value={value}
        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
          handleChange(e.target.value)
        }
      >
        {topicOptions.map((option) => (
          <option key={option}>{option}</option>
        ))}
      </SelectElement>
      <SelectChevron>
        <SAIcon
          icon={isActive ? SAIcons.chevronUpBold : SAIcons.chevronDownBold}
          size={SAIconSize.small}
          colorVariant="#4D840B"
        />
      </SelectChevron>
    </FieldGroup>
  );
};

interface TextareaProps {
  error: boolean;
  value: string;
  onChange: Function;
}

export const TextareaGroup = ({ error, value, onChange }: TextareaProps) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  return (
    <FieldGroup label="Message" active={isActive} error={error}>
      <TextareaElement
        value={value}
        onFocus={() => setIsActive(true)}
        onBlur={() => setIsActive(false)}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
          onChange(e.target.value)
        }
      />
    </FieldGroup>
  );
};

import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Spinner } from '../../assets/images/Spinner.svg';

const Overlay = styled.div`
   {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
  }
`;
const OverlayWrapper = styled.div`
   {
    width: 100%;
    height: 100%;
    position: relative;
  }
`;

const OverlaySpinner = styled.div`
   {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Spinning = () => {
  return (
    <Overlay>
      <OverlayWrapper>
        <OverlaySpinner>
          <Spinner />
        </OverlaySpinner>
      </OverlayWrapper>
    </Overlay>
  );
};

export default Spinning;

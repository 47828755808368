import styled from 'styled-components';

export const PriorReportMainWrapper = styled.div`
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  background: #fff;
`;
export const Wrapper = styled.div`
  height: 80vh;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  background: #fff;
`;
export const Text = styled.div`
  height: 80vh;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const PriorReportWrapper = styled.div`
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  padding: 25px;
`;

export const HeaderContainer = styled.div`
  width: 50%;
`;

export const HeaderText = styled.div`
  color: #3b3b3b;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

export const PolicyDropDownContainer = styled.div`
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  margin: 20px 0px;
  height: 100px;
`;

export const SummaryContainer = styled.div`
  display: flex;
  height: 54px;
  padding: 15px 743px 17px 13px;
  align-items: center;
  border-radius: 2px;
  flex-shrink: 0;
  background: var(--grays-100-gray-blue, #e3e9f1);
  @media (max-width: 768px) {
    padding: 15px 20px 17px 13px;
  }
`;

export const SummaryText = styled.div`
  color: var(--text-primary-text-black, #3b3b3b);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const PolicyMainBox = styled.div`
  display: flex;
  margin: 20px 0px;
  padding: 0px 30px;
`;

export const PolicyLeftBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
`;

export const PolicyLabel = styled.div`
  color: var(--text-primary-text-black, #b3b3b);
`;

export const PolicyLabelText = styled.div`
  color: var(--text-primary-text-black, #b3b3b);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 200%; /* 32px */
`;

export const PolicyValue = styled.div`
  margin-right: 30px;
`;

export const PolicyValueText = styled.div`
  color: var(--text-primary-text-black, #b3b3b);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 200%; /* 32px */
`;

export const PolicyRightBox = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;

export const PolicyRightButton = styled.div`
  background: #fff;
  color: #0173c6;
  align-self: flex-end;
  width: fit-content;
  font-weight: 700;
  border: 1px solid #0173c6;
  border-radius: 4px;
  padding: 8px 25px;
  text-transform: uppercase;
  cursor: pointer;
`;
export const WarningContainer = styled.div`
  background: linear-gradient(0deg, #fce4bd, #fce4bd),
    linear-gradient(0deg, #fff6e9, #fff6e9);
  color: #7a5212;
  height: 10vh;
  margin-top: 4vh;
  margin-bottom: 4vh;
  font-size: 1rem;
  border-left: 5px solid #f5a524;
  border-bottom: 1px solid #f5a524;
  display: flex;
  justify-content: center;
  align-items: center;
`;

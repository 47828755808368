import React, { useState, useCallback, useEffect } from 'react';
import { ReactComponent as ChevronUp } from '../../assets/images/ChevronUp.svg';
import { ReactComponent as ChevronDownIcon } from '../../assets/images/ChevronDownIcon.svg';
import { ReactComponent as Calendar } from '../../assets/images/Calendar.svg';
import { Location } from './graphql/GET_PREMIUM_DETAILS';
import { formatCurrency } from '../../util/formatters';
import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
  ClassHeader,
  ClassCell,
  Header,
  TimePeriod,
  Result,
  ResultLabel,
  LocationDisplay,
  ReportedPayroll,
  Green,
  Icon,
  SummaryContainer,
  SummaryText,
  Wrapper,
  Span,
} from '../priorReport/JurisdictionDetailsStyles';

interface JurisdictionDetailsProps {
  locationDetails: Location[];
  jurisdictionName: string;
}
interface ExtractedValue {
  classCode: string;
  auditedPayroll: number | undefined;
  description: string;
  estimatedPayroll?: string | undefined;
}

const JurisdictionDetails = ({
  locationDetails,
  jurisdictionName,
}: JurisdictionDetailsProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [locationArray, setLocationArray] = useState<(string | null)[]>([]);
  const locationName: string[] = [];
  const [ArrayExtractedValues, setArrayExtractedValues] = useState<
    ExtractedValue[][]
  >([]);
  let payroll = 0;
  const [finalPayroll, setFinalPayroll] = useState<number | null>(0);
  useEffect(() => {
    locationDetails.forEach((locationDetail: any) => {
      locationName.push(locationDetail.locationDisplayName);
      const Details: ExtractedValue[] = [];
      locationDetail.coveredEmployees.forEach((employee: any) => {
        const temp: ExtractedValue = {
          classCode: employee.classCode,
          auditedPayroll: employee.auditedPayroll,
          description: employee.description,
          estimatedPayroll: employee.estimatedPayroll,
        };
        if (temp.auditedPayroll !== undefined) {
          payroll += temp.auditedPayroll;
        }
        Details.push(temp);
      });
      setLocationArray(locationName);
      setFinalPayroll(locationDetail?.totalReportedPayRoll);
      Details.sort((a, b) => a.classCode.localeCompare(b.classCode));
      setArrayExtractedValues((prevArray) => [...prevArray, Details]);
    });
  }, [locationDetails]);

  const toggleDropdown = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);
  const generateKey = (location: string | null, index: number) => {
    return location ?? index.toString();
  };

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLSpanElement>) => {
      if (event.key === 'Enter' || event.key === ' ') {
        toggleDropdown();
      }
    },
    [toggleDropdown],
  );
  function sumOfAuditedPayroll(value: ExtractedValue[]): number {
    return value.reduce((sum, x) => {
      if (x.auditedPayroll !== undefined) {
        return sum + Number(x.auditedPayroll);
      }
      return sum;
    }, 0);
  }
  function sumOfEstimatedPayroll(value: ExtractedValue[]): number {
    return value.reduce((sum, x) => {
      if (x.estimatedPayroll !== undefined) {
        return sum + Number(x.estimatedPayroll);
      }
      return sum;
    }, 0);
  }
  return (
    <Wrapper>
      <SummaryContainer>
        <SummaryText>{jurisdictionName}</SummaryText>
        <ReportedPayroll>
          Total Reported Payroll: &nbsp;&nbsp;&nbsp;
          {formatCurrency(finalPayroll)}
          <Span
            role="button"
            tabIndex={0}
            onClick={toggleDropdown}
            onKeyDown={handleKeyDown}
          >
            {isOpen ? <ChevronUp /> : <ChevronDownIcon />}
          </Span>
        </ReportedPayroll>
      </SummaryContainer>
      {isOpen ? (
        <>
          {locationArray.map((location, index) => (
            <>
              <LocationDisplay key={generateKey(location, index)}>
                <Green />
                <b>Location {index + 1}: </b> {location}
              </LocationDisplay>
              <Table>
                <Header>
                  <ClassHeader>Class Code</ClassHeader>
                  <TableHeader>Estimated Payroll</TableHeader>
                  <TableHeader>Reported Payroll</TableHeader>
                </Header>
                {ArrayExtractedValues[index] &&
                  ArrayExtractedValues[index].map((value) => (
                    <TableRow key={value.classCode}>
                      <ClassCell>
                        {value.classCode}-{value.description}
                      </ClassCell>
                      <TableCell>
                        {formatCurrency(value.estimatedPayroll)}
                      </TableCell>
                      <TableCell>
                        {formatCurrency(value.auditedPayroll)}
                      </TableCell>
                    </TableRow>
                  ))}
                {ArrayExtractedValues.length > 0 && (
                  <TableRow>
                    <ResultLabel>Total</ResultLabel>
                    <Result>
                      {formatCurrency(
                        sumOfEstimatedPayroll(ArrayExtractedValues[index]),
                      )}
                    </Result>
                    <Result>
                      {formatCurrency(
                        sumOfAuditedPayroll(ArrayExtractedValues[index]),
                      )}
                    </Result>
                  </TableRow>
                )}
              </Table>
            </>
          ))}
        </>
      ) : null}
    </Wrapper>
  );
};

export default JurisdictionDetails;

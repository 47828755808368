import styled from 'styled-components';
import { SAUXTheme } from '@saux/design-system-react';
import customBase from '../../../util/global.theme';

const breakpoint = customBase.global.breakpoints.medium;

export const FeedbackButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const OpenFeedbackButton = styled.button`
  ${({ theme }: { theme: SAUXTheme }) => `
    position: fixed;
    z-index: 100;
    top: 25%;
    right: 0;
    height: 36px;
    width: 130px;
    background-color: hsl(12, 60%, 49%);
    cursor: pointer;
    color: white;
    font-weight: bold;
    font-size: 14px;
    border: none;
    border-radius: ${theme?.size?.radius?.small} ${theme?.size?.radius?.small} 0px 0px;
    box-shadow: 0px ${theme?.size?.spacer?.xxs} ${theme?.size?.radius?.small} 0px hsla(205, 99%, 28%, 0.2);
    font-family: 'PT Sans', sans-serif;
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
    -webkit-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    transform-origin: right bottom;
  `}

  @media (max-width: ${breakpoint}px) {
    display: none;
  }
`;

export const EmailIconHolder = styled.span`
  margin: 3px 6px 0px 0px;
`;

export const WordingHolder = styled.span`
  margin-top: 3px;
`;

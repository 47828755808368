import padStart from 'lodash.padstart';

const padZeros = (num: number | string) => padStart(num.toString(), 2, '0');

export default (date?: string | number | Date | null) => {
  const formattedDate = date instanceof Date ? date : new Date(date as string);

  if (formattedDate.toString() === 'Invalid Date') {
    // We don't want to actually display "Invalid Date" in the UI.
    return date;
  }

  const d = formattedDate;

  return `${padZeros(String(d.getUTCMonth() + 1))}/${padZeros(
    String(d.getUTCDate()),
  )}/${d.getUTCFullYear()}`;
};

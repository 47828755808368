import styled from 'styled-components';
import { SAModal } from '@saux/design-system-react';
import customBase from '../../util/global.theme';

const breakpoint = customBase.global.breakpoints.medium;

const defaultWidth = '700px';
const defaultHeight = 'auto';

export const MultiItemModalWrapper = styled(SAModal)`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MultiItemContainer = styled.div<{
  customStyle: string;
}>`
  display: block;
  position: relative;
  background-color: #ffffff;
  border-radius: 4px;
  overflow: hidden;
  width: ${defaultWidth};
  height: ${defaultHeight};

  @media (max-width: ${breakpoint}px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  ${({ customStyle }) => customStyle}
`;

const scaleInOutDuration = 0.2;
const scaleSlideDelay = 0.15;
const slideInDuration = 0.45;

export const SlideReel = styled.div<{
  slideHeight: string;
  prevIndex: number;
  activeIndex: number;
  count: number;
}>`
  display: flex;
  align-items: center;
  width: calc(100% * ${({ count }) => count});
  height: ${({ slideHeight }) => slideHeight};
  position: relative;
  top: 0;
  left: -${({ prevIndex }) => 100 * prevIndex}%;
  transition-timing-function: ease-out;
  transition-delay: ${(scaleInOutDuration + scaleSlideDelay).toFixed(1)}s;
  transition-property: all;
  transition-duration: ${slideInDuration}s;

  &.reelAnimate-1,
  &.reelAnimate-2 {
    left: -${({ activeIndex }) => 100 * activeIndex}%;
  }

  @media (max-width: ${breakpoint}px) {
    height: 100%;
    border-radius: 0;
  }
`;

export const SlideContainer = styled.div<{
  count: number;
  active: boolean;
}>`
  display: block;
  margin: 0;
  width: calc(100% / ${({ count }) => count});
  transform: scale(${({ active }) => (active ? 0.9 : 1)});
  opacity: ${({ active }) => (active ? 0.85 : 1)};
  box-shadow: 0px 0px 20px 6px rgb(0 0 0 / 40%);
  transition-timing-function: ease-out;
  transition-property: all;

  &.active {
    transform: scale(1);
    opacity: 1;
    transition-delay: ${(
      scaleInOutDuration +
      scaleSlideDelay * 2 +
      slideInDuration
    ).toFixed(1)}s;
    transition-duration: ${scaleInOutDuration}s;
  }

  &.inactive {
    transform: scale(0.9);
    opacity: 0.85;
    transition-delay: 0s;
    transition-duration: ${scaleInOutDuration}s;
  }

  @media (max-width: ${breakpoint}px) {
    width: 100%;
    height: 100%;
  }
`;

const wrapPaddingX = 4;
const wrapPaddingY = 0;
const indicatorDimen = 8;
const indicatorPadding = 4;

export const ItemIndicatorWrap = styled.div<{ count: number }>`
  position: absolute;
  left: ${({ count }) =>
    `calc(50% - ${
      (count * (indicatorDimen + indicatorPadding * 2) + wrapPaddingX * 2) / 2
    }px)`};
  display: flex;
  padding: ${wrapPaddingY}px ${wrapPaddingX}px;
  border-radius: ${wrapPaddingY + indicatorDimen / 2 + indicatorPadding}px;
  background-color: #ffffff;
  bottom: 12px;
  box-shadow: 0 2px 4px 0 rgba(1, 83, 142, 0.2);
`;

export const ItemIndicator = styled.span<{
  active: boolean;
  indicatorClick?: boolean;
}>`
  cursor: ${({ indicatorClick }) => (indicatorClick ? 'pointer' : 'default')};
  position: relative;
  display: block;
  width: ${indicatorDimen + indicatorPadding * 2}px;
  height: ${indicatorDimen + indicatorPadding * 2}px;
  :before {
    content: '';
    display: block;
    position: absolute;
    width: ${indicatorDimen}px;
    height: ${indicatorDimen}px;
    border-radius: 100%;
    background-color: ${({ active }) => (active ? '#0173c6' : '#bfcbdc')};
    top: ${indicatorPadding}px;
    left: ${indicatorPadding}px;
    transition: all 0.8s;
  }
`;

import React, { useEffect, useState } from 'react';
import { ApolloQueryResult } from 'apollo-client';
import AWSAppSyncClient from 'aws-appsync';
import { loader } from 'graphql.macro';
import { useFeatureFlagFunctions } from '../../util/hooks';
import Nav, { TabRoute } from './Navbar';
import getConfig, { Env } from '../../aws_exports';
import {
  GET_PAYMENT_INFO_account_items_payplans_items,
  GET_PAYPLAN_INFO,
  GET_PAYPLAN_INFOVariables,
} from '../../generated/GET_PAYPLAN_INFO';
import AgencyBilledQuantity from '../../sharedTypes/AgencyBilledQuantity';

interface NavbarProps {
  awsAppSyncClient: AWSAppSyncClient<any>;
  accountNumber: string;
}

const getPayPlanInfoQuery = loader(
  '../../graphql/queries/Get_PayPlan_Info.graphql',
);

const config = getConfig(process.env.REACT_APP_ENV as Env);

export function fetchPayPlanInfo(
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
): Promise<ApolloQueryResult<GET_PAYPLAN_INFO>> {
  const variables: GET_PAYPLAN_INFOVariables = {
    account_number: accountNumber,
  } as GET_PAYPLAN_INFOVariables;

  const queryResult: Promise<ApolloQueryResult<GET_PAYPLAN_INFO>> =
    awsAppSyncClient.query({
      query: getPayPlanInfoQuery,
      variables,
    }) as Promise<ApolloQueryResult<GET_PAYPLAN_INFO>>;
  return queryResult;
}

export const agencyBilledPayplansOnly = (
  payplans: (GET_PAYMENT_INFO_account_items_payplans_items | null)[],
): boolean => {
  return !!payplans.every(
    (payplan: GET_PAYMENT_INFO_account_items_payplans_items | null) =>
      payplan?.billing_method === 'Agency Bill',
  );
};

export const containsSomeAgencyBilledPayplans = (
  payplans: (GET_PAYMENT_INFO_account_items_payplans_items | null)[],
): boolean => {
  const someAgencyBilledPayplans = payplans.some(
    (payplan: GET_PAYMENT_INFO_account_items_payplans_items | null) =>
      payplan?.billing_method === 'Agency Bill',
  );

  const agencyBilledOnly = agencyBilledPayplansOnly(payplans);

  return someAgencyBilledPayplans && !agencyBilledOnly;
};

export const processAgencyBilled = (
  payplans: (GET_PAYMENT_INFO_account_items_payplans_items | null)[],
): AgencyBilledQuantity => {
  if (agencyBilledPayplansOnly(payplans)) {
    return AgencyBilledQuantity.All;
  }

  if (containsSomeAgencyBilledPayplans(payplans)) {
    return AgencyBilledQuantity.Partial;
  }

  return AgencyBilledQuantity.None;
};

const DataContainer = ({ awsAppSyncClient, accountNumber }: NavbarProps) => {
  const [isAgencyBilled, setIsAgencyBilled] = useState<boolean>(false);
  const [agencyBilledQuantity, setAgencyBilledQuantity] =
    useState<AgencyBilledQuantity>(AgencyBilledQuantity.None);
  const tabRoutes: TabRoute[] = [
    { label: 'Overview', pathMatch: '/overview' },
    { label: 'Policy', pathMatch: '/policy' },
    { label: 'Billing', pathMatch: '/billing' },
    { label: 'Documents', pathMatch: '/documents' },
    { label: '', pathMatch: '/claims' },
    { label: '', pathMatch: '/report-payroll' },
  ];

  useFeatureFlagFunctions('Claims Hub Redirect', {
    onEnabled: () => {
      tabRoutes[4] = { label: 'Claims', pathMatch: config.claims_portal_url };
    },
    onDisabled: () => {
      tabRoutes[4] = { label: 'Claims', pathMatch: '/claims' };
    },
  });

  if (window.location.pathname === '/report-payroll') {
    tabRoutes[5] = { label: 'Report Payroll', pathMatch: '/report-payroll' };
  }

  useEffect(() => {
    fetchPayPlanInfo(awsAppSyncClient, accountNumber)
      .then((apolloQueryResult: ApolloQueryResult<GET_PAYPLAN_INFO>) => {
        if (
          apolloQueryResult?.data?.account?.items &&
          apolloQueryResult?.data?.account?.items[0]?.payplans?.items
        ) {
          const checkProcessAgencyBilled: AgencyBilledQuantity =
            processAgencyBilled(
              apolloQueryResult.data.account.items[0].payplans.items,
            );
          setAgencyBilledQuantity(checkProcessAgencyBilled);
        }
        if (
          apolloQueryResult?.data &&
          apolloQueryResult?.data?.account &&
          apolloQueryResult.data.account?.items[0] &&
          apolloQueryResult.data.account.items[0]?.policies &&
          apolloQueryResult.data.account.items[0]?.policies?.items
        ) {
          const policyData =
            apolloQueryResult?.data?.account?.items[0]?.policies?.items;
          const selectedPolicies = policyData.filter(
            (policy) =>
              policy?.status !== 'Expired' && policy?.status !== 'Cancelled',
          );
          const payPlansData =
            apolloQueryResult?.data?.account?.items[0]?.payplans?.items;
          const filteredItems = payPlansData?.filter((item) => {
            return selectedPolicies.some(
              (policy) => policy?.policy_number === item?.policy_number,
            );
          });
          const isbillingMethodAB: boolean = (filteredItems || []).every(
            (payplan: any) => payplan.billing_method === 'Agency Bill',
          );
          setIsAgencyBilled(isbillingMethodAB);
        }
      })
      .catch((err: Error) => {
        console.error('GET_PAYPLAN_INFO ERROR: ', err);
      });
  }, [accountNumber]);

  return (
    <Nav
      tabRoutes={tabRoutes}
      isAgencyBilled={isAgencyBilled}
      accountNumber={accountNumber}
      awsAppSyncClient={awsAppSyncClient}
      agencyBilledQuantity={agencyBilledQuantity}
    />
  );
};

export default DataContainer;

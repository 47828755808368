import React, { useEffect, useState } from 'react';
/* eslint-disable consistent-return */
export interface MicroFrontendProps {
  host: string;
  id: string;
  session?: any;
}

declare global {
  interface Window {
    [key: string]: Function;
  }
}

export const MicroFrontend = ({ host, id, session }: MicroFrontendProps) => {
  const renderId = `render${id}`;
  const containerId = `${id}-container`;

  useEffect(() => {
    const script: any = document.getElementById(id);
    if (script?.src && window[renderId]) {
      window[renderId](containerId, session);
    } else if (script) {
      fetch(`${host}/asset-manifest.json`)
        .then((res) => res.json())
        .then((manifest) => {
          script.crossOrigin = '';
          script.src = `${host}${manifest.files['main.js']}`;
          script.onload = () => {
            if (window[renderId]) {
              window[renderId](containerId, session);
            }
          };
        });
    }

    return () => {
      if (window[`unmount${id}`]) window[`unmount${id}`](`${id}-container`);
    };
  }, []);

  return <main id={`${id}-container`} />;
};

export default MicroFrontend;

/* eslint-disable import/prefer-default-export */
import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';
import { useFeatureFlag } from '../../util/hooks';

export const ContactPageWrapper = styled.div`
  max-width: 1050px;

  @media (min-width: 0px) and (max-width: ${customBase.global.breakpoints
      .small}px) {
    align-items: center;
    padding: 0 ${customBase.global.spacers.xxsmall - 2}px
      ${customBase.global.spacers.large}px;
  }
  @media (min-width: ${customBase.global.breakpoints
      .small}px) and (max-width: ${customBase.global.breakpoints.xlarge}px) {
    margin: ${customBase.global.spacers.med_large}px auto 0;
    padding: 0 ${customBase.global.spacers.large}px
      ${customBase.global.spacers.large}px;
  }
  @media (min-width: ${customBase.global.breakpoints.xlarge}px) {
    margin: ${customBase.global.spacers.med_large}px auto 0;
    width: 100%;
  }
`;

export const PhoneLink = styled.a`
  text-decoration: none;
`;

export const StepperHolder = styled.div`
  ${() => {
    const { flagDetails: TempMessageBanner } = useFeatureFlag(
      'TempMessageBanner',
    );
    const { flagDetails: ScheduledMaintenance } = useFeatureFlag(
      'ScheduledMaintenance',
    );
    return `
      background-color: #e8ebf0;
      width: 100vw;
      height: 40px;
      position: absolute;
      top: ${
        (TempMessageBanner && TempMessageBanner.enabled) ||
        (ScheduledMaintenance && ScheduledMaintenance.enabled)
          ? '158px'
          : '98px'
      };
      left: 0;
      padding: 6.5px 0px 0px 25px;
      @media screen and (max-width: 600px) {
        top: ${
          (TempMessageBanner && TempMessageBanner.enabled) ||
          (ScheduledMaintenance && ScheduledMaintenance.enabled)
            ? '170px'
            : '70px'
        };
      }
      `;
  }}
`;

export const RootRouteLink = styled.div``;

export const AgencyNameHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    margin: ${theme.size.spacer.small} 0px ${theme.size.spacer.xl} 0px;
  `}
`;

export const ClaimsNameHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    margin: ${theme.size.spacer.small} 0px 97px 0px;
  `}
`;

export const CustomerServiceNameHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    margin: ${theme.size.spacer.small} 0px 32px 0px;
  `}
`;

export const CustomerServicePhoneHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 32px;
`;

export const PhoneHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const IconSpacer = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    width: ${theme.size.spacer.small};
  `}
`;

export const MailAdjust = styled.div`
  margin-top: 8px;
`;

export const EmailHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: ${theme.size.spacer.small};
    word-wrap: break-word;
  `}
`;

export const InnerCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const LoadingErrHolder = styled.div`
  padding: 8px;
`;

export const Page = styled.div`
  width: 100%;
`;

export const ContactCollection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
  @media screen and (max-width: 980px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const GreenContactCards = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    background: #ffffff;
    border: 1px solid #d9dfeb;
    border-top: ${theme.size.spacer.xs} solid #65a100;
    box-sizing: border-box;
    box-shadow: 0px ${theme.size.spacer.xxs} ${theme.size.spacer.xs} rgba(1, 83, 142, 0.2);
    border-radius: ${theme.size.spacer.xs};
    max-width: 340px;
    min-width: 300px;
    padding: 20px;
    margin-bottom: 20px;
    @media screen and (max-width: 1140px) {
      min-width: 250px;
    }
    @media screen and (max-width: 980px) {
      min-width: 100%;
    }
  `}
`;

export const BlueContactCards = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    background: #ffffff;
    border: 1px solid #d9dfeb;
    border-top: ${theme.size.spacer.xs} solid #0173c6;
    box-sizing: border-box;
    box-shadow: 0px ${theme.size.spacer.xxs} ${theme.size.spacer.xs} rgba(1, 83, 142, 0.2);
    border-radius: ${theme.size.spacer.xs};
    max-width: 340px;
    min-width: 300px;
    padding: 20px;
    margin-bottom: 20px;
    @media screen and (max-width: 1140px) {
      min-width: 250px;
    }
    @media screen and (max-width: 980px) {
      min-width: 100%;
    }
  `}
`;

export const HeadingInnerText = styled.div`
  margin-top: 7px;
`;

export const Heading = styled.div`
  margin-top: 40px;
`;

export const InnerPage = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

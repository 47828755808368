import { navigate } from '@reach/router';
import {
  ColumnSizes,
  SAButton,
  SACard,
  SAColumns,
  SAIcon,
  SAIcons,
  SAIconSize,
  SASnackbar,
  SAStack,
  SAStep,
  SAStepper,
  SAText,
  SAUX360Theme,
} from '@saux/design-system-react';
import React, { useMemo, useState } from 'react';
import { formatPhone } from '../../util';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import { useFeatureFlag } from '../../util/hooks';
import useIsResponsive from '../../util/useIsResponsive';
import { RootRouteLink, StepperHolder } from '../contactPage/ContactPageStyles';
import {
  AccountDetailsWrapper,
  CancelSaveWrapper,
  EditButtonWrapper,
  PasswordSpacer,
  ProfileWrapper,
} from './ProfilePageStyles';
import ProfilePageRow, { validateEmail, validatePhone } from './ProfilePageRow';
import { ReactComponent as SpinnerLight } from '../../assets/images/Spinner-Light.svg';
import ProfilePagePasswordRow from './ProfilePagePasswordRow';
import { ProfilePageState } from './ProfileState';
import OutageModal from '../outageModal/OutageModal';

interface ProfilePageProps {
  state: ProfilePageState;
  dispatch: Function;
  displayName: React.ReactNode;
  userId: string;
  oldPassword: string | undefined;
  newPassword: string | undefined;
  confirmPassword: string | undefined;
  setOldPassword: Function;
  setNewPassword: Function;
  setConfirmPassword: Function;
  passwordIsLongEnough: boolean;
  passwordHasCapital: boolean;
  passwordHasNumber: boolean;
  passwordHasLowercase: boolean;
  passwordHasSpecialChar: boolean;
  passwordError: boolean;
  passwordsDoNotMatch: boolean;
  setPasswordsDoNotMatch: Function;
  setPasswordError: Function;
  cancelClickHandler: (dispatch: Function) => void;
  errorField: string;
}

const ProfilePage = ({
  state,
  dispatch,
  displayName,
  userId,
  cancelClickHandler,
  oldPassword,
  newPassword,
  confirmPassword,
  setOldPassword,
  setNewPassword,
  setConfirmPassword,
  passwordIsLongEnough,
  passwordHasCapital,
  passwordHasNumber,
  passwordHasLowercase,
  passwordHasSpecialChar,
  passwordError,
  passwordsDoNotMatch,
  setPasswordsDoNotMatch,
  setPasswordError,
  errorField,
}: ProfilePageProps) => {
  const { flagDetails } = useFeatureFlag('Profile Page Edit');
  const { flagDetails: EditPasswordProfilePageFlag } = useFeatureFlag(
    'EditPasswordProfilePage',
  );
  const [showOutageModal, setShowOutageModal] = useState<boolean>(false);
  const { flagDetails: PolicyCenterOutage } = useFeatureFlag(
    'PolicyCenterOutage',
  );
  const [activeStep, setActiveStep] = useState<number>(0);
  const isResponsive = useIsResponsive();

  const steps: string[] = ['Dashboard', 'Profile'];
  const connector = (
    <SAIcon
      icon={SAIcons.chevronRightBold}
      size={SAIconSize.small}
      colorVariant={SAUX360Theme.color.dark.background}
    />
  );

  const columns: ColumnSizes[] = useMemo(() => {
    if (state.editPassword) {
      return [12, 0] as ColumnSizes[];
    }
    return [8, 4] as ColumnSizes[];
  }, [state.editPassword]);

  function handleClick(e: any) {
    if (e.target.innerText === 'Dashboard') {
      setActiveStep(0);
      navigate('/overview');
    }
  }

  const snackbars = () => {
    if (isResponsive) {
      return (
        <>
          <SASnackbar
            open={state.displaySuccessSnackbar}
            closeable
            onClose={() =>
              dispatch({ type: 'displaySuccessSnackbar', data: false })
            }
            offset={[0, 98]}
            autoHideDuration={9000}
            variant="snackbar-success"
            icon={SAIcons.checkmark}
            position="bottom"
            title="Your contact information was updated successfully."
            zIndex={1}
          />
          <SASnackbar
            open={state.displayErrorSnackbar}
            closeable
            onClose={() =>
              dispatch({ type: 'displayErrorSnackbar', data: false })
            }
            offset={[0, 98]}
            autoHideDuration={9000}
            variant="snackbar-error"
            icon={SAIcons.error}
            position="bottom"
            title={`${errorField} could not be saved.`}
            zIndex={1}
          />
        </>
      );
    }

    return (
      <>
        <SASnackbar
          open={state.displaySuccessSnackbar}
          closeable
          onClose={() =>
            dispatch({ type: 'displaySuccessSnackbar', data: false })
          }
          offset={[0, 98]}
          position="top"
          autoHideDuration={9000}
          variant="snackbar-success"
          icon={SAIcons.checkmark}
          title="Your contact information was updated successfully."
        />
        <SASnackbar
          open={state.displayErrorSnackbar}
          closeable
          onClose={() =>
            dispatch({ type: 'displayErrorSnackbar', data: false })
          }
          offset={[0, 98]}
          position="top"
          autoHideDuration={9000}
          variant="snackbar-error"
          icon={SAIcons.error}
          title={`${errorField} could not be saved.`}
        />
      </>
    );
  };

  if (state.loading) {
    return <SAText type="standard" text="Loading..." />;
  }

  if (state.error) {
    return <SAText type="standard" text="Error" />;
  }

  return (
    <>
      {showOutageModal && (
        <OutageModal
          component="Edit Contact Information"
          setModal={setShowOutageModal}
        />
      )}
      <StepperHolder>
        <SAStepper activeStep={activeStep} connector={connector} hideNumbers>
          {steps.map((label, index) => {
            if (index === 0) {
              return (
                <SAStep key={label} index={index}>
                  <RootRouteLink
                    onMouseOver={(e) => {
                      const target = e.target as HTMLTextAreaElement;
                      target.style.cursor = 'pointer';
                    }}
                    onClick={(e) => handleClick(e)}
                  >
                    <SAText text={label} type="paragraph" weight="bold" />
                  </RootRouteLink>
                </SAStep>
              );
            }
            return (
              <SAStep key={label} index={index}>
                <SAText text={label} type="paragraph" />
              </SAStep>
            );
          })}
        </SAStepper>
      </StepperHolder>
      {snackbars()}
      <ProfileWrapper>
        {displayName}
        <AccountDetailsWrapper>
          <SACard title="Account Details" variant="standard">
            <SAColumns columns={[8, 4]} spacing="medium">
              <SAStack spacing="small">
                <ProfilePageRow
                  id="profile-page-user-id"
                  state={state}
                  header="Username"
                  editLabel=""
                  value={userId}
                  editing={state.editProfile}
                />
                {state.updatedEmail.valid ? (
                  <ProfilePageRow
                    id="profile-page-email-input"
                    state={state}
                    header="Email"
                    editLabel="Email ID"
                    value={state.updatedEmail.address}
                    editing={state.editProfile}
                    onInputChange={(data: any) => {
                      dispatch({
                        type: 'setValidUpdatedEmail',
                        data: validateEmail(data),
                      });
                      dispatch({ type: 'setUpdatedEmail', data: data });
                    }}
                  />
                ) : (
                  <ProfilePageRow
                    id="profile-page-email-input"
                    state={state}
                    inputHint="Invalid Email Address"
                    header="Email"
                    editLabel="Email ID"
                    editing={state.editProfile}
                    value={state.updatedEmail.address}
                    onInputChange={(data: any) => {
                      dispatch({
                        type: 'setValidUpdatedEmail',
                        data: validateEmail(data),
                      });
                      dispatch({ type: 'setUpdatedEmail', data: data });
                    }}
                  />
                )}
                {state.updatedPrimaryPhone.validNumber ? (
                  <ProfilePageRow
                    id="profile-page-primary-phone-input"
                    state={state}
                    header="Primary Phone"
                    editLabel="Primary Phone Number"
                    editing={state.editProfile}
                    value={state.updatedPrimaryPhone}
                    valueFormatter={formatPhone}
                    hasOptions
                    onInputChange={(data: string) => {
                      dispatch({
                        type: 'setUpdatedPrimaryPhone',
                        data: {
                          ...state.updatedPrimaryPhone,
                          number: data,
                          validNumber: validatePhone(data),
                        },
                      });
                    }}
                    onSelectChange={(data: any) =>
                      dispatch({
                        type: 'setUpdatedPrimaryPhone',
                        data: {
                          ...state.updatedPrimaryPhone,
                          type: data.type,
                          validType: data.validType,
                        },
                      })
                    }
                  />
                ) : (
                  <ProfilePageRow
                    id="profile-page-primary-phone-input"
                    state={state}
                    inputHint="Invalid Phone Number"
                    header="Primary Phone"
                    editLabel="Primary Phone Number"
                    editing={state.editProfile}
                    value={state.updatedPrimaryPhone}
                    valueFormatter={formatPhone}
                    hasOptions
                    onInputChange={(data: string) => {
                      dispatch({
                        type: 'setUpdatedPrimaryPhone',
                        data: {
                          ...state.updatedPrimaryPhone,
                          number: data,
                          validNumber: validatePhone(data),
                        },
                      });
                    }}
                    onSelectChange={(data: any) =>
                      dispatch({
                        type: 'setUpdatedPrimaryPhone',
                        data: {
                          ...state.updatedPrimaryPhone,
                          type: data.type,
                          validType: data.validType,
                        },
                      })
                    }
                  />
                )}
              </SAStack>
              {!state.editPassword && !state.editProfile && (
                <EditButtonWrapper>
                  <SAButton
                    label={
                      flagDetails?.enabled &&
                      !sessionStorage.getItem('userLOB')?.includes('Commercial')
                        ? 'Edit'
                        : ''
                    }
                    variant="secondary-small-outline"
                    onClick={
                      flagDetails?.enabled &&
                      !sessionStorage.getItem('userLOB')?.includes('Commercial')
                        ? () => {
                            if (
                              PolicyCenterOutage &&
                              PolicyCenterOutage.enabled
                            ) {
                              setShowOutageModal(true);
                            } else {
                              dispatch({ type: 'setEditProfile', data: true });
                            }
                            simpleGTMDataLayer({
                              event: `ProfileEdit-click`,
                              event_action:
                                'Profile Page Edit Details Button Click',
                              event_category: 'Profile',
                              event_label:
                                'Profile Page Edit Details Button Click',
                            });
                            simpleGTMDataLayer({
                              event: `Snowplow-ProfileEdit-click`,
                              event_action:
                                'Snowplow Profile Page Edit Details Button Click',
                              event_category: 'Snowplow Profile',
                              event_label:
                                'Snowplow Profile Page Edit Details Button Click',
                            });
                          }
                        : () => {}
                    }
                  />
                </EditButtonWrapper>
              )}
            </SAColumns>

            {EditPasswordProfilePageFlag?.enabled && (
              <>
                <PasswordSpacer />
                <ProfilePagePasswordRow
                  edit={state.editPassword}
                  oldPassword={oldPassword}
                  confirmPassword={confirmPassword}
                  setOldPassword={setOldPassword}
                  setNewPassword={setNewPassword}
                  setConfirmPassword={setConfirmPassword}
                  passwordIsLongEnough={passwordIsLongEnough}
                  passwordHasCapital={passwordHasCapital}
                  passwordHasNumber={passwordHasNumber}
                  passwordHasLowercase={passwordHasLowercase}
                  passwordHasSpecialChar={passwordHasSpecialChar}
                  passwordError={passwordError}
                  passwordsDoNotMatch={passwordsDoNotMatch}
                  setPasswordError={setPasswordError}
                  setPasswordsDoNotMatch={setPasswordsDoNotMatch}
                />
                {!state.editPassword && !state.editProfile && (
                  <EditButtonWrapper>
                    <SAButton
                      label={
                        flagDetails?.enabled &&
                        !sessionStorage
                          .getItem('userLOB')
                          ?.includes('Commercial')
                          ? 'Edit'
                          : ''
                      }
                      variant="secondary-small-outline"
                      onClick={
                        flagDetails?.enabled &&
                        !sessionStorage
                          .getItem('userLOB')
                          ?.includes('Commercial')
                          ? () => {
                              if (
                                PolicyCenterOutage &&
                                PolicyCenterOutage.enabled
                              ) {
                                setShowOutageModal(true);
                              } else {
                                dispatch({
                                  type: 'setEditPassword',
                                  data: true,
                                });
                              }
                              simpleGTMDataLayer({
                                event: `ProfileEdit-click`,
                                event_action:
                                  'Profile Page Edit Details Button Click',
                                event_category: 'Profile',
                                event_label:
                                  'Profile Page Edit Details Button Click',
                              });
                              simpleGTMDataLayer({
                                event: `Snowplow-ProfileEdit-click`,
                                event_action:
                                  'Snowplow Profile Page Edit Details Button Click',
                                event_category: 'Snowplow Profile',
                                event_label:
                                  'Snowplow Profile Page Edit Details Button Click',
                              });
                            }
                          : () => {}
                      }
                    />
                  </EditButtonWrapper>
                )}
              </>
            )}
            {(state.editProfile || state.editPassword) && (
              <CancelSaveWrapper>
                <SAButton
                  id="profile-page-cancel-button"
                  variant="link-medium"
                  label="CANCEL"
                  onClick={() => {
                    cancelClickHandler(dispatch);
                    simpleGTMDataLayer({
                      event: `ProfileEditCancel-click`,
                      event_action:
                        'Profile Page Cancel Edit Details Button Click',
                      event_category: 'Profile',
                      event_label:
                        'Profile Page Cancel Edit Details Button Click',
                    });
                    simpleGTMDataLayer({
                      event: `Snowplow-ProfileEditCancel-click`,
                      event_action:
                        'Snowplow Profile Page Cancel Edit Details Button Click',
                      event_category: 'Snowplow Profile',
                      event_label:
                        'Snowplow Profile Page Cancel Edit Details Button Click',
                    });
                  }}
                  test-attr="profile-page-cancel-button"
                />
                {state.saveEnabled ? (
                  <>
                    {state.saveCustomerInfo ? (
                      <SAButton label="SAVE CHANGES" variant="primary-medium">
                        SAVING...&nbsp;&nbsp;
                        <SpinnerLight />
                      </SAButton>
                    ) : (
                      <SAButton
                        variant="primary-medium"
                        test-attr="profile-page-active-save-button"
                        label="SAVE CHANGES"
                        onClick={() => {
                          if (
                            newPassword &&
                            confirmPassword &&
                            newPassword !== confirmPassword
                          ) {
                            setPasswordsDoNotMatch(true);
                          } else {
                            dispatch({ type: 'saveCustomerInfo', data: true });
                            simpleGTMDataLayer({
                              event: 'ProfileEditSave-click',
                              event_action:
                                'Profile Page Save Edit Details Button Click',
                              event_category: 'Profile',
                              event_label:
                                'Profile Page Save Edit Details Button Click',
                            });
                            simpleGTMDataLayer({
                              event: 'Snowplow-ProfileEditSave-click',
                              event_action:
                                'Snowplow Profile Page Save Edit Details Button Click',
                              event_category: 'Snowplow Profile',
                              event_label:
                                'Snowplow Profile Page Save Edit Details Button Click',
                            });
                          }
                        }}
                      />
                    )}
                  </>
                ) : (
                  <SAButton
                    variant="primary-medium"
                    test-attr="profile-page-active-save-button"
                    label="SAVE CHANGES"
                    disabled
                  />
                )}
              </CancelSaveWrapper>
            )}
          </SACard>
        </AccountDetailsWrapper>
      </ProfileWrapper>
    </>
  );
};

export default ProfilePage;

import React, { useState } from 'react';
import { navigate } from '@reach/router';
import {
  SAHeader,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAText,
} from '@saux/design-system-react';
import styled from 'styled-components';
import { ReactComponent as SearchIcon } from '../../assets/images/SearchIcon.svg';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import { useFeatureFlag } from '../../util/hooks';
import useIsResponsive from '../../util/useIsResponsive';
import AccountSlideOut from '../accountSlideOut/AccountSlideOut';
import MenuIcon from '../menuIcon/MenuIcon';
import {
  BarHolder,
  ErrorBanner,
  HeaderHolder,
  IconHolder,
  Input,
  MenuHolder,
  TextHolder,
  TopText,
} from './SearchBarStyles';

interface SearchBarProps {
  signOut: Function;
  isSuperUser: boolean;
  displayName: string;
}

interface ColorWrapperProps {
  featureFlag: any;
}

export const ProcessMainText = (flagDetails: any): string => {
  if (
    flagDetails &&
    flagDetails.enabled &&
    flagDetails.value &&
    flagDetails.value.bannerMessageMain
  ) {
    if (window.screen.availWidth < 285) {
      return flagDetails.value.bannerMessageMain.length > 50
        ? `${flagDetails.value.bannerMessageMain.substring(0, 50)}...`
        : flagDetails.value.bannerMessageMain;
    }
    return flagDetails.value.bannerMessageMain;
  }
  return 'We are not able to update the content due to server error.';
};

export const ProcessSubText = (
  flagDetails: any,
  isResponsive: boolean,
): string => {
  if (
    flagDetails &&
    flagDetails.enabled &&
    flagDetails.value &&
    flagDetails.value.bannerMessageSecondary
  ) {
    if (isResponsive) {
      return flagDetails.value.bannerMessageSecondary.length > 90
        ? `${flagDetails.value.bannerMessageSecondary.substring(0, 90)}...`
        : flagDetails.value.bannerMessageSecondary;
    }
    return flagDetails.value.bannerMessageSecondary;
  }
  return 'Try again after some time.';
};

const ColorWrapper = styled('div')<ColorWrapperProps>`
  span {
    color: ${({ featureFlag }) =>
      featureFlag?.enabled ? '#7A5212' : '#FFFFFF'};
  }
`;

export const SearchBar = ({
  signOut,
  isSuperUser,
  displayName,
}: SearchBarProps) => {
  const [sideModal, showSideModal] = useState<boolean>(false);
  const numbersRegex: RegExp = /^[0-9\b]+(C?)$/;
  const { flagDetails: TempMessageBanner } = useFeatureFlag(
    'TempMessageBanner',
  );
  const { flagDetails: ScheduledMaintenance } = useFeatureFlag(
    'ScheduledMaintenance',
  );

  const isResponsive = useIsResponsive();

  const onChange = (event: object | any) => {
    if (
      event?.target?.value?.length === 10 &&
      numbersRegex.test(event?.target?.value)
    ) {
      sessionStorage.removeItem('accountNumber');
      sessionStorage.removeItem('policyNumber');
      sessionStorage.setItem('accountNumber', `${event?.target?.value}`);
    }
  };

  const onSubmit = () => {
    window.location.reload();
  };

  const avatarIconOnClick = () => {
    simpleGTMDataLayer({
      event: 'avatarIcon-click',
      event_action: 'Avatar Icon Click',
      event_category: 'Avatar Icon',
      event_label: 'Avatar Icon',
    });

    simpleGTMDataLayer({
      event: 'Snowplow-Avatar-Icon-click',
      event_action: 'Snowplow Avatar Icon Click',
      event_category: 'Snowplow Avatar Icon',
      event_label: 'Snowplow Avatar Icon',
    });
  };

  const logOutOnClick = () => {
    signOut();

    simpleGTMDataLayer({
      event: 'Log-Out-click',
      event_action: 'Log Out Click',
      event_category: 'Log Out',
      event_label: 'Log Out',
    });

    simpleGTMDataLayer({
      event: 'Snowplow-Log-Out-click',
      event_action: 'Snowplow Log Out Click',
      event_category: 'Snowplow Log Out',
      event_label: 'Snowplow Log Out',
    });
  };

  const enterPress = (event: object | any) => {
    const alertMessage = () => alert('Make sure this a valid account number');
    if (numbersRegex.test(event?.target?.value)) {
      const eventLength = event.target.value.length;
      const isValidAccountNumber = eventLength === 10 || eventLength === 11;
      return isValidAccountNumber ? onSubmit() : alertMessage();
    }
    return alertMessage();
  };

  return (
    <>
      {((TempMessageBanner && TempMessageBanner.enabled) ||
        (ScheduledMaintenance && ScheduledMaintenance.enabled)) && (
        <ErrorBanner
          style={{
            backgroundColor: ScheduledMaintenance?.enabled
              ? '#FFF6E9'
              : '#CC1D33',
          }}
        >
          <IconHolder>
            <SAIcon
              icon={SAIcons.alert}
              size={SAIconSize.medium}
              colorVariant={
                ScheduledMaintenance?.enabled ? 'tertiary' : 'light'
              }
            />
          </IconHolder>
          <TextHolder>
            <TopText>
              <ColorWrapper featureFlag={ScheduledMaintenance}>
                <SAText
                  type="standard"
                  weight="bold"
                  text={ProcessMainText(
                    ScheduledMaintenance?.enabled
                      ? ScheduledMaintenance
                      : TempMessageBanner,
                  )}
                />
              </ColorWrapper>
            </TopText>
            <ColorWrapper featureFlag={ScheduledMaintenance}>
              <SAText
                type="standard"
                weight="normal"
                text={ProcessSubText(
                  ScheduledMaintenance?.enabled
                    ? ScheduledMaintenance
                    : TempMessageBanner,
                  isResponsive,
                )}
              />
            </ColorWrapper>
          </TextHolder>
        </ErrorBanner>
      )}
      {sideModal && (
        <AccountSlideOut
          showSideModal={showSideModal}
          logOutOnClick={logOutOnClick}
          displayName={displayName}
        />
      )}
      <HeaderHolder>
        <SAHeader
          onClick={() => {
            navigate('/overview');
          }}
        >
          {isSuperUser && (
            <BarHolder>
              <Input
                onChange={onChange}
                placeholder="Account Number"
                onKeyPress={(event: object | any) => {
                  if (event.key === 'Enter') {
                    enterPress(event);
                  }
                }}
              />
              <SearchIcon
                onClick={() => onSubmit()}
                style={{ paddingRight: '10px' }}
                title="Search"
                width="30px"
                height="auto"
              />
            </BarHolder>
          )}
          <MenuHolder
            onClick={() => {
              showSideModal(true);
              avatarIconOnClick();
            }}
          >
            <MenuIcon displayName={displayName} />
          </MenuHolder>
        </SAHeader>
      </HeaderHolder>
    </>
  );
};

import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

export const EmptyState = styled.div``;

// z-index of 2147483601 is one unit above the freshchat icon
export const SideModal = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    @keyframes slideInFromRight {
      0% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(0);
      }
    }

    background-color: #fff;
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 2147483601;
    border-radius: 4px 0px 0px 4px;
    box-shadow: 0px 2px 4px 0px hsla(205, 99%, 28%, 0.2);
    box-shadow: 0px ${theme?.size?.radius?.medium} 32px -${theme?.size?.radius?.medium} #002038;
    animation: 0.5s ease 0s 1 slideInFromRight;

    @media (max-width: ${customBase.global.breakpoints.medium}px) {
      width: 100%;
    }
  `}
`;

export const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  @media (max-width: ${customBase.global.breakpoints.small}px) {
    width: 0%;
    height: 0%;
  }
`;

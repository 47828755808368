import styled from 'styled-components';

export const PayrollMainWrapper = styled.div`
  margin-top: 30px;
  padding: 0px;
  box-sizing: border-box;
`;

export const HeaderTabWrappper = styled.div`
  background-color: rgb(255, 255, 255);
  color: rgb(59, 59, 59);
  border-radius: 4px 4px 0px 0px;
  // box-shadow: rgba(1, 83, 142, 0.2) 0px 2px 4px 0px;
  display: flex;
  flex-flow: column;
  overflow: hidden;
`;

export const HeaderTab = styled.div`
  background: rgb(1, 116, 198);
  display: flex;
  height: 50px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0px 10px 0px 10px;
`;

export const InstructionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const InstructionDiv = styled.div`
  padding: 7px;
  display: flex;
  color: #666666;
`;

export const InstructionPDF = styled.div`
  text-transform: uppercase;
  cursor: pointer;
  color: rgb(1, 116, 198);
`;

export const InstructionVideo = styled.div`
  cursor: pointer;
  text-transform: uppercase;
  color: rgb(1, 116, 198);
`;

export const PayrollReportTab = styled.div`
  width: 50%;
  cursor: pointer;
`;

export const ActiveTab = styled.div`
  background: #fff;
  cursor: pointer;
  border-radius: 2px 2px 0px 0px;
`;

export const PayrollTextContainer = styled.div`
  text-align: center;
  height: 100%;
`;

export const TextStyling = styled.h2`
  color: rgb(255, 255, 255);
  padding-top: 10px;
`;

export const TextStylingA = styled.h2`
  color: #000;
  padding-top: 10px;
`;

export const TextStylingD = styled.div`
  color: rgb(255, 255, 255);
  padding-top: 10px;
  font-weight: 600;
`;

export const PriorReportTab = styled.div`
  background: rgb(1, 116, 198);
  width: 50%;
  cursor: pointer;
`;

export const PariorContainer = styled.div`
  border: 1px solid magenta;
  background: #fff;
`;

export const PayrollBlock = styled.div`
  display: flex;
  // height: 40px;
  padding: 0px 743px 7px 13px;
  align-items: center;
  flex-shrink: 0;
  background: rgb(1, 116, 198);
  justify-content: space-between;
  margin-bottom: 20px;
  border-radius: 2px;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

export const PayrollMainWrapper = styled.div`
  margin: 0px;
  padding: 10px;
  box-sizing: border-box;
  // background: #fff;
  background: white;
`;

export const PayrollWrapper = styled.div`
  // border: 1px;
  // margin-left: 20px;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  padding: 25px;
`;

export const SubmitPayrollError = styled.div`
  display: flex;
  background: #f7e5e8;
  color: #de350a;
  border: 1px solid #de350a;
  border-left: 4px solid #de350a;
  border-radius: 4px;
  // box-shadow: 0 2px 4px 0 hsla(205, 99%, 28%, 0.2);
  align-items: center;
  padding: 15px;
  margin: 10px;
`;

export const PayrollMessage = styled.div`
  width: 647px;
  height: 72px;
  top: 404px;
  left: 312px;
  margin-left: 20px;
  margin-top: 15px;
  // font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  display: contents;
`;

export const ResponsiveButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 3vh;
  // justify-content: space-between;
  // width: 30%;
  // margin: 30px;
  // margin-left: 20px;
  // margin-top: 10px;
  // margin-bottom: 10px;
`;

export const MakePaymentButtonWrapper = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    display: flex;
    justify-content: left;
    @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
      width: auto;
      margin-right: ${theme.size.spacer.small};
    }
`}
`;
// export const Border = styled.div`
//   border-bottom: 1px solid transparent;
// `;

export const Border = styled.span`
  margin-left: 0px;
  margin-right: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const SummaryBlock = styled.div`
  display: flex;
  height: 54px;
  padding: 15px 13px 17px 13px;
  align-items: center;
  flex-shrink: 0;
  background: var(--grays-100-gray-blue, #e3e9f1);
  justify-content: space-between;
  margin-bottom: 10px;
  border-radius: 2px;
`;

export const LocationNumWrapper = styled.div`
  border-left-style: solid;
  border-color: hsl(87, 85%, 28%);
  border-width: 5px;
  padding-left: 10px;
  margin: 15px;
  border-radius: 2px;
`;

export const LocationText = styled.div`
  font-style: bold;
`;

export const EmpFieldsBlock = styled.div`
  padding: 5px;
  margin-top: 30px;
  margin-bottom: 30px;
  // display: contents;
`;

export const CovEmpWrapper = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
  display: flex;
  justify-content: left;
  width: auto;
  margin: ${theme.size.spacer.small};
}
`}
`;

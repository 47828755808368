import styled from 'styled-components';

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  width: 656px;
  height: 474px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #979797;
  background: #fff;
  box-shadow: 0px 8px 32px -8px rgba(0, 32, 56, 0.33);
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const AboveContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
`;

export const ModalButton = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

export const DynamicIcon = styled.div`
  font-size: 50px;
`;

export const DynamicText = styled.div`
  color: var(--text-primary-text-black, #3b3b3b);
  font-family: Lato;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 15px 0px;
`;

export const TextWrapper = styled.div`
  display: flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #e1f5ff;
`;

export const TextStyle = styled.div`
  color: var(--primary-primary-state-auto-blue, #0173c6);
  text-align: center;
  font-family: Lato;
  font-size: 13.33px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const InfoText = styled.div`
  width: 80%;
  color: var(--text-primary-text-black, #3b3b3b);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const CloseButton = styled.div`
  padding: 13px 25px 14px 25px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  margin-right: 10px;
`;

export const CloseText = styled.div`
  font-size: 20px;
  line-height: normal;
  cursor: pointer;
  text-transform: uppercase;
  color: var(--text-primary-text-black, #3b3b3b);
  text-align: center;
  font-style: normal;
`;

export const ConfirmButton = styled.div`
  display: flex;
  padding: 13px 25px 14px 25px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  cursor: pointer;
  background: var(--primary-action-green, #4d840b);
`;

export const ConfirmText = styled.div`
  color: var(--backgrounds-white, #fff);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

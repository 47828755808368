import styled from 'styled-components';
import customBase from '../../util/global.theme';

export const UserInfoHolder = styled.div``;

export const CloseHolder = styled.div`
  padding-bottom: 27px;
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
    padding-bottom: 0px;
  }
`;

export const IconNameCloseHolder = styled.div`
  background-color: #e8ebf0;
  height: 140px;
  width: 100%;
  margin: 0 auto;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Name = styled.p`
  font-family: PT Sans;
  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 31px;
  letter-spacing: 1px;
  color: #3b3b3b;
  overflow-wrap: break-word;
  @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
    overflow-wrap: normal;
    font-size: 24px;
  }
`;

export const MenuInitialsIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  min-height: 60px;
  background: #3e5572;
  border: 2px solid #e1f5ff;
  border-radius: 50%;
  box-sizing: border-box;
`;

export const UserName = styled.div`
  padding: 0px 25px 0px 25px;
  max-width: 200px;

  @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
    max-width: none;
  }
`;

export const LinkHolder = styled.div`
  padding: 0px 0px 0px 25px;
`;

export const ContactUsLinkHolder = styled.div`
  padding: 0px 0px 0px 25px;
`;

export const MenuOptionSpacer = styled.div`
  height: 25px;
  width: 100%;
`;

export const MenuOption = styled.div`
  height: 40px;
  width: 100%;
  margin: 0 auto;
  padding: 30px 30px 30px 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  *:hover {
    cursor: pointer;
  }
`;

import styled from 'styled-components';

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 30px;
  width: 100%;
  gap: 10px;
  background-color: #f5a524;
`;

export const CloseButton = styled.div`
  display: block;
  cursor: pointer;
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const TextWapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ButtonWapper = styled.div`
  align-items: center;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
`;

export const ButtonTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background: #ffffff;
  border-radius: 5px;
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
  height: 40px;
  padding: 0px 15px;
`;

export const ButtonText = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 23px;
  text-align: center;
  color: #3b3b3b;
  flex: none;
  order: 2;
  flex-grow: 0;
  cursor: pointer;
`;

export const MainHeadingText = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #3b3b3b;
`;

export const DetailText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #3b3b3b;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const SentAlertWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const AlertStyle = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 12px 10px 10px;
  gap: 12px;
  height: 53px;
  background: #edf2e6;
  border: 1px solid #c9dav5;
  box-shadow: 0px 2px 5px rgba(1, 83, 142, 0.2);
  border-radius: 4px;
`;

export const AlertStyleText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #264205;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

export const LeftBorder = styled.div`
  width: 5px;
  background: #4d840b;
  border-radius: 4px 0px 0px 4px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

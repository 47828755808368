import React, { useState, useEffect } from 'react';
import AWSAppSyncClient from 'aws-appsync';
import { loader } from 'graphql.macro';
import { ApolloQueryResult } from 'apollo-client';
import styled, { css } from 'styled-components';
import { Wrapper, Text } from './PriorReportsStyles';
import PriorReports from './PriorReports';
import {
  PriorReport,
  PriorReportDetails,
  PriorReportVariable,
} from './graphql/GET_PRIOR_AUDIT_REPORT_DETAILS';
import Spinning from '../ReportPayroll/Spinner';
import { ReactComponent as Info } from '../../assets/images/Info.svg';
import { formatDatePayg } from '../../util';

const getPriorReportPayrollDetails = loader(
  './graphql/Get_Prior_Audit_Report_Details.graphql',
);

interface PriorReportsContainerProps {
  awsAppSyncClient: AWSAppSyncClient<any>;
  accountNumber: string;
}

export const fetchPriorReportDetails = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  username: String,
): Promise<ApolloQueryResult<PriorReport>> => {
  const variables: PriorReportVariable = {
    username,
  } as PriorReportVariable;
  const queryResult: Promise<
    ApolloQueryResult<PriorReport>
  > = awsAppSyncClient.query({
    query: getPriorReportPayrollDetails,
    variables,
  }) as Promise<ApolloQueryResult<PriorReport>>;
  return queryResult;
};

const getObjectByPolicyNumber = (
  policyNumber: string,
  priorReportDetails: PriorReport[],
) => {
  return priorReportDetails.find((item) => item.policyNumber === policyNumber);
};

export default ({
  awsAppSyncClient,
  accountNumber,
}: PriorReportsContainerProps) => {
  const eidUserName = sessionStorage.getItem('EIDuserName_PC') ?? '';
  const [loaded, setLoaded] = useState<boolean>(false);
  const [policyNumberArray, setPolicyNumberArray] = useState<[] | string[]>([]);
  const [selectedPolicy, setSelectedPolicy] = useState<string>('');
  const [selectedPriorReport, setSelectedPriorReport] = useState<
    PriorReportDetails | {}
  >({});
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [isPriorReportEmpty, setPriorReportEmpty] = useState<Boolean>(false);
  const [reviewDateArray, setReviewDateArray] = useState<string[] | []>([]);
  const [priorReportDetails, setPriorReportDetails] = useState<PriorReport[]>([
    {
      policyNumber: '',
      priorReports: [],
    },
  ]);
  const [priorReportLoading, setPriorReportLoading] = useState<Boolean>(false);

  useEffect(() => {
    setLoaded(false);
    setPriorReportLoading(true);
    try {
      fetchPriorReportDetails(awsAppSyncClient, eidUserName || '')
        .then((result: ApolloQueryResult<any>) => {
          if (
            result &&
            result.data &&
            result.data.getPriorAuditReportDetails &&
            result.data.getPriorAuditReportDetails.result
          ) {
            const data: PriorReport =
              result.data.getPriorAuditReportDetails.result[0];
            if (data.policyNumber === null) {
              setPriorReportEmpty(true);
            } else {
              const response: PriorReport[] =
                result.data.getPriorAuditReportDetails.result;
              const uniquePolicyNumbers = Array.from(
                new Set(response.map((item) => item.policyNumber)),
              );
              setPolicyNumberArray(uniquePolicyNumbers);
              setPriorReportDetails(response);
              setLoaded(true);
            }
          } else {
            setPriorReportEmpty(true);
          }
          setPriorReportLoading(false);
        })
        .catch((err: Error) => {
          console.error('ERROR WHILE FETCHING API: ', err);
          setPriorReportLoading(false);
        });
    } catch (err) {
      console.error('ERROR: ', err);
      setPriorReportLoading(false);
    }
  }, [eidUserName]);

  const getEffectiveDate = (selectedReport: PriorReportDetails) => {
    if (selectedReport) {
      const effStartDate = formatDatePayg(selectedReport.policyEffectiveDate);
      const effEndDate = formatDatePayg(selectedReport.policyExpirationDate);
      return `${effStartDate} - ${effEndDate}`;
    }
    return true;
  };

  const handlePolicyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSelectedPolicy(selectedValue);
    const selectedObject = getObjectByPolicyNumber(
      selectedValue,
      priorReportDetails,
    );
    if (selectedObject) {
      const datesArray = selectedObject.priorReports.map((report) => {
        const effStartDate = formatDatePayg(
          report.premiumAuditBaseDetails.periodStartDate,
        );
        const effEndDate = formatDatePayg(
          report.premiumAuditBaseDetails.periodEndDate,
        );
        return `${effStartDate} - ${effEndDate}`;
      });
      if (datesArray && datesArray.length > 0) {
        setReviewDateArray(datesArray);
      }
    }
    setSelectedDate('');
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSelectedDate(selectedValue);
    const selectedObject = getObjectByPolicyNumber(
      selectedPolicy,
      priorReportDetails,
    );
    if (selectedObject) {
      selectedObject.priorReports.map((report) => {
        const effStartDate = formatDatePayg(
          report.premiumAuditBaseDetails.periodStartDate,
        );
        const effEndDate = formatDatePayg(
          report.premiumAuditBaseDetails.periodEndDate,
        );
        if (selectedValue === `${effStartDate} - ${effEndDate}`) {
          setSelectedPriorReport(report);
        }
        return true;
      });
    }
  };

  const BannerIconWrapper = styled.div`
    ${({ size }: { size: string }) => css`
      width: ${size};
      flex-basis: ${size};
      margin-right: 20px;
      display: flex;
      align-items: center;
    `}
  `;
  const BannerTextContainer = styled.div`
    display: flex;
  `;

  return (
    <>
      {priorReportLoading && <Spinning />}
      {!loaded ? (
        <Wrapper>
          <Text>
            <Info />
            <div style={{ marginTop: '5vh' }}>
              There are not currently any submitted
            </div>
            <div> payroll reports. </div>
          </Text>
        </Wrapper>
      ) : (
        <PriorReports
          policyNumberArray={policyNumberArray}
          selectedPolicy={selectedPolicy}
          selectedDate={selectedDate}
          reviewDateArray={reviewDateArray}
          handlePolicyChange={handlePolicyChange}
          handleDateChange={handleDateChange}
          selectedPriorReport={selectedPriorReport}
          getEffectiveDate={getEffectiveDate}
          accountNumber={accountNumber}
        />
      )}
    </>
  );
};

import useFeatureFlag from './useFeatureFlag';

interface RunFunctions {
  onEnabled?: Function;
  onDisabled?: Function;
  onDefault?: Function;
}

const useFeatureFlagFunctions = (
  flagName: string,
  runFunctions: RunFunctions | undefined,
) => {
  const { flagDetails, error } = useFeatureFlag(flagName);
  let returnValue = null;
  if (flagDetails?.enabled && runFunctions?.onEnabled)
    returnValue = runFunctions.onEnabled(flagDetails, error);
  else if (
    !flagDetails?.enabled &&
    flagDetails?.enabled !== undefined &&
    runFunctions?.onDisabled
  )
    returnValue = runFunctions.onDisabled(flagDetails, error);
  else if (runFunctions?.onDefault)
    returnValue = runFunctions.onDefault(flagDetails, error);

  return returnValue;
};

export default useFeatureFlagFunctions;

import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

export const ContentRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    flex-direction: row;
    width: 100%;
  }
`;

export const ContentRowSpacer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 12px;

  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    flex-direction: row;
    width: 100%;
  }
`;

export const PasswordContentRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    flex-direction: row;
    width: 100%;
  }
`;

export const TextHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const GreenWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  @media screen and (max-width: ${customBase.global.breakpoints.medium}px) {
    justify-content: left;
  }
`;

export const CheckWrapper = styled.div`
  padding: 3px 0px 0px 8px;
`;

export const EditPasswordContentRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  width: 138px;
`;

export const PasswordHeaderWrapper = styled.div`
  width: 138px;
  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    margin-top: 15px;
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
`;

export const Spacer = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      height: ${theme.separator.line.thickness};
      background-color: ${theme.color.background.background};
      margin-top: ${theme.size.spacer.small};
      margin-bottom: 20px;
    `;
  }}
`;

export const RowValueWrapper = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    display: flex;
    margin-top: ${theme.size.spacer.xs};

    @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
      flex-direction: row;
      margin-top: 0px;
      width: 50%;
    }
  `}
`;

export const EditPasswordRowValueWrapper = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    display: flex;
    justify-content: flex-start;
    margin-top: ${theme.size.spacer.xs};
    width: 100%;

    @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
      flex-direction: row;
      margin-left: ${theme.size.spacer.xxl};
      margin-top: 0px;
    }
  `}
`;

export const PasswordRowValueWrapper = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    display: flex;
    justify-content: flex-start;
    padding-top: 7px;

    @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
      flex-direction: row;
      width: 50%;
    }
  `}
`;

export const TypeSelectWrapper = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    margin-left: -${theme.size.spacer.xs};
  `}
`;

export const EditingRowValueWrapper = styled.div`
  display: flex;
  width: 100%;

  #profile-page-primary-phone-input {
    width: 100%;
  }
  .saSelectWrapper {
    width: 120%;
    margin-right: 22px;
    @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
      margin-right: 18px;
    }
  }

  #profile-page-email-input {
    width: 100%;
  }
`;

export const AccountDetailsWrapper = styled.div`
  margin-top: 40px;
`;

export const ProfilePageWrapper = styled.div`
  max-width: 800px;

  @media (min-width: 0px) and (max-width: ${customBase.global.breakpoints
      .small}px) {
    align-items: center;
    padding: 0 ${customBase.global.spacers.xxsmall - 2}px
      ${customBase.global.spacers.large}px;
  }
  @media (min-width: ${customBase.global.breakpoints
      .small}px) and (max-width: ${customBase.global.breakpoints.xlarge}px) {
    margin: ${customBase.global.spacers.med_large}px auto 0;
    padding: 0 ${customBase.global.spacers.large}px
      ${customBase.global.spacers.large}px;
  }
  @media (min-width: ${customBase.global.breakpoints.xlarge}px) {
    margin: ${customBase.global.spacers.med_large}px auto 0;
    width: 75%;
  }
`;

export const CancelSaveWrapper = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    display: flex;
    justify-content: space-around;
    margin-top: ${theme.size.spacer.xl};
  `}

  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    justify-content: flex-end;
  }
`;

export const ProfileWrapper = styled.div`
  margin-top: 70px;
`;

export const EditButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
`;

export const PasswordSpacer = styled.div`
  margin-top: 30px;
  border-top-width: 1px;
  border-top-color: rgb(217, 223, 235);
  border-top-style: solid;
  padding-top: 30px;
`;

export const ResponsiveRow = styled.div`
  display: flex;
  justify-content: left;
`;

export default ContentRow;
